import { FC, Dispatch, SetStateAction } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useRolesControllerFindRoleQuery } from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";

type ToolbarFiltersPropsType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  role: string;
  setRole: Dispatch<SetStateAction<string>>;
};

const ToolbarFilters: FC<ToolbarFiltersPropsType> = ({
  search,
  setSearch,
  role,
  setRole,
}) => {
  const { data, isLoading } = useRolesControllerFindRoleQuery();

  const rolesArray = data
    ? [...data?.roles.map(({ name }) => name), "All"]
    : ["All"];

  const roleChangeHandler = ({ target: { value } }: SelectChangeEvent) => {
    const selectedRole = rolesArray.find((item) => item === value);
    setRole(selectedRole || "All");
  };

  return (
    <>
      {isLoading && <Loading />}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm>
          <TextField
            sx={{ width: "100%", maxWidth: "350px" }}
            size="small"
            placeholder="Search by username"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </Grid>
        <Grid item xs={12} sm>
          <FormControl
            fullWidth
            size="small"
            sx={{ width: "100%", maxWidth: "350px" }}
          >
            <InputLabel id="roleLabelId">Role</InputLabel>
            <Select
              labelId="roleLabelId"
              id="role"
              value={role}
              label="Role"
              onChange={roleChangeHandler}
            >
              {rolesArray.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ToolbarFilters;
