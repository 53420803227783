import { Middleware } from "@reduxjs/toolkit";
import { api } from "./api";
import { logoutAction } from "../slice/authSlice";

interface KnownAction {
  type: string;
  payload?: any;
}

const isKnownAction = (action: unknown): action is KnownAction => {
  return typeof action === "object" && action !== null && "type" in action;
};

export const authMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isKnownAction(action) && action.type === logoutAction.type) {
      dispatch(api.util.resetApiState());
    }
    return next(action);
  };
