export enum ParticipantInformationItem {
  TaskTitle = "TaskTitle",
  WorkLocation = "WorkLocation",
  Age = "Age",
  Gender = "Gender",
  Notes = "Notes",
}

export enum InputTypes {
  STRING = "string",
  NUMBER = "number",
  OPTIONS = "options",
}

interface IInputInformation {
  label: string;
  type: InputTypes;
  min?: number;
  max?: number;
  options?: string[];
}

export const participantInformationInputs: Record<
  ParticipantInformationItem,
  IInputInformation
> = {
  [ParticipantInformationItem.TaskTitle]: {
    label: "Task Title",
    type: InputTypes.STRING,
  },
  [ParticipantInformationItem.WorkLocation]: {
    label: "Work Location",
    type: InputTypes.STRING,
  },
  [ParticipantInformationItem.Age]: {
    label: "Age",
    type: InputTypes.OPTIONS,
    options: ["Under 20", "20-29", "30-39", "40-49", "50-59", "60 and Over"],
  },
  [ParticipantInformationItem.Gender]: {
    label: "Gender",
    type: InputTypes.OPTIONS,
    options: ["Male", "Female", "Other"],
  },
  [ParticipantInformationItem.Notes]: {
    label: "Notes",
    type: InputTypes.STRING,
  },
};

export enum ReportSection {
  ASSESSMENT_INFORMATION = "ASSESSMENT_INFORMATION",
  ANALYSIS_SNAPSHOT_AND_BODY_MAP = "ANALYSIS_SNAPSHOT_AND_BODY_MAP",
  BODY_JOINT_ANGLES_AND_RISK_LEVELS = "BODY_JOINT_ANGLES_AND_RISK_LEVELS",
  ASSESSMENTS = "ASSESSMENTS",
  OBSERVATION_AND_RECOMMENDATION = "OBSERVATION_AND_RECOMMENDATION",
}

export const defaultParticipantInformation = Object.keys(
  participantInformationInputs,
).reduce((values, key) => ({ ...values, [key]: "" }), {});
