import { getResultData } from "src/app/logic/assessment/mmh/calculate";
import {
  CalcTaskModes,
  Gender,
  TaskData,
  calcTaskModesTitle,
} from "src/app/logic/assessment/mmh/constants";
import { MmhDto } from "src/app/services/generatedApi";

export const taskModesKey: MmhDto["mode"][] = [
  "LIFT",
  "LOWER",
  "PUSH",
  "PULL",
  "CARRY",
];

type TaskParam =
  | { label: string; type: "STRING" | "NUMBER"; units: string[] }
  | "Frequency";

type TaskModeData = {
  calculateModes: CalcTaskModes[];
  title: string;
  video: string;
  params: TaskParam[];
};

export type MMHResultType = {
  title: string;
  data: {
    [key in Gender]: {
      acceptancePercentage: number;
      percentilesMAL10: number;
      percentilesMAL25: number;
      percentilesMAL50: number;
      percentilesMAL75: number;
      percentilesMAL90: number;
    };
  };
};

export const taskModesMapper: {
  [key in MmhDto["mode"]]: TaskModeData;
} = {
  LIFT: {
    calculateModes: [CalcTaskModes.LIFT],
    video: "/videos/lift.mp4",
    title: "Lifting",
    params: [
      "Frequency",
      {
        label: "Frequency",
        type: "NUMBER",
        units: [],
      },
    ],
  },
  LOWER: {
    calculateModes: [CalcTaskModes.LOWER],
    video: "/videos/lower.mp4",
    title: "Lowering",
    params: [],
  },
  PULL: {
    calculateModes: [CalcTaskModes.PULL_INITIAL, CalcTaskModes.PULL_SUSTAINED],
    video: "/videos/pull.mp4",
    title: "Pull",
    params: [],
  },
  PUSH: {
    calculateModes: [CalcTaskModes.PUSH_INITIAL, CalcTaskModes.PUSH_SUSTAINED],
    video: "/videos/push.mp4",
    title: "Push",
    params: [],
  },
  CARRY: {
    calculateModes: [CalcTaskModes.CARRY],
    video: "/videos/carry.mp4",
    title: "Carry",
    params: [],
  },
};

export const getResultsFromData = (
  mode: CalcTaskModes,
  data: MmhDto["data"],
  load: number
): MMHResultType => ({
  title: calcTaskModesTitle[mode],
  data: {
    FEMALE: getResultData({ mode, data } as TaskData, Gender.FEMALE, load),
    MALE: getResultData({ mode, data } as TaskData, Gender.MALE, load),
  },
});
