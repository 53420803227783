import { Divider, Grid, Typography } from "@mui/material";
import { taskModesKey, taskModesMapper } from "./constant";
import { SelectItem } from "src/components/atoms/SelectItem";
import { QuestionType } from "src/app/logic/assessment/reba-rula/types";
import { FC } from "react";
import { MmhDto } from "src/app/services/generatedApi";
import {
  defaultLiftLowerCarryLoad,
  defaultLiftLowerTaskData,
  defaultPushPullCarryTaskData,
  defaultPushPullLoad,
} from "./tasks/constants";

export const SelectTaskMode: FC<{
  mmhDto?: MmhDto;
  setMmhDto: (mmhDto?: MmhDto) => void;
}> = ({ mmhDto, setMmhDto }) => {
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Divider sx={{ width: "100%" }}>
          <Typography variant="h6">Task Type</Typography>
        </Divider>
      </Grid>
      {taskModesKey.map((taskMode) => (
        <Grid item sm={4} md={2.4} key={taskMode}>
          <SelectItem
            video={taskModesMapper[taskMode]?.video}
            text={taskMode}
            type={QuestionType.SELECT}
            isSelected={mmhDto?.mode === taskMode}
            onSelect={() => {
              setMmhDto(undefined);
              setTimeout(() => {
                const data =
                  taskMode === "LIFT" || taskMode === "LOWER"
                    ? defaultLiftLowerTaskData
                    : defaultPushPullCarryTaskData;
                const load =
                  taskMode === "LIFT" ||
                  taskMode === "LOWER" ||
                  taskMode === "CARRY"
                    ? defaultLiftLowerCarryLoad
                    : defaultPushPullLoad;
                setMmhDto({ mode: taskMode, data, load });
              }, 400);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
