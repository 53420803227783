import { FC } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Tooltip,
  Chip,
} from "@mui/material";
import AutocompleteWithAdd, {
  nameIdType,
} from "src/components/atoms/AutocompleteWithAdd";

type TaskInfoStepProps = {
  taskName: string;
  setTaskName: (value: string) => void;
  category: string;
  setCategory: (value: string) => void;
  tag: string | nameIdType | null;
  setTag: (value: string | nameIdType | null) => void;
  description: string;
  setDescription: (value: string) => void;
  blurFace: boolean;
  setBlurFace: (value: boolean) => void;
  blurBack: boolean;
  setBlurBack: (value: boolean) => void;
  multiTarget: boolean;
  setMultiTarget: (value: boolean) => void;
  allCategories: any;
  allTags: any;
};

export const TaskInfoStep: FC<TaskInfoStepProps> = ({
  taskName,
  setTaskName,
  category,
  setCategory,
  tag,
  setTag,
  description,
  setDescription,
  blurFace,
  setBlurFace,
  blurBack,
  setBlurBack,
  multiTarget,
  setMultiTarget,
  allCategories,
  allTags,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          label="File name"
          fullWidth
          value={taskName}
          onChange={({ target: { value } }) => setTaskName(value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth required>
          <InputLabel id="angleThresholdsLabelId">Angle Thresholds</InputLabel>
          <Select
            required
            labelId="angleThresholdsLabelId"
            id="angleThresholds"
            value={category}
            label="AngleThresholds"
            onChange={({ target: { value } }) => setCategory(value)}
          >
            {allCategories?.categories &&
              [...allCategories.categories]
                .reverse()
                .map(({ name, id, isSystemCategory }, index) => (
                  <MenuItem key={index} value={id}>
                    <Typography component="span">{name}</Typography>
                    {isSystemCategory && (
                      <Typography
                        component="span"
                        color="primary"
                        fontSize={13}
                        fontWeight="bold"
                      >
                        &nbsp;(SYSTEM ANGLE THRESHOLDS)
                      </Typography>
                    )}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteWithAdd
          onChange={setTag}
          options={(allTags?.tags as any) || []}
          label="Tag"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={blurFace}
                onChange={() => setBlurFace(!blurFace)}
              />
            }
            label="Blur Faces"
          />
          <FormControlLabel
            control={
              <Switch
                checked={blurBack}
                onChange={() => setBlurBack(!blurBack)}
              />
            }
            label="Blur Background"
          />
          <FormControlLabel
            control={
              <Switch
                checked={multiTarget}
                onChange={() => setMultiTarget(!multiTarget)}
              />
            }
            label={
              <Stack direction="row" spacing={1}>
                <Typography>Multi-Subject Analysis</Typography>
                <Tooltip title="The multi-subject feature, still in development, is designed to analyze and conduct assessments on several individuals within a single uploaded video. This innovative functionality enables users to seamlessly toggle between the analysis results of each person included in the video.">
                  <Chip label="Beta" size="small" color="warning" />
                </Tooltip>
              </Stack>
            }
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={6}
          label="Description"
          fullWidth
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
        />
      </Grid>
    </Grid>
  );
};

export default TaskInfoStep;
