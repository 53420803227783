import { FC, useState, Dispatch, SetStateAction, useCallback } from "react";
import { Delete, InsertDriveFile } from "@mui/icons-material";
import { Stack, Box, IconButton, Typography } from "@mui/material";
import theme from "src/configs/theme";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import uploadSvg from "src/assets/icons/upload_file.svg";
import { grey } from "@mui/material/colors";
import { toast } from "react-toastify";

const supportedDuration = 5;
const supportedSize = 400;

const getVideoDuration = (file: File) =>
  new Promise((resolve, reject) => {
    let errorCount = 0;
    window.URL = window.URL || window.webkitURL;

    let video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = (error) => {
      errorCount++;
      if (errorCount === 2) reject(error);
    };

    video.src = window.URL.createObjectURL(file);

    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result as string | undefined);
      media.onloadedmetadata = () => {
        resolve(media.duration);
      };
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => {
      errorCount++;
      if (errorCount === 2) reject(error);
    };
  });

type DropzonePropsType = {
  dropzoneOptions?: DropzoneOptions;
  onChange: Dispatch<SetStateAction<any>>;
  dropzoneText?: string;
};

const Dropzone: FC<DropzonePropsType> = ({
  dropzoneOptions = {
    maxSize: supportedSize * (1024 * 1024),
    accept: {
      "video/*": [
        ".mp4",
        ".mkv",
        ".webm",
        ".ogg",
        ".avi",
        ".mov",
        ".wmv",
        ".m4v",
      ],
    },
    multiple: false,
  },
  onChange,
  dropzoneText,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.error(`Error: File is larger than ${supportedSize} MB`);
          }
          if (err.code === "file-invalid-type") {
            toast.error(`Error: ${err.message}`);
          }
        });
      });

      getVideoDuration(acceptedFiles[0])
        .then((duration) => {
          if (
            duration &&
            Math.round(duration as number) >= 60 * supportedDuration + 10
          ) {
            toast.error(
              `Selected file length is more than ${supportedDuration} minute`,
            );
          }
        })
        .catch((err) => console.log(err));
      setSelectedFiles(acceptedFiles);
      onChange(acceptedFiles[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  const removeFileHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedIndex: number,
  ) => {
    e.stopPropagation();
    setSelectedFiles((prevState) =>
      prevState.filter((_, index) => index !== selectedIndex),
    );
    onChange(null);
  };

  const dropzoneWithContent = (
    <Stack direction="column">
      {selectedFiles.map(({ name }, index) => (
        <div key={index}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" columnGap={1} alignItems="center">
              <InsertDriveFile />
              <Typography>{name}‍</Typography>
            </Stack>
            <IconButton onClick={(e) => removeFileHandler(e, index)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
          {index < selectedFiles.length - 1 && (
            <Box
              sx={{
                width: "100%",
                height: "1px",
                border: "none",
                borderTop: "1px dashed rgba(0, 0, 0, 0.5)",
              }}
            />
          )}
        </div>
      ))}
    </Stack>
  );

  const dropzoneWithoutContent = (
    <Stack justifyContent="center" alignItems="center" direction="column">
      <img src={uploadSvg} alt="upload_dropzone" />
      <Typography
        sx={{ color: grey[400] }}
        fontWeight="bold"
        align="center"
        lineHeight="2rem"
      >
        {dropzoneText ||
          "Drag and Drop a video file here or click to select a video file"}
      </Typography>
      <br />
      <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
        {`* Supported file duration is less than ${supportedDuration} minute`}
      </Typography>
      <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
        {`* Supported file size is less than ${supportedSize} MB`}
      </Typography>
      <Typography fontWeight="bold" sx={{ color: grey[400] }} align="center">
        * Supported file type ".mp4, .mkv, .webm, .ogg, .avi, .mov, .wmv, .m4v"
      </Typography>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px dashed rgba(0, 0, 0, 0.5)",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(4, 2),
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {selectedFiles.length > 0 ? dropzoneWithContent : dropzoneWithoutContent}
    </Box>
  );
};

export default Dropzone;
