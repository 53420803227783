import { FC, useRef, useState, MouseEvent } from "react";
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Popover,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { useAppDispatch } from "src/app/hooks";
import { changeSidebarMenuStateAction } from "src/app/slice/sidebarSlice";
import { useLocation } from "react-router";
import { grey } from "@mui/material/colors";
import longLogo from "src/assets/images/logo.png";

const Navbar: FC = () => {
  const rootElRef = useRef(null),
    theme = useTheme(),
    isLg = useMediaQuery(theme.breakpoints.up("md")),
    dispatch = useAppDispatch(),
    { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const correctPath = () => {
    let result = pathname.replaceAll("/", "");
    switch (result) {
      case "":
        result = "Add Video";
        break;
      case "angleThresholds":
        result = "Angle Thresholds";
        break;
      case "allTasks":
        result = "All Videos";
        break;
      case "results":
        result = "Videos";
        break;
    }
    if (pathname.includes("result/")) {
      result = "Analysis Result";
    }
    if (pathname.includes("compare/")) {
      result = "Compare Videos";
    }
    return result;
  };

  return (
    <AppBar
      ref={rootElRef}
      position="fixed"
      color="inherit"
      sx={{ width: { xs: "100%", md: "calc(100% - 260px)" } }}
    >
      <Toolbar
        component={Stack}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {isLg ? (
          <Box sx={{ maxWidth: "150px", height: "53px" }}>
            <img
              src={longLogo}
              alt="company logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => dispatch(changeSidebarMenuStateAction(true))}
            sx={{ width: "53px", height: "53px" }}
          >
            <MenuIcon color="primary" />
          </IconButton>
        )}
        <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
          {correctPath()}
        </Typography>
        <Badge
          color="secondary"
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          overlap="circular"
          badgeContent=" "
          variant="dot"
          onClick={handleClick}
          invisible={true}
          sx={{ width: { xs: "53px", md: "127px" } }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: "auto" }}
          >
            <NotificationsIcon sx={{ color: grey[500] }} />
          </IconButton>
        </Badge>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "320px", p: 2 }}
          >
            <Typography>Notifications</Typography>
            <Typography>0</Typography>
          </Stack>
          <Box sx={{ p: 2 }}>
            <Typography align="center" color={grey[400]}>
              No notification
            </Typography>
          </Box>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
