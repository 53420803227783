export const rangeToString = (min?: number, max?: number): string => {
  if (min === undefined && max === undefined) {
    return "";
  }
  if (min === undefined) {
    return max + "-";
  }
  if (max === undefined) {
    return min + "+";
  }
  if (min === max) {
    return "" + min;
  }
  return min + "-" + max;
};
