import { FC, useMemo, Dispatch, SetStateAction } from "react";
import { Button, ButtonGroup } from "@mui/material";
import {
  MinimalTaskRo,
  useTasksControllerDeleteMultipleUserTasksMutation,
} from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { stateType } from "src/pages/Tasks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

type ToolbarActionsPropsType = {
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  rows: MinimalTaskRo[];
};

const ToolbarActions: FC<ToolbarActionsPropsType> = ({
  selectedRows,
  setSelectedRows,
  rows,
}) => {
  const [deleteTasks, { isLoading: deleteTasksLoading }] =
    useTasksControllerDeleteMultipleUserTasksMutation();

  const navigate = useNavigate();

  const deleteSelectedRows = () => {
    deleteTasks({ idsDto: { ids: selectedRows } })
      .unwrap()
      .then(() => {
        setSelectedRows([]);
        toast.success("Selected tasks, successfully deleted");
      });
  };

  const compareSelectedRows = () => {
    navigate("/compare/" + selectedRows[0] + "/" + selectedRows[1]);
  };

  return (
    <>
      {deleteTasksLoading && <Loading />}
      <ButtonGroup variant="contained">
        <Button
          color="info"
          onClick={compareSelectedRows}
          disabled={selectedRows.length === 2 ? false : true}
        >
          Compare
        </Button>
        <Button color="error" onClick={deleteSelectedRows}>
          Delete
        </Button>
      </ButtonGroup>
    </>
  );
};

export default ToolbarActions;
