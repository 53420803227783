import { createSlice } from "@reduxjs/toolkit";
import { MeasurementSystem } from "src/components/molecules/assessment/mmh/constants";

const initialState = {
  system: MeasurementSystem.Metric,
};

const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    changeSystem: (state, { payload }: { payload: MeasurementSystem }) => {
      state.system = payload;
    },
  },
});

export const { changeSystem: changeSystemAction } = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
