import { FC } from "react";
import { Bar, BarChart, Cell, LabelList } from "recharts";

export const CompareBarChart: FC<{
  chartData: {
    key: string;
    l: number;
    r: number;
  }[];
}> = ({ chartData }) => {
  return (
    <BarChart
      width={380}
      height={600}
      data={chartData.map((d) => ({ ...d, l: -d.l }))}
      stackOffset="sign"
      margin={{
        top: 15,
        right: 15,
        bottom: 15,
        left: 15,
      }}
    >
      <Bar dataKey="l" barSize={15} stackId="a">
        {chartData.map((_, index) => (
          <Cell key={index} fill={"#C7B7A3"} />
        ))}
        <LabelList
          dataKey="l"
          content={({ x, width, height, value = 0 }: any) => (
            <text
              fill={Math.abs(value) < 15 ? "black" : "white"}
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                transform: `translate(${x + width / 2 + 1}px, ${-height + (Math.abs(value) < 10 ? 315 : Math.abs(value) < 15 ? 325 : 275)}px) rotate(-90deg)`,
              }}
              fontWeight={800}
            >
              {Math.abs(Math.round(value)) + "%"}
            </text>
          )}
        />
      </Bar>
      <Bar dataKey="r" barSize={15} stackId="a">
        {chartData.map((_, index) => (
          <Cell key={index} fill={"#ADBC9F"} />
        ))}

        <LabelList
          dataKey="key"
          content={({ x, width, value, index }: any) => {
            return (
              <g
                style={{
                  transform: `translate(${x + width / 2 - 20}px, ${310}px) rotate(-90deg)`,
                }}
              >
                <text
                  fill="black"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={25}
                  fontWeight={800}
                >
                  {value}
                </text>
                {Math.abs(
                  Math.round(chartData[index].l) -
                    Math.round(chartData[index].r),
                ) > 0.99 && (
                  <>
                    <path
                      d={
                        chartData[index].l > chartData[index].r
                          ? "M 83 10 L 89 2 L 86 2 L 86 -8 L 80 -8 L 80 2 L 77 2 Z"
                          : "M 83 -10 L 89 -2 L 86 -2 L 86 8 L 80 8 L 80 -2 L 77 -2 Z"
                      }
                      fill={
                        chartData[index].l > chartData[index].r
                          ? "rgb(76, 175, 80)"
                          : "rgb(244, 67, 54)"
                      }
                    />
                    <text
                      x={93}
                      y={5}
                      fill={
                        chartData[index].l > chartData[index].r
                          ? "rgb(76, 175, 80)"
                          : "rgb(244, 67, 54)"
                      }
                    >
                      {Math.abs(
                        Math.round(chartData[index].l) -
                          Math.round(chartData[index].r),
                      )}
                      %
                    </text>
                  </>
                )}
              </g>
            );
          }}
        />
        <LabelList
          dataKey="r"
          content={({ x, width, height, value = 0 }: any) => (
            <text
              fill={Math.abs(value) < 15 ? "black" : "white"}
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                transform: `translate(${x + width / 2 + 1}px, ${-height + (Math.abs(value) < 10 ? 280 : Math.abs(value) < 15 ? 275 : 325)}px) rotate(-90deg)`,
              }}
              fontWeight={800}
            >
              {Math.round(value) + "%"}
            </text>
          )}
        />
      </Bar>
    </BarChart>
  );
};
