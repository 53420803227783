import {
  Button,
  Container,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { taskModesMapper } from "./constant";
import { FC, useEffect, useRef } from "react";
import {
  LiftLowerCarryLoadDto,
  LiftLowerTaskDataDto,
  MmhDto,
  PushPullCarryTaskDataDto,
  PushPullLoadDto,
} from "src/app/services/generatedApi";
import { LiftLower } from "./tasks/LiftLower";
import { PushPull } from "./tasks/PushPull";
import { Carry } from "./tasks/Carry";
import { MeasurementSystem } from "src/components/molecules/assessment/mmh/constants";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { changeSystemAction } from "src/app/slice/assessmentsSlice";
import { MMHHelpDialog } from "src/components/molecules/assessment/mmh/HelpDialog";

export const TaskDataForm: FC<{
  mmhDto?: MmhDto;
  setMmhDto: (mmhDto?: MmhDto) => void;
  onSubmit: () => void;
  onChangeHasError: (hasError: { men: boolean; women: boolean }) => void;
  errorMessage: string;
}> = ({ mmhDto, setMmhDto, onSubmit, onChangeHasError, errorMessage }) => {
  const errorsRef = useRef<Record<string, { men: boolean; women: boolean }>>(
    {},
  );

  useEffect(() => {
    errorsRef.current = {};
  }, [mmhDto?.mode]);

  const onChangeError =
    (name: string) => (errorState: { men: boolean; women: boolean }) => {
      errorsRef.current[name] = errorState;

      const menHasError = (
        Object.values(errorsRef.current) as { men: boolean; women: boolean }[]
      ).some((error) => error.men);
      const womenHasError = (
        Object.values(errorsRef.current) as { men: boolean; women: boolean }[]
      ).some((error) => error.women);

      onChangeHasError({ men: menHasError, women: womenHasError });
    };

  const dispatch = useAppDispatch();
  const measurementSystem = useAppSelector((store) => store.assessments.system);

  const handleSwitchChange = () => {
    const newSystem =
      measurementSystem === MeasurementSystem.Metric
        ? MeasurementSystem.Imperial
        : MeasurementSystem.Metric;
    dispatch(changeSystemAction(newSystem));
  };

  return (
    <Stack spacing={2} width="100%" alignItems="center">
      {mmhDto?.mode && (
        <Divider sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography variant="h6">
              {`${taskModesMapper[mmhDto.mode].title} Task Details`}
            </Typography>
            <MMHHelpDialog mode={mmhDto.mode} />
          </Stack>
        </Divider>
      )}

      <Container maxWidth="sm">
        <Stack spacing={2} width="100%" alignItems="center">
          <FormControlLabel
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
            control={
              <Switch
                checked={measurementSystem === MeasurementSystem.Imperial}
                onChange={handleSwitchChange}
                color="primary"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    "&.Mui-checked": {
                      color: "#fff",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#8796A5",
                      },
                    },
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#8796A5",
                  },
                }}
              />
            }
            label={
              <Typography variant="body2">
                {measurementSystem === MeasurementSystem.Metric
                  ? "Metric"
                  : "Imperial"}
              </Typography>
            }
            labelPlacement="top"
          />
          {mmhDto?.mode === "LIFT" || mmhDto?.mode === "LOWER" ? (
            <LiftLower
              lift={mmhDto.mode === "LIFT"}
              data={mmhDto.data as LiftLowerTaskDataDto}
              load={mmhDto.load as LiftLowerCarryLoadDto}
              onChangeData={(name, value) =>
                mmhDto &&
                setMmhDto({
                  ...mmhDto,
                  data: { ...mmhDto.data, [name]: value },
                })
              }
              onChangeLoad={(name, value) =>
                mmhDto &&
                setMmhDto({
                  ...mmhDto,
                  load: { ...mmhDto.load, [name]: value },
                })
              }
              onChangeError={onChangeError}
            />
          ) : mmhDto?.mode === "PUSH" || mmhDto?.mode === "PULL" ? (
            <PushPull
              push={mmhDto.mode === "PUSH"}
              data={mmhDto.data as PushPullCarryTaskDataDto}
              load={mmhDto.load as PushPullLoadDto}
              onChangeData={(name, value) =>
                mmhDto &&
                setMmhDto({
                  ...mmhDto,
                  data: { ...mmhDto.data, [name]: value },
                })
              }
              onChangeLoad={(name, value) =>
                mmhDto &&
                setMmhDto({
                  ...mmhDto,
                  load: { ...mmhDto.load, [name]: value },
                })
              }
              onChangeError={onChangeError}
            />
          ) : mmhDto?.mode === "CARRY" ? (
            <Carry
              data={mmhDto.data as PushPullCarryTaskDataDto}
              load={mmhDto.load as LiftLowerCarryLoadDto}
              onChangeData={(name, value) =>
                mmhDto &&
                setMmhDto({
                  ...mmhDto,
                  data: { ...mmhDto.data, [name]: value },
                })
              }
              onChangeLoad={(name, value) =>
                mmhDto &&
                setMmhDto({
                  ...mmhDto,
                  load: { ...mmhDto.load, [name]: value },
                })
              }
              onChangeError={onChangeError}
            />
          ) : (
            <></>
          )}
          <Typography color="error">{errorMessage}</Typography>
          {mmhDto?.mode && (
            <Button variant="contained" fullWidth onClick={onSubmit}>
              Calculate
            </Button>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
