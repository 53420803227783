import { Grid } from "@mui/material";
import { FC } from "react";
import { TaskRo } from "src/app/services/generatedApi";
import { FloatingAssessmentButtons } from "src/components/molecules/assessment/FloatingAssessmentButton";
import AssessmentContextProvider from "src/context/AssessmentContext";
import { Assessments } from "../assessment/Assessments";
import { AssessmentsList } from "../assessment/AssessmentsList";

export const AssessmentSections: FC<{
  task: TaskRo;
  assessmentPageIndex: number;
  setAssessmentPageIndex: (index: number) => void;
}> = ({ task, assessmentPageIndex, setAssessmentPageIndex }) => (
  <AssessmentContextProvider
    assessmentPageIndex={assessmentPageIndex}
    setAssessmentPageIndex={setAssessmentPageIndex}
  >
    {!!assessmentPageIndex && (
      <Grid item xs={12}>
        <Assessments fileName={task.outFileName} havePose={task.havePose} />
      </Grid>
    )}
    <Grid item xs={12}>
      <AssessmentsList task={task} />
    </Grid>
    <FloatingAssessmentButtons task={task} />
  </AssessmentContextProvider>
);
