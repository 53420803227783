import { FC, MouseEvent, ChangeEvent } from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { tableOrderType } from "src/pages/Tasks";
import { MinimalTaskRo } from "src/app/services/generatedApi";

type HeadCell = {
  id: keyof MinimalTaskRo;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "creator",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "riskScore",
    numeric: true,
    disablePadding: false,
    label: "Risk Score",
  },
  {
    id: "isDeleted",
    numeric: false,
    disablePadding: false,
    label: "Deleted",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Date created",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

type TasksTableHeadPropsType = {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof MinimalTaskRo,
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: tableOrderType;
  orderBy: string;
  rows: MinimalTaskRo[];
};

const TasksTableHead: FC<TasksTableHeadPropsType> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rows,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: keyof MinimalTaskRo) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowCount = rows.length;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TasksTableHead;
