type LiveSearchType = {
  inputValue: string;
  originalArray: any[];
  searchItem: string;
};

export const liveSearch = ({
  inputValue,
  originalArray,
  searchItem,
}: LiveSearchType) => {
  return originalArray.filter((item) =>
    inputValue
      .trim()
      .split(" ")
      .every((word) =>
        item[searchItem].toString().toLowerCase().includes(word.toLowerCase()),
      ),
  );
};
