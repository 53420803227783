import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const HorizontalDistanceIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M5.51082 17.2261C5.24015 16.2149 5.27781 15.1475 5.61894 14.157C6.20819 15.1482 7.28132 15.7653 8.44939 15.7844C9.00192 15.7779 10.0512 15.6978 9.855 14.9067H9.85518C9.76219 14.5912 9.42665 14.4093 9.10534 14.5003C7.29502 15.0432 6.3655 13.2867 6.23342 12.5713C6.17467 12.3172 6.05898 12.0793 5.89482 11.8748C6.64788 11.975 7.40837 11.7492 7.97908 11.2562C8.54978 10.7631 8.87488 10.0509 8.86999 9.30425C8.86512 8.55754 8.53102 7.84945 7.95403 7.36361C7.377 6.87754 6.61365 6.66136 5.86202 6.77097C3.48694 7.08597 2.81474 10.2555 4.82206 11.4834C4.47462 11.5288 4.16449 11.7207 3.97313 12.0092C3.41899 12.9552 3.07429 14.0054 2.96145 15.0912C2.84846 16.177 2.96992 17.2741 3.31789 18.3106C3.19481 18.5916 3.0202 18.8482 2.80269 19.0672C2.55094 19.4701 2.11125 19.7227 1.63065 19.7408L0.711968 19.836C0.438057 19.8683 0.199991 20.0354 0.080185 20.2796C-0.0396531 20.5238 -0.0243325 20.811 0.120551 21.0415C0.265434 21.2721 0.519882 21.4143 0.79579 21.4183C0.784617 21.4343 1.77504 21.3155 1.79954 21.3188C3.16566 21.277 4.21697 20.1243 4.78282 18.9858C4.90573 18.9486 5.02124 18.8912 5.12467 18.8163C5.87972 19.1942 6.49854 19.7907 6.89736 20.5254C7.29615 21.26 7.45579 22.0972 7.35454 22.9239C7.32588 23.2055 7.45185 23.4808 7.68539 23.6465C7.91912 23.8121 8.22473 23.8429 8.48768 23.7273C8.7506 23.6118 8.93099 23.3673 8.96091 23.0858C9.09552 21.8755 8.83332 20.6546 8.21287 19.6007C7.59244 18.5468 6.6458 17.7151 5.5109 17.2262L5.51082 17.2261Z" />
      <path d="M14.9246 17.4518V11.899C14.9246 11.7938 14.8821 11.6929 14.8064 11.6185C14.7307 11.5441 14.628 11.5023 14.5209 11.5023H8.0624C7.83949 11.5023 7.65874 11.68 7.65874 11.899V13.9918C8.14982 14.3135 8.73367 14.1674 9.27337 14.0799C9.49196 14.081 9.70424 14.1511 9.87886 14.2802C10.0535 14.4093 10.1811 14.5902 10.2427 14.7964C10.5662 16.2032 8.62534 16.3222 7.65873 16.0869V17.4519C7.65873 17.557 7.70125 17.658 7.77694 17.7323C7.85263 17.8067 7.95534 17.8485 8.06238 17.8485H14.5209C14.628 17.8485 14.7307 17.8067 14.8064 17.7323C14.882 17.658 14.9246 17.557 14.9246 17.4518ZM12.5026 14.2788H10.0807V11.899H12.5026V14.2788Z" />
      <path d="M15.0196 0C14.7967 0 14.616 0.177601 14.616 0.396633V5.62584C14.2548 5.75156 13.9759 6.03806 13.8638 6.39803C13.7519 6.75801 13.8202 7.14896 14.0481 7.45158C14.2761 7.7542 14.6365 7.93266 15.0196 7.93266C15.4027 7.93266 15.7631 7.75417 15.9911 7.45158C16.2191 7.14897 16.2876 6.75799 16.1755 6.39803C16.0636 6.03805 15.7844 5.75154 15.4233 5.62584V3.54282C15.4382 3.55255 17.9775 2.28633 17.993 2.28547C18.0916 2.23695 18.1539 2.13779 18.1539 2.02943C18.1539 1.92106 18.0916 1.82208 17.993 1.77357C17.9706 1.77126 15.4445 0.509281 15.4233 0.516217C15.4512 0.393686 15.4222 0.265132 15.3444 0.165443C15.2663 0.0657549 15.1474 0.00519476 15.0196 6.3461e-05L15.0196 0Z" />
      <path d="M10.4843 23.2032H9.87886C9.65901 23.2075 9.48295 23.3839 9.48295 23.5999C9.48295 23.8159 9.65901 23.9923 9.87886 23.9965H10.4843C10.7042 23.9923 10.8803 23.8159 10.8803 23.5999C10.8803 23.3839 10.7042 23.2075 10.4843 23.2032Z" />
      <path d="M18.0048 18.2796C18.1728 18.2767 18.3216 18.1726 18.3795 18.0177C18.4373 17.8626 18.3919 17.6887 18.2656 17.58C18.0016 17.3611 17.7989 17.0794 17.6769 16.7619C17.5983 16.5571 17.3658 16.4535 17.1571 16.5303C16.9486 16.6073 16.843 16.8358 16.9212 17.0408C17.1314 17.5033 17.4659 18.1595 18.0048 18.2796Z" />
      <path d="M19.1425 23.2032H17.9057C17.6859 23.2075 17.5098 23.3839 17.5098 23.5999C17.5098 23.8159 17.6859 23.9923 17.9057 23.9965H19.1425C19.3623 23.9923 19.5384 23.8159 19.5384 23.5999C19.5384 23.3839 19.3623 23.2075 19.1425 23.2032Z" />
      <path d="M17.1735 15.6971C17.3723 15.6966 17.5412 15.5543 17.5718 15.3612C17.6249 15.0262 17.7648 14.7102 17.978 14.4437C18.1104 14.271 18.078 14.0262 17.9052 13.8927C17.7322 13.7592 17.4822 13.7861 17.3429 13.9534C17.051 14.3383 16.3167 15.581 17.1735 15.6971Z" />
      <path d="M13.3704 23.2032H12.1333C11.9135 23.2075 11.7376 23.3839 11.7376 23.5999C11.7376 23.8159 11.9135 23.9923 12.1335 23.9965H13.3704C13.5902 23.9923 13.7663 23.8159 13.7663 23.5999C13.7663 23.3839 13.5902 23.2075 13.3704 23.2032Z" />
      <path d="M23.6247 20.8103C23.5176 20.8015 23.4115 20.8355 23.3302 20.9047C23.2491 20.9739 23.1999 21.0724 23.1936 21.1779C23.1689 21.5211 23.0615 21.8536 22.8806 22.148C22.7653 22.3356 22.8264 22.5796 23.0172 22.6929C23.208 22.8062 23.4564 22.7462 23.5717 22.5587C23.8184 22.1562 23.9649 21.7022 23.999 21.2337C24.0066 21.1287 23.9714 21.0251 23.9011 20.9458C23.831 20.8663 23.7315 20.8176 23.6247 20.8103Z" />
      <path d="M16.2564 23.2032H15.0193C14.7995 23.2075 14.6234 23.3839 14.6234 23.5999C14.6236 23.8159 14.7995 23.9923 15.0193 23.9965H16.2564C16.4763 23.9923 16.6522 23.8159 16.6522 23.5999C16.6522 23.3839 16.4763 23.2075 16.2564 23.2032Z" />
      <path d="M18.6327 13.4357C18.7538 13.8983 19.2276 13.6788 19.5665 13.6839C19.9689 13.6652 20.7645 13.6614 20.636 13.0801C20.5732 12.8698 20.349 12.7494 20.1349 12.8108C19.6826 13.0088 18.5597 12.6699 18.6327 13.4357L18.6327 13.4357Z" />
      <path d="M20.8198 6.83162C20.3924 6.62445 19.919 6.52564 19.4427 6.54424C19.2228 6.54831 19.0466 6.72467 19.0466 6.94087C19.0466 7.15707 19.2228 7.33343 19.4427 7.3375C19.8545 7.34175 20.2612 7.42533 20.6402 7.58345C20.8252 7.57973 20.9843 7.45331 21.0267 7.27606C21.069 7.099 20.984 6.91608 20.8198 6.83162Z" />
      <path d="M19.5281 18.0466C19.3076 18.0446 19.1263 18.2169 19.1209 18.4336C19.1155 18.6504 19.2879 18.8311 19.5083 18.8398L20.7549 18.8402C20.9779 18.8402 21.1586 18.6626 21.1586 18.4435C21.1586 18.2245 20.9779 18.0469 20.7549 18.0469L19.5281 18.0466Z" />
      <path d="M21.8435 23.0319C21.5103 23.1654 21.151 23.224 20.7918 23.2031C20.572 23.2074 20.3961 23.3837 20.3961 23.5997C20.3961 23.8158 20.572 23.9921 20.7918 23.9964C21.2593 24.0185 21.7258 23.9385 22.1581 23.7625C22.3589 23.6745 22.4512 23.4446 22.3654 23.2456C22.2798 23.0466 22.0477 22.9515 21.8435 23.0319Z" />
      <path d="M21.3296 12.5866C22.0135 12.9583 22.3214 11.631 22.38 11.1678H22.3799C22.3963 10.9523 22.2339 10.7635 22.0148 10.7435C21.7956 10.7233 21.6006 10.8793 21.5761 11.0943C21.5439 11.4318 21.4237 11.7556 21.2274 12.0347C21.1665 12.1211 21.1429 12.2279 21.1622 12.3315C21.1813 12.4349 21.2415 12.5268 21.3296 12.5866Z" />
      <path d="M23.2076 20.0556C23.3534 20.054 23.4873 19.9754 23.558 19.85C23.6288 19.7246 23.6255 19.5717 23.5497 19.4491C23.2823 19.0688 22.4503 17.9991 21.9891 18.6089C21.874 18.7964 21.9352 19.0403 22.1259 19.1536C22.4259 19.3317 22.6789 19.5768 22.8647 19.869C22.9384 19.9853 23.0682 20.0558 23.2076 20.0556Z" />
      <path d="M21.9888 9.90765C22.7206 9.85701 22.3145 8.57573 22.1352 8.16124C22.0393 7.96646 21.8027 7.88307 21.6028 7.97337C21.403 8.06368 21.3134 8.29475 21.4015 8.49271C21.5232 8.75247 21.5859 9.03508 21.5852 9.32088C21.5531 9.6049 21.6522 9.90094 21.9888 9.90765Z" />
      <path d="M17.1449 7.33794H17.7504C17.9703 7.33369 18.1463 7.15733 18.1463 6.94131C18.1463 6.72529 17.9703 6.54893 17.7504 6.54468H17.1449C16.9251 6.54893 16.749 6.72529 16.749 6.94131C16.749 7.15733 16.9251 7.33369 17.1449 7.33794Z" />
    </SvgIcon>
  );
};
