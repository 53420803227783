import { FC, Dispatch, SetStateAction } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Grid,
  Typography,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  MinimalCategoryRo,
  TagRo,
  useCategoryControllerGetAllCategoriesQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
} from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";

type ToolbarFiltersPropsType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  category?: MinimalCategoryRo;
  setCategory?: Dispatch<SetStateAction<MinimalCategoryRo>>;
  tag?: TagRo;
  setTag?: Dispatch<SetStateAction<TagRo>>;
};

const ToolbarFilters: FC<ToolbarFiltersPropsType> = ({
  search,
  category,
  setSearch,
  setCategory,
  tag,
  setTag,
}) => {
  const { data: allCategories, isLoading: getCategoriesLoading } =
    useCategoryControllerGetAllCategoriesQuery();

  const { data: allTags, isLoading: getTagsLoading } =
    useTasksControllerGetUserTagsForCurrentUserQuery();

  const categoriesArray = allCategories
    ? [
        ...allCategories?.categories,
        { id: "all", name: "All", isSystemCategory: false },
      ]
    : [{ id: "all", name: "All", isSystemCategory: false }];

  const tagsArray: TagRo[] = allTags
    ? [...allTags.tags, { id: "all", name: "All" }]
    : [{ id: "all", name: "All" }];

  const categoryChangeHandler = ({ target: { value } }: SelectChangeEvent) => {
    if (!setCategory) return;
    const selectedCategory = categoriesArray.find(({ id }) => id === value);
    setCategory(
      selectedCategory || {
        id: "all",
        name: "All",
      },
    );
  };

  const tagChangeHandler = ({ target: { value } }: SelectChangeEvent) => {
    if (!setTag) return;
    const selectedTag = tagsArray.find(({ id }) => id === value);
    setTag(
      selectedTag || {
        id: "all",
        name: "All",
      },
    );
  };

  return (
    <>
      {(getCategoriesLoading || getTagsLoading) && <Loading />}
      {!category || !tag ? (
        <TextField
          sx={{ width: "100%", maxWidth: "352px" }}
          size="small"
          placeholder="Search by name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
        />
      ) : (
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{ minWidth: !category && !tag ? undefined : "80%" }}
        >
          <Grid item xs={12} md={!category && !tag ? undefined : 5}>
            <TextField
              sx={{ width: "100%", maxWidth: "352px" }}
              size="small"
              placeholder="Search by name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </Grid>
          <Grid item xs={12} md>
            <FormControl
              fullWidth
              size="small"
              sx={{ width: "100%", maxWidth: "352px" }}
            >
              <InputLabel id="angleThresholdsLabelId">
                Angle thresholds
              </InputLabel>
              <Select
                labelId="angleThresholdsLabelId"
                id="angleThresholds"
                value={category.id}
                label="Angle thresholds"
                onChange={categoryChangeHandler}
              >
                {categoriesArray.map(({ name, id, isSystemCategory }) => (
                  <MenuItem key={id} value={id}>
                    <Typography component="span">{name}</Typography>
                    {isSystemCategory && (
                      <Typography
                        component="span"
                        color="primary"
                        fontSize={13}
                        fontWeight="bold"
                      >
                        &nbsp;(SYSTEM ANGLE THRESHOLDS)
                      </Typography>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md>
            <FormControl
              fullWidth
              size="small"
              sx={{ width: "100%", maxWidth: "352px" }}
            >
              <InputLabel id="tagLabelId">Tag</InputLabel>
              <Select
                labelId="tagLabelId"
                id="tag"
                value={tag.id}
                label="Tag"
                onChange={tagChangeHandler}
              >
                {tagsArray.map(({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ToolbarFilters;
