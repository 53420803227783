import { Divider, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { SelectItem } from "../../../atoms/SelectItem";
import {
  QuestionType,
  RebaRulaQuestion,
} from "src/app/logic/assessment/reba-rula/types";

type RebaRulaFormItemPropsType = {
  question: RebaRulaQuestion<any>;
  onSelect: (index: number) => void;
};

export const RebaRulaFormItem: FC<RebaRulaFormItemPropsType> = ({
  question,
  onSelect,
}) => {
  return (
    <Stack spacing={0.5} alignItems="center">
      {question.question ? (
        <Divider sx={{ width: "100%" }}>
          <Typography>{question.question}</Typography>
        </Divider>
      ) : (
        <Typography align="left" sx={{ width: "100%" }}>
          Adjust:
        </Typography>
      )}

      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent={question.question ? "center" : "flex-start"}
        alignItems="center"
      >
        {question.type === QuestionType.CHECK
          ? question.items.map((item, index: number) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <SelectItem
                  img={item.img}
                  text={item.text}
                  isSelected={question.items[index].selected}
                  type={question.type}
                  onSelect={() => onSelect(index)}
                />
              </Grid>
            ))
          : question.items.map((item, index: number) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <SelectItem
                  img={item.img}
                  text={item.text}
                  isSelected={question.selectedIndex === index}
                  type={question.type}
                  onSelect={() => onSelect(index)}
                />
              </Grid>
            ))}
      </Grid>
    </Stack>
  );
};
