import { RebaDto, ResultRo } from "src/app/services/generatedApi";
import { getRebaQuestions } from "./RebaQuestions";
import { RebaItems, RebaQuestions } from "./types";
import {
  getIdOfSelectedItem,
  getIdOfSelectedOptionalItem,
  getValueOfRebaQuestions,
  setIndexById,
  setIndexOfOptionalById,
} from "../reba-rula/helper";
import { Angle3DResultRo } from "src/types/pose";

const calculateOrganAngle = (leftAngle?: number, rightAngle?: number) => {
  let angle = 0;
  if (leftAngle) angle += leftAngle;
  if (rightAngle) angle += rightAngle;
  if (leftAngle && rightAngle) angle /= 2;
  return angle;
};

export const extractRebaData = (
  result: ResultRo,
  rebaQuestions: RebaQuestions,
  selectedAngles3D?: Angle3DResultRo,
): RebaQuestions => {
  const legAngle = calculateOrganAngle(result.leftKnee, result.rightKnee);
  const upperArmAngle = calculateOrganAngle(
    selectedAngles3D?.leftUpperArm?.Beta || result.leftUpperArm,
    selectedAngles3D?.rightUpperArm?.Beta || result.rightUpperArm,
  );
  const lowerArmAngle = calculateOrganAngle(
    result.leftLowerArm,
    result.rightLowerArm,
  );
  const wristAngle = calculateOrganAngle(result.leftWrist, result.rightWrist);

  rebaQuestions[RebaItems.NECK_ANGLE].update(result.neck);
  rebaQuestions[RebaItems.TRUNK_ANGLE].update(
    result.back,
    selectedAngles3D?.back,
  );
  rebaQuestions[RebaItems.TRUNK_ADJUST].update?.(selectedAngles3D?.back);
  rebaQuestions[RebaItems.LEG_DOWN].update(result.leftKnee, result.rightKnee);
  rebaQuestions[RebaItems.LEG_ANGLE].update(legAngle);
  rebaQuestions[RebaItems.UPPER_ARM_ANGLE].update(upperArmAngle);
  rebaQuestions[RebaItems.LOWER_ARM_ANGLE].update(lowerArmAngle);
  rebaQuestions[RebaItems.WRIST_ANGLE].update(wristAngle);

  return rebaQuestions;
};

export const getRebaDataFromResult = (rebaResult: RebaDto): RebaQuestions => {
  const rebaQuestions = getRebaQuestions();
  setIndexById(rebaQuestions[RebaItems.NECK_ANGLE], rebaResult.neckAngle);
  rebaQuestions[RebaItems.NECK_ADJUST].items[0].selected =
    rebaResult.neckAdjust.twisted;
  rebaQuestions[RebaItems.NECK_ADJUST].items[1].selected =
    rebaResult.neckAdjust.sideBending;
  setIndexById(rebaQuestions[RebaItems.TRUNK_ANGLE], rebaResult.trunkAngle);
  rebaQuestions[RebaItems.TRUNK_ADJUST].items[0].selected =
    rebaResult.trunkAdjust.twisted;
  rebaQuestions[RebaItems.TRUNK_ADJUST].items[1].selected =
    rebaResult.trunkAdjust.sideBending;
  setIndexById(rebaQuestions[RebaItems.LEG_DOWN], rebaResult.legDown);
  setIndexOfOptionalById(
    rebaQuestions[RebaItems.LEG_ANGLE],
    rebaResult.legAngle,
  );
  setIndexById(rebaQuestions[RebaItems.FORCE_MODE], rebaResult.forceMode);
  rebaQuestions[RebaItems.SHOCK].items[0].selected = rebaResult.shock;
  setIndexById(
    rebaQuestions[RebaItems.UPPER_ARM_ANGLE],
    rebaResult.upperArmAngle,
  );
  rebaQuestions[RebaItems.UPPER_ARM_ADJUST].items[0].selected =
    rebaResult.upperArmAdjust.raisedShoulder;
  rebaQuestions[RebaItems.UPPER_ARM_ADJUST].items[1].selected =
    rebaResult.upperArmAdjust.abductedUpperArm;
  rebaQuestions[RebaItems.UPPER_ARM_ADJUST].items[2].selected =
    rebaResult.upperArmAdjust.supportedArm;
  setIndexById(
    rebaQuestions[RebaItems.LOWER_ARM_ANGLE],
    rebaResult.lowerArmAngle,
  );
  setIndexById(rebaQuestions[RebaItems.WRIST_ANGLE], rebaResult.wristAngle);
  rebaQuestions[RebaItems.BENT_WRIST].items[0].selected = rebaResult.bentWrist;
  setIndexById(rebaQuestions[RebaItems.COUPLING_MODE], rebaResult.couplingMode);
  rebaQuestions[RebaItems.ACTIVITY].items[0].selected =
    rebaResult.activity.bodyPartHeldLong;
  rebaQuestions[RebaItems.ACTIVITY].items[1].selected =
    rebaResult.activity.repeatedSmallRangeAction;
  rebaQuestions[RebaItems.ACTIVITY].items[2].selected =
    rebaResult.activity.rapidLargeRangeChange;
  return rebaQuestions;
};

export const getRebaResult = (rebaQuestions: RebaQuestions): RebaDto => ({
  neckAngle: getIdOfSelectedItem(rebaQuestions[RebaItems.NECK_ANGLE]),
  neckAdjust: {
    twisted: rebaQuestions[RebaItems.NECK_ADJUST].items[0].selected,
    sideBending: rebaQuestions[RebaItems.NECK_ADJUST].items[1].selected,
  },
  trunkAngle: getIdOfSelectedItem(rebaQuestions[RebaItems.TRUNK_ANGLE]),
  trunkAdjust: {
    twisted: rebaQuestions[RebaItems.TRUNK_ADJUST].items[0].selected,
    sideBending: rebaQuestions[RebaItems.TRUNK_ADJUST].items[1].selected,
  },
  legDown: getIdOfSelectedItem(rebaQuestions[RebaItems.LEG_DOWN]),
  legAngle: getIdOfSelectedOptionalItem(rebaQuestions[RebaItems.LEG_ANGLE]),
  forceMode: getIdOfSelectedItem(rebaQuestions[RebaItems.FORCE_MODE]),
  shock: rebaQuestions[RebaItems.SHOCK].items[0].selected,
  upperArmAngle: getIdOfSelectedItem(rebaQuestions[RebaItems.UPPER_ARM_ANGLE]),
  upperArmAdjust: {
    raisedShoulder: rebaQuestions[RebaItems.UPPER_ARM_ADJUST].items[0].selected,
    abductedUpperArm:
      rebaQuestions[RebaItems.UPPER_ARM_ADJUST].items[1].selected,
    supportedArm: rebaQuestions[RebaItems.UPPER_ARM_ADJUST].items[2].selected,
  },
  lowerArmAngle: getIdOfSelectedItem(rebaQuestions[RebaItems.LOWER_ARM_ANGLE]),
  wristAngle: getIdOfSelectedItem(rebaQuestions[RebaItems.WRIST_ANGLE]),
  bentWrist: rebaQuestions[RebaItems.BENT_WRIST].items[0].selected,
  couplingMode: getIdOfSelectedItem(rebaQuestions[RebaItems.COUPLING_MODE]),
  activity: {
    bodyPartHeldLong: rebaQuestions[RebaItems.ACTIVITY].items[0].selected,
    repeatedSmallRangeAction:
      rebaQuestions[RebaItems.ACTIVITY].items[1].selected,
    rapidLargeRangeChange: rebaQuestions[RebaItems.ACTIVITY].items[2].selected,
  },
});

const A = [
  [1, 2, 3, 4, 1, 2, 3, 5, 3, 3, 5, 6],
  [2, 3, 4, 5, 3, 4, 5, 6, 4, 5, 6, 7],
  [2, 4, 5, 6, 4, 5, 6, 7, 5, 6, 7, 8],
  [3, 5, 6, 7, 5, 6, 7, 8, 6, 7, 8, 9],
  [4, 6, 7, 8, 6, 7, 8, 9, 7, 8, 9, 9],
];

const B = [
  [1, 2, 2, 1, 2, 3],
  [1, 2, 3, 2, 3, 4],
  [3, 4, 5, 4, 5, 5],
  [4, 5, 5, 5, 6, 7],
  [6, 7, 8, 7, 8, 8],
  [7, 8, 8, 8, 9, 9],
];

const C = [
  [1, 1, 1, 2, 3, 3, 4, 5, 6, 7, 7, 7],
  [1, 2, 2, 3, 4, 4, 5, 6, 6, 7, 7, 8],
  [2, 3, 3, 3, 4, 5, 6, 7, 7, 8, 8, 8],
  [3, 4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9],
  [4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9, 9],
  [6, 6, 6, 7, 8, 8, 9, 9, 10, 10, 10, 10],
  [7, 7, 7, 8, 9, 9, 9, 10, 10, 11, 11, 11],
  [8, 8, 8, 9, 10, 10, 10, 10, 10, 11, 11, 11],
  [9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 12],
  [10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12],
  [11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12],
  [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12],
];

export const getRebaScore = (rebaResult: RebaDto) => {
  const rebaQuestions = getRebaDataFromResult(rebaResult);

  const A_i = Math.min(
    4,
    getValueOfRebaQuestions(rebaQuestions, [
      RebaItems.TRUNK_ANGLE,
      RebaItems.TRUNK_ADJUST,
    ]) - 1,
  );

  const A_j = Math.min(
    2,
    getValueOfRebaQuestions(rebaQuestions, [
      RebaItems.NECK_ANGLE,
      RebaItems.NECK_ADJUST,
    ]) - 1,
  );

  const A_k =
    getValueOfRebaQuestions(rebaQuestions, [
      RebaItems.LEG_DOWN,
      RebaItems.LEG_ANGLE,
    ]) - 1;

  const forceScore = getValueOfRebaQuestions(rebaQuestions, [
    RebaItems.FORCE_MODE,
  ]);

  const scoreA = A[A_i][A_j * 4 + A_k] + forceScore;

  const B_i = Math.max(
    0,
    getValueOfRebaQuestions(rebaQuestions, [
      RebaItems.UPPER_ARM_ANGLE,
      RebaItems.UPPER_ARM_ADJUST,
    ]) - 1,
  );

  const B_j =
    getValueOfRebaQuestions(rebaQuestions, [RebaItems.LOWER_ARM_ANGLE]) - 1;

  const B_k =
    getValueOfRebaQuestions(rebaQuestions, [
      RebaItems.WRIST_ANGLE,
      RebaItems.BENT_WRIST,
    ]) - 1;

  const couplingScore = getValueOfRebaQuestions(rebaQuestions, [
    RebaItems.COUPLING_MODE,
  ]);

  const scoreB = B[B_i][B_j * 3 + B_k] + couplingScore;

  const scoreC = C[scoreA - 1][scoreB - 1];

  const activityScore = getValueOfRebaQuestions(rebaQuestions, [
    RebaItems.ACTIVITY,
  ]);

  return scoreC + activityScore;
};
