import { Paper, Grid, Typography, Stack, Button } from "@mui/material";
import { FC, useContext } from "react";
import {
  TaskRo,
  useAssessmentsControllerGetAssessmentsQuery,
} from "src/app/services/generatedApi";
import { AssessmentContext } from "src/context/AssessmentContext";
import { AssessmentCard } from "../../assessment/AssessmentCard";

type AssessmentsListProps = {
  task: TaskRo;
};

export const AssessmentsList: FC<AssessmentsListProps> = ({ task }) => {
  const { reset, setOpenSelectAssessmentMethodDialog } =
    useContext(AssessmentContext);

  const { data: assessments = [], isLoading } =
    useAssessmentsControllerGetAssessmentsQuery({
      id: task.id,
    });

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={1} alignItems="center" justifyContent="center">
        <Typography variant="h5">Assessment Results</Typography>
        {assessments.length > 0 ? (
          <Grid
            id="assessment-items"
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {assessments.map((assessment) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={assessment.id}>
                <AssessmentCard
                  assessment={assessment}
                  fileName={task.outFileName}
                  havePose={task.havePose}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          !isLoading && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Typography>No results found.</Typography>
              <Button
                variant="text"
                onClick={() => {
                  setOpenSelectAssessmentMethodDialog(true);
                  reset();
                }}
              >
                Create new
              </Button>
            </Stack>
          )
        )}
      </Stack>
    </Paper>
  );
};
