import { Close, HelpOutline } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useState } from "react";
import { mmhFields, modeDescriptions } from "./constants";
import { taskModesMapper } from "src/components/organisms/assessment/mmh/constant";

export const MMHHelpDialog: FC<{
  mode: "LIFT" | "LOWER" | "PUSH" | "PULL" | "CARRY";
}> = ({ mode }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpOutline />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              {taskModesMapper[mode].title} Task
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>{modeDescriptions[mode]}</Typography>
            <Table>
              <TableHead>
                <TableRow
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#ddd" } }}
                >
                  <TableCell>Field</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Minimum</TableCell>
                  <TableCell>Maximum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mmhFields[mode].map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td": { py: 1, fontSize: 12 },
                    }}
                  >
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
