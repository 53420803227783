import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#04b485",
      light: "#A6E3E9",
    },
    secondary: {
      main: "#464646",
    },
  },
  typography: {
    button: {
      textTransform: "capitalize",
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
        },
        contained: {
          color: "#fff",
        },
        text: {
          ":hover": {
            backgroundColor: "#E3FDFD",
          },
        },
      },
    },
  },
});

export default theme;
