import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import { TaskRo } from "src/app/services/generatedApi";
import { HumanBodySVG } from "src/components/molecules/result/humanBodySVG/HumanBodySVG";
import { ShowAngleThreshold } from "src/components/molecules/result/ShowAngleThresholds";

export const HumanBodySection: FC<{ task: TaskRo }> = ({ task }) => (
  <Grid item xs={12} md={4}>
    <Stack direction="column" justifyContent="center">
      <HumanBodySVG />
      <ShowAngleThreshold setting={task.setting} />
    </Stack>
  </Grid>
);
