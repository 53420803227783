import {
  RebaRulaQuestionSelect,
  RebaRulaQuestionCheck,
  RebaRulaQuestionSelectOptional,
} from "../reba-rula/types";

export enum RebaItems {
  NECK_ANGLE = "NECK_ANGLE",
  NECK_ADJUST = "NECK_ADJUST",
  TRUNK_ANGLE = "TRUNK_ANGLE",
  TRUNK_ADJUST = "TRUNK_ADJUST",
  LEG_DOWN = "LEG_DOWN",
  LEG_ANGLE = "LEG_ANGLE",
  FORCE_MODE = "FORCE_MODE",
  SHOCK = "SHOCK",
  UPPER_ARM_ANGLE = "UPPER_ARM_ANGLE",
  UPPER_ARM_ADJUST = "UPPER_ARM_ADJUST",
  LOWER_ARM_ANGLE = "LOWER_ARM_ANGLE",
  WRIST_ANGLE = "WRIST_ANGLE",
  BENT_WRIST = "BENT_WRIST",
  COUPLING_MODE = "COUPLING_MODE",
  ACTIVITY = "ACTIVITY",
}

export const rebaItemsList: RebaItems[] = Object.keys(RebaItems) as any[];

export type RebaQuestions = {
  [RebaItems.NECK_ANGLE]: RebaRulaQuestionSelect<RebaNeck>;
  [RebaItems.NECK_ADJUST]: RebaRulaQuestionCheck;
  [RebaItems.TRUNK_ANGLE]: RebaRulaQuestionSelect<RebaTrunk>;
  [RebaItems.TRUNK_ADJUST]: RebaRulaQuestionCheck;
  [RebaItems.LEG_DOWN]: RebaRulaQuestionSelect<boolean>;
  [RebaItems.LEG_ANGLE]: RebaRulaQuestionSelectOptional<RebaLeg>;
  [RebaItems.FORCE_MODE]: RebaRulaQuestionSelect<RebaForceMode>;
  [RebaItems.SHOCK]: RebaRulaQuestionCheck;
  [RebaItems.UPPER_ARM_ANGLE]: RebaRulaQuestionSelect<RebaUpperArm>;
  [RebaItems.UPPER_ARM_ADJUST]: RebaRulaQuestionCheck;
  [RebaItems.LOWER_ARM_ANGLE]: RebaRulaQuestionSelect<RebaLowerArm>;
  [RebaItems.WRIST_ANGLE]: RebaRulaQuestionSelect<RebaWrist>;
  [RebaItems.BENT_WRIST]: RebaRulaQuestionCheck;
  [RebaItems.COUPLING_MODE]: RebaRulaQuestionSelect<RebaCouplingMode>;
  [RebaItems.ACTIVITY]: RebaRulaQuestionCheck;
};

export enum RebaNeck {
  ANGLE_0_MINUS = "ANGLE_0_MINUS",
  ANGLE_0_20 = "ANGLE_0_20",
  ANGLE_20_PLUS = "ANGLE_20_PLUS",
}

export enum RebaTrunk {
  ANGLE_0_MINUS = "ANGLE_0_MINUS",
  ANGLE_0_10 = "ANGLE_0_10",
  ANGLE_10_20 = "ANGLE_10_20",
  ANGLE_20_60 = "ANGLE_20_60",
  ANGLE_60_PLUS = "ANGLE_60_PLUS",
}

export enum RebaLeg {
  ANGLE_0_30 = "ANGLE_0_30",
  ANGLE_30_60 = "ANGLE_30_60",
  ANGLE_60_PLUS = "ANGLE_60_PLUS",
}

export enum RebaForceMode {
  LOAD_0_11_LBS = "LOAD_0_11_LBS",
  LOAD_11_22_LBS = "LOAD_11_22_LBS",
  LOAD_22_PLUS_LBS = "LOAD_22_PLUS_LBS",
}

export enum RebaUpperArm {
  ANGLE__20_MINUS = "ANGLE__20_MINUS",
  ANGLE__20_20 = "ANGLE__20_20",
  ANGLE_20_45 = "ANGLE_20_45",
  ANGLE_45_90 = "ANGLE_45_90",
  ANGLE_90_PLUS = "ANGLE_90_PLUS",
}

export enum RebaLowerArm {
  ANGLE_0_60_OR_100_PLUS = "ANGLE_0_60_OR_100_PLUS",
  ANGLE_60_100 = "ANGLE_60_100",
}

export enum RebaWrist {
  ANGLE__15_15 = "ANGLE__15_15",
  ANGLE_GT_15 = "ANGLE_GT_15",
}

export enum RebaCouplingMode {
  GOOD = "GOOD",
  FAIR = "FAIR",
  POOR = "POOR",
  UNACCEPTABLE = "UNACCEPTABLE",
}
