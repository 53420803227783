import {
  Circle as CircleIcon,
  AllInclusive as AllInclusiveIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  BatterySaver as BatterySaverIcon,
} from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import moment from "moment";
import { FC, useState, MouseEvent } from "react";
import { ActivateUserButton } from "src/components/atoms/ActivateUserButton";
import AdminAccessSwitch from "src/components/molecules/AdminAccessSwitch";
import { UserWithUsages } from "src/types/userTypes";
import { convertHMS } from "src/utils/timeUtils";

type UserTableRowPropsType = {
  row: UserWithUsages;
  index: number;
  setSelectedUser: (user: UserWithUsages) => void;
  setShowEditDialog: (show: boolean) => void;
  setShowLimitDialog: (show: boolean) => void;
};

export const UserTableRow: FC<UserTableRowPropsType> = ({
  row,
  index,
  setSelectedUser,
  setShowEditDialog,
  setShowLimitDialog,
}) => {
  const { username, firstName, lastName, email, isActive, usage, limit } = row;
  const labelId = `enhanced-table-checkbox-${index}`;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>, row: UserWithUsages) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openEditDialog = (user: UserWithUsages) => {
    setSelectedUser(user);
    setAnchorEl(null);
    setShowEditDialog(true);
  };

  const openLimitDialog = (user: UserWithUsages) => {
    setSelectedUser(user);
    setAnchorEl(null);
    setShowLimitDialog(true);
  };

  return (
    <TableRow hover tabIndex={-1} key={index}>
      <TableCell
        align="center"
        sx={{
          color: isActive ? green[500] : red[500],
          fontWeight: isActive ? "bold" : undefined,
        }}
      >
        <Tooltip title={isActive ? "activated" : "suspend"}>
          <CircleIcon
            color={isActive ? "primary" : "error"}
            sx={{ fontSize: 20 }}
          />
        </Tooltip>
      </TableCell>
      <TableCell
        align="center"
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        {username}
      </TableCell>
      <TableCell align="center">{firstName + " " + lastName}</TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
        <AdminAccessSwitch username={row.username} roleNames={row.roleNames} />
      </TableCell>
      <TableCell align="center">
        <Stack alignItems="center" justifyContent="center">
          <Typography sx={{ fontSize: 13 }}>
            {usage?.count
              ? usage.count + (usage.count === 1 ? " video" : " videos")
              : "-"}
          </Typography>
          <Typography sx={{ fontSize: 13 }}>
            {usage?.duration ? convertHMS(Math.round(usage.duration)) : "-"}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="center">
        <Stack alignItems="center" justifyContent="center">
          {!limit?.date && !limit?.count ? (
            <AllInclusiveIcon />
          ) : (
            <>
              {limit.count !== undefined ? (
                <Typography sx={{ fontSize: 13 }}>
                  {limit.count + (limit.count === 1 ? " video" : " videos")}
                </Typography>
              ) : (
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <AllInclusiveIcon fontSize="small" />
                  <Typography sx={{ fontSize: 13 }}>{" videos"}</Typography>
                </Stack>
              )}
              {limit.date !== undefined && (
                <Typography sx={{ fontSize: 13 }}>
                  {moment(limit.date).calendar()}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={(e) => handleMenu(e, row)}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => openEditDialog(row)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => openLimitDialog(row)}>
            <ListItemIcon>
              <BatterySaverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Limits</ListItemText>
          </MenuItem>
          <ActivateUserButton isActive={row.isActive} username={row.username} />
        </Menu>
      </TableCell>
    </TableRow>
  );
};
