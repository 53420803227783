import { FC } from "react";
import {
  Stack,
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import {
  Close,
  InsertDriveFile,
  CheckCircle,
  Warning,
} from "@mui/icons-material";

export const UploadFileCard: FC<{
  file: File;
  progress: number;
  status: "uploading" | "success" | "error";
  cancel: () => void;
}> = ({ file, progress, status, cancel }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Stack direction="row" columnGap={1} alignItems="center">
        <InsertDriveFile />
        <Typography>{file.name}</Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ position: "relative" }}
      >
        {status === "uploading" && (
          <CircularProgress
            variant={progress === 100 ? "indeterminate" : "determinate"}
            value={progress}
            size={30}
          />
        )}
        {status === "success" && <CheckCircle color="success" />}
        {status === "error" && <Warning color="error" />}
        {status === "uploading" && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {progress === 100 ? (
              <CheckCircle color="success" />
            ) : (
              <IconButton onClick={cancel}>
                <Close color="error" />
              </IconButton>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
