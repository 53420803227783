import { Grid } from "@mui/material";
import { FC } from "react";
import { TaskRo } from "src/app/services/generatedApi";
import { VideoPlayer } from "src/components/molecules/result/VideoPlayer/VideoPlayer";
import { DownloadButtonsSection } from "./sections/DownloadButtonsSection";

export const VideoPlayerSection: FC<{
  task: TaskRo;
  assessmentPageIndex: number;
  downloadCsvReport: () => void;
  openReportDialog: boolean;
  setOpenReportDialog: (open: boolean) => void;
}> = ({
  task,
  assessmentPageIndex,
  downloadCsvReport,
  openReportDialog,
  setOpenReportDialog,
}) => (
  <Grid item xs={12} md={8} id="videoPlayerContainer">
    <VideoPlayer
      fileName={task.outFileName}
      havePose={task.havePose}
      controls={!assessmentPageIndex}
      mouseListening={assessmentPageIndex < 2}
    />
    <DownloadButtonsSection
      task={task}
      assessmentPageIndex={assessmentPageIndex}
      openReportDialog={openReportDialog}
      setOpenReportDialog={setOpenReportDialog}
      downloadCsvReport={downloadCsvReport}
    />
  </Grid>
);
