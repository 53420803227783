import { forwardRef, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { VideoPlayerPropsType } from "../VideoPlayer";

export const DomVideoPlayer = forwardRef<
  HTMLVideoElement,
  VideoPlayerPropsType
>(({ src, controls = true, onUpdate, onLoaded }, ref) => {
  const requestRef = useRef<number>();

  useEffect(() => {
    const animate = () => {
      onUpdate();
      requestRef.current = requestAnimationFrame(animate);
    };
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, [onUpdate]);

  return (
    <Box
      component="video"
      onLoadedMetadata={onLoaded}
      ref={ref}
      controls={controls}
      sx={{
        borderRadius: 1,
        overflow: "hidden",
        maxWidth: "100%",
        height: "auto",
        maxHeight: "500px",
        background: "none",
      }}
      crossOrigin="anonymous"
    >
      {src && <source src={src} type="video/mp4" />}
    </Box>
  );
});
