import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { FC } from "react";
import { useAssessmentsControllerDeleteAssessmentMutation } from "src/app/services/generatedApi";

type DeleteDialogPropsType = {
  id: string;
  open: boolean;
  handleClose: () => void;
};

export const DeleteDialog: FC<DeleteDialogPropsType> = ({
  id,
  open,
  handleClose,
}) => {
  const [deleteAssessment, { isLoading }] =
    useAssessmentsControllerDeleteAssessmentMutation();

  const submit = () => {
    deleteAssessment({ idDto: { id } });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to delete this record?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          cancel
        </Button>
        <LoadingButton loading={isLoading} onClick={submit} color="error">
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
