import { FC, useContext, useMemo, useRef } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomLegend from "./CustomLegend";
import LineChartTooltip from "../typeOne/LineChartTooltip";
import { AssessmentContext } from "src/context/AssessmentContext";
import { useSyncByVideo } from "src/components/molecules/result/charts/useSyncByVideo";
import { TaskContext } from "src/context/TaskContext";
import { ThresholdChartLinearGradient } from "src/components/atoms/ThresholdChartLinearGradient";

type AssessmentsChartProps = {
  assessmentAlgorithm: string;
  data: {
    angle: number;
    seconds: number;
  }[];
};

export const AssessmentsChart: FC<AssessmentsChartProps> = ({
  assessmentAlgorithm,
  data,
}) => {
  const { frameIndex, setSelectedFrame } = useContext(AssessmentContext);
  const { fps } = useContext(TaskContext);

  const randomNumber = useRef(Math.floor(Math.random() * 1000));

  const threshold = {
    threshold1: 0,
    threshold2: 0,
    threshold3: 2,
    threshold4: 7,
  };

  const minMax = useMemo(() => {
    const numbersArray = data
      .filter(({ angle }) => angle !== undefined)
      .map(({ angle }) => angle);
    return {
      min: Math.min(...numbersArray),
      max: Math.max(...numbersArray),
    };
  }, [data]);

  const { onChange: onChangeChartSecond, dotX } = useSyncByVideo(true);

  const dotY =
    dotX !== undefined ? data?.[Math.round(dotX * fps)]?.angle : undefined;

  const handleChartClick = () => {
    if (dotX === undefined) return;
    const frameIdx = Math.round(dotX * fps);
    if (frameIdx === undefined || !data[frameIdx]) return;

    setSelectedFrame(frameIdx);
  };

  const handleMouseMove = (e: any) => {
    if (!e?.isTooltipActive || !e.activePayload?.[0].payload) return;
    onChangeChartSecond(e.activePayload[0].payload.seconds);
  };

  const handleMouseLeave = () => {
    if (frameIndex === undefined) return;
    onChangeChartSecond(frameIndex / fps);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          left: 20,
          bottom: 17,
        }}
        onClick={handleChartClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          label={{ value: "Time", position: "bottom" }}
          type="number"
          allowDecimals={false}
          dataKey="seconds"
          name="seconds"
          unit="″"
          domain={[0, Math.round(data[data.length - 1]?.seconds)]}
        />
        <YAxis
          name="angle"
          label={{
            value: "Score",
            angle: -90,
            position: "left",
          }}
          domain={[minMax.min - 1, minMax.max + 1]}
          tickFormatter={(value) => (Math.round(100 * +value) / 100).toString()}
        />
        <Tooltip
          wrapperStyle={{
            visibility: "visible",
          }}
          content={<LineChartTooltip isAssessment={true} />}
        />
        <Legend
          wrapperStyle={{ position: "absolute", top: "-30px" }}
          content={<CustomLegend />}
        />
        <defs>
          <ThresholdChartLinearGradient
            id={`assessment-chart-${randomNumber.current}`}
            minMax={minMax}
            threshold={threshold}
          />
        </defs>
        <Line
          type="monotone"
          name="angle"
          dataKey="angle"
          stroke={`url(#assessment-chart-${randomNumber.current})`}
          strokeWidth={3}
          dot={false}
          activeDot={{ r: 6, fill: "black" }}
        />
        {frameIndex !== undefined && (
          <ReferenceDot
            x={data?.[frameIndex]?.seconds}
            y={data?.[frameIndex]?.angle}
            r={5}
            fill="blue"
            stroke="white"
          />
        )}
        {dotX !== undefined && dotY !== undefined && (
          <ReferenceDot
            x={dotX}
            y={dotY}
            r={3}
            fill="#000"
            stroke="white"
            strokeWidth={1}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
