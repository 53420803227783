import {
  Dangerous,
  ErrorOutline,
  GppGood,
  ReportProblem,
} from "@mui/icons-material";

export type RulaScoreDetailType = {
  range: { min?: number; max?: number };
  detail: string;
  color: string;
  icon: any;
};

export const rulaScoresDetail: RulaScoreDetailType[] = [
  {
    range: { min: 1, max: 2 },
    detail: "Acceptable posture",
    color: "#B2DFDB",
    icon: GppGood,
  },
  {
    range: { min: 3, max: 4 },
    detail: "Further investigation, change may be needed",
    color: "#FFF176",
    icon: ErrorOutline,
  },
  {
    range: { min: 5, max: 6 },
    detail: "Further investigation, change soon",
    color: "#FFB74D",
    icon: ReportProblem,
  },
  {
    range: { min: 7, max: 7 },
    detail: "Investigate and implement change",
    color: "#EF5350",
    icon: Dangerous,
  },
];

export const getRulaScoresDetailItem = (score: number) => {
  let index = 0;
  for (; index < rulaScoresDetail.length - 1; index++) {
    const max = rulaScoresDetail[index].range.max;
    if (max === undefined || max >= score) {
      break;
    }
  }
  return rulaScoresDetail[index];
};
