import { FC, useState } from "react";
import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Loading from "src/components/atoms/Loading";
import CategorySettings from "src/components/organisms/category/CategorySettings";
import {
  SettingDto,
  useCategoryControllerCreateCategoryMutation,
  useCategoryControllerCreateSystemCategoryMutation,
} from "src/app/services/generatedApi";
import { toast } from "react-toastify";
import { useAppSelector } from "src/app/hooks";

type AddAngleThresholdsPropsType = {
  closeModalFunc: () => any;
};

export const categoryDefaultSettings: SettingDto = {
  neck: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 10,
    threshold4: 20,
  },
  back: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 20,
    threshold4: 60,
  },
  rightKnee: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 30,
    threshold4: 60,
  },
  leftKnee: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 30,
    threshold4: 60,
  },
  rightUpperArm: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 20,
    threshold4: 90,
  },
  leftUpperArm: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 20,
    threshold4: 90,
  },
  rightLowerArm: {
    threshold1: 30,
    threshold2: 60,
    threshold3: 100,
    threshold4: 140,
  },
  leftLowerArm: {
    threshold1: 30,
    threshold2: 60,
    threshold3: 100,
    threshold4: 140,
  },
  leftWrist: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 15,
    threshold4: 25,
  },
  rightWrist: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 15,
    threshold4: 25,
  },
};

const AddAngleThresholds: FC<AddAngleThresholdsPropsType> = ({
  closeModalFunc,
}) => {
  const [name, setName] = useState("");
  const [isSystemCategory, setIsSystemCategory] = useState(false);
  const [settings, setSettings] = useState(categoryDefaultSettings);

  const [createCategory, { isLoading: createCategoryLoading }] =
    useCategoryControllerCreateCategoryMutation();

  const switchChangeHandler = () =>
    setIsSystemCategory((prevState) => !prevState);

  const [
    createCategoryAsAdminDefault,
    { isLoading: createCategoryAsAdminDefaultLoading },
  ] = useCategoryControllerCreateSystemCategoryMutation();

  const { auth } = useAppSelector((state) => state);

  const createCategoryHandler = () => {
    if (!name || !settings) return;

    if (auth?.isAdmin) {
      createCategoryAsAdminDefault({
        createSystemCategoryDto: {
          name,
          setting: settings,
          isSystemCategory,
        },
      })
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Your system angle thresholds successfully created");
            closeModalFunc();
          }
        });
    } else {
      createCategory({
        createCategoryDto: {
          name,
          setting: settings,
        },
      })
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Your angle thresholds successfully created");
            closeModalFunc();
          }
        });
    }
  };

  return (
    <>
      {(createCategoryLoading || createCategoryAsAdminDefaultLoading) && (
        <Loading />
      )}
      <DialogTitle sx={{ textAlign: "center" }}>
        Add new angle thresholds
      </DialogTitle>
      <DialogContent
        sx={{
          p: { xs: "auto", md: 1 },
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            mb: 4,
            mt: 2,
            px: { xs: 0, md: 3 },
          }}
          columnGap={{ xs: 0, md: 7 }}
          rowGap={{ xs: 2, md: 0 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md>
            <TextField
              fullWidth
              label="Angle thresholds name"
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
          </Grid>
          <Grid item xs={12} md>
            {auth?.isAdmin && (
              <FormControlLabel
                sx={{ ml: "auto" }}
                control={
                  <Switch
                    checked={isSystemCategory}
                    onClick={switchChangeHandler}
                  />
                }
                label="Set as system angle threshold"
              />
            )}
          </Grid>
        </Grid>
        <CategorySettings value={settings} onChange={setSettings} />
      </DialogContent>
      <DialogActions sx={{ pr: { xs: 1, sm: 2, md: 4 }, pb: 2 }}>
        <Button
          sx={{ width: "100%", maxWidth: "130px" }}
          onClick={closeModalFunc}
        >
          Cancel
        </Button>
        <Button
          onClick={createCategoryHandler}
          disabled={!name}
          sx={{ width: "100%", maxWidth: "130px" }}
          variant="contained"
          type="submit"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default AddAngleThresholds;
