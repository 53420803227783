import { FC, MouseEvent } from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { tableOrderType } from "src/pages/Tasks";
import { headCells, HeadIdType } from "./struct";

type UsersTableHeadPropsType = {
  onRequestSort: (event: MouseEvent<unknown>, property: HeadIdType) => void;
  order: tableOrderType;
  orderBy: string;
};

const UsersTableHead: FC<UsersTableHeadPropsType> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: HeadIdType) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default UsersTableHead;
