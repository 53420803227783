export enum QuestionType {
  CHECK = "CHECK",
  SELECT = "SELECT",
  SELECT_OPTIONAL = "SELECT_OPTIONAL",
}

export type RebaRulaItem = {
  text: string;
  value: number;
  img?: any;
};

export type RebaRulaItemSelect<T> = RebaRulaItem & {
  id: T;
};

export type RebaRulaItemCheck = RebaRulaItem & {
  selected: boolean;
};

type RebaRulaQuestionBase = {
  type: QuestionType;
  question?: string;
  update?: (...props: any) => void;
};

export type RebaRulaQuestionSelect<T> = RebaRulaQuestionBase & {
  type: QuestionType.SELECT;
  selectedIndex: number;
  items: RebaRulaItemSelect<T>[];
  update: (...props: any) => void;
};

export type RebaRulaQuestionSelectOptional<T> = RebaRulaQuestionBase & {
  type: QuestionType.SELECT_OPTIONAL;
  selectedIndex?: number;
  items: RebaRulaItemSelect<T>[];
  update: (...props: any) => void;
};

export type RebaRulaQuestionCheck = RebaRulaQuestionBase & {
  type: QuestionType.CHECK;
  items: RebaRulaItemCheck[];
};

export type RebaRulaQuestion<T> =
  | RebaRulaQuestionSelect<T>
  | RebaRulaQuestionSelectOptional<T>
  | RebaRulaQuestionCheck;
