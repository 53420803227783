import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const PushIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M22.8256 11.3332H15.9191C15.9289 12.0575 15.2276 12.3127 14.6127 12.33V18.4263H14.6128C14.6128 18.6325 14.7798 18.7996 14.9862 18.7996H22.8258C22.9247 18.7996 23.0196 18.7603 23.0896 18.6903C23.1597 18.6203 23.1991 18.5253 23.1991 18.4263V11.7067H23.1989C23.1989 11.6077 23.1596 11.5127 23.0896 11.4427C23.0196 11.3727 22.9246 11.3333 22.8256 11.3333L22.8256 11.3332ZM20.2126 14.5997H17.5994V11.9865H20.2126V14.5997Z" />
      <path d="M15.5356 11.2253C15.5072 11.0795 15.4221 10.9508 15.2987 10.8678C15.1754 10.785 15.0241 10.7545 14.8782 10.7835C13.5202 11.0526 12.1107 10.8553 10.8786 10.2237C10.8798 10.0802 10.8535 9.93768 10.8008 9.80403C12.4687 10.63 14.5449 9.09857 14.2128 7.24416V7.24432C14.103 6.5032 13.657 5.85423 13.0044 5.48625C12.3517 5.11827 11.5656 5.07227 10.8746 5.36192C10.1837 5.65141 9.66503 6.24405 9.46971 6.96734C9.27439 7.69063 9.42421 8.46358 9.87552 9.06174C8.83706 8.70526 7.70778 8.71559 6.67602 9.09107C4.4518 9.92934 3.42119 12.3797 3.79651 13.1002C4.0665 14.8408 3.52302 16.6927 1.35312 17.3313V17.3315C1.09546 17.4007 0.894304 17.602 0.825315 17.8596C0.756485 18.1173 0.830315 18.3923 1.01897 18.5808C1.20763 18.7693 1.48261 18.8427 1.74026 18.7736C2.78537 18.4918 3.70718 17.8708 4.36114 17.0082C5.0151 16.1457 5.36427 15.0904 5.3536 14.008C5.83707 14.1747 6.23471 14.5262 6.45955 14.9854C6.80836 15.8036 6.44388 16.8379 5.59278 17.4448H5.59294C5.43112 17.5597 5.32179 17.734 5.28896 17.9296C5.25596 18.1251 5.30229 18.3258 5.41745 18.4871C5.53261 18.6486 5.70727 18.7574 5.90293 18.7899C6.09859 18.8224 6.29908 18.7757 6.46021 18.6602C8.82191 16.9838 8.50943 13.6258 6.0119 12.6642C6.24788 12.0246 6.73651 11.5102 7.36317 11.2419C7.98982 10.9734 8.69925 10.9746 9.32505 11.2451C9.64253 11.3777 10.0035 11.3562 10.303 11.1868C11.3441 11.7339 12.5027 12.02 13.6788 12.0204C14.2683 11.9515 15.6431 12.1435 15.5356 11.2252L15.5356 11.2253Z" />
      <path d="M20.2978 7.78652H16.1061C15.7968 7.78652 15.5462 8.03734 15.5462 8.34649C15.5462 8.65581 15.7968 8.90646 16.1061 8.90646H20.2978V9.69874C20.2978 9.76541 20.3333 9.82707 20.3911 9.8604C20.4488 9.89374 20.5199 9.89374 20.5777 9.8604L22.9191 8.50845V8.50828C22.9768 8.47495 23.0125 8.41329 23.0125 8.34662C23.0125 8.27996 22.9768 8.2183 22.9191 8.18496L20.5777 6.83301C20.5199 6.79968 20.4488 6.79968 20.3911 6.83301C20.3333 6.86634 20.2978 6.92801 20.2978 6.99467V7.78652Z" />
    </SvgIcon>
  );
};
