import { UserRo, UserUsageAndLimitRo } from "src/app/services/generatedApi";
import { UserWithUsages } from "src/types/userTypes";
import { liveSearch } from "./liveSearchUtils";

type filterByRoleType = {
  originalArray: UserWithUsages[];
  selectedRole: string;
};

export const filterByRole = ({
  originalArray,
  selectedRole,
}: filterByRoleType) =>
  [...originalArray].filter(({ roleNames }) =>
    roleNames.find((role) => role === selectedRole)
  );

type usersFilterType = {
  originalArray?: UserWithUsages[];
  selectedRole: string;
  inputValue: string;
  searchItem: keyof UserRo;
};

export const usersFilter = ({
  originalArray = [],
  selectedRole,
  inputValue,
  searchItem,
}: usersFilterType) => {
  let result: typeof originalArray = [...originalArray];
  if (inputValue) {
    result = liveSearch({
      inputValue,
      originalArray: result,
      searchItem,
    });
  }
  if (selectedRole !== "All") {
    result = filterByRole({ originalArray: result, selectedRole });
  }

  return result;
};

export type addUsersUsageType = {
  users: UserRo[] | undefined;
  usersUsageAndLimit: UserUsageAndLimitRo[] | undefined;
};

export const addUsersUsage = ({
  users,
  usersUsageAndLimit,
}: addUsersUsageType) => {
  if (!users || !usersUsageAndLimit) return [];
  let result: UserWithUsages[] = [];
  users.forEach((user) => {
    const userUsageAndLimit = usersUsageAndLimit.find(
      ({ creator }) => creator === user.username
    );

    result.push({
      ...user,
      usage: userUsageAndLimit?.usage,
    });
  });
  return result;
};
