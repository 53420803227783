import {
  EventRepeat as EventRepeatIcon,
  Scale as ScaleIcon,
} from "@mui/icons-material";
import { FC } from "react";
import {
  LiftLowerCarryLoadDto,
  PushPullCarryTaskDataDto,
} from "src/app/services/generatedApi";
import { HorizontalDistanceIcon } from "src/components/atoms/Icons/HorizontalDistanceIcon";
import { VerticalIcon } from "src/components/atoms/Icons/VerticalIcon";
import {
  UnitModes,
  UnitValues,
} from "src/components/molecules/assessment/mmh/constants";
import { MMHFormField } from "src/components/molecules/assessment/mmh/FormField";

export const Carry: FC<{
  data: PushPullCarryTaskDataDto;
  load: LiftLowerCarryLoadDto;
  onChangeData: (name: keyof PushPullCarryTaskDataDto, value: any) => void;
  onChangeLoad: (name: keyof LiftLowerCarryLoadDto, value: any) => void;
  onChangeError: (
    name: string
  ) => (errorState: { men: boolean; women: boolean }) => void;
}> = ({ data, load, onChangeData, onChangeLoad, onChangeError }) => {
  return (
    <>
      <MMHFormField
        label="Frequency"
        icon={<EventRepeatIcon />}
        unitMode={UnitModes.Frequency}
        min={1 / 1440}
        max={10}
        value={data.frequency}
        onChange={(value) => onChangeData("frequency", value)}
        setErrorState={onChangeError("frequency")}
      />

      <MMHFormField
        label="Load"
        icon={<ScaleIcon />}
        unitMode={UnitModes.Weight}
        textFieldProps={{
          inputProps: {
            step: 0.5,
          },
        }}
        min={0}
        value={load.objectWeight}
        onChange={(value) => onChangeLoad("objectWeight", value)}
        setErrorState={onChangeError("objectWeight")}
      />

      <MMHFormField
        label="Horizontal Distance"
        icon={<HorizontalDistanceIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.FEET, UnitValues.METER]}
        min={2.1}
        max={10}
        value={data.horizontalDistance}
        onChange={(value) => onChangeData("horizontalDistance", value)}
        setErrorState={onChangeError("horizontalDistance")}
      />

      <MMHFormField
        label="Vertical Hand Height"
        icon={<VerticalIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.INCH, UnitValues.CENTIMETER]}
        minF={0.71}
        maxF={1.03}
        minM={0.78}
        maxM={1.1}
        value={data.verticalHandHeight}
        onChange={(value) => onChangeData("verticalHandHeight", value)}
        setErrorState={onChangeError("verticalHandHeight")}
      />
    </>
  );
};
