export enum UnitModes {
  Frequency = "Frequency",
  Distance = "Distance",
  Weight = "Weight",
}

export enum MeasurementSystem {
  Metric = "Metric",
  Imperial = "Imperial",
}

export enum UnitValues {
  PER_MINUTE = "PER_MINUTE",
  PER_HOUR = "PER_HOUR",
  PER_DAY = "PER_DAY",
  METER = "METER",
  CENTIMETER = "CENTIMETER",
  INCH = "INCH",
  FEET = "FEET",
  KILOGRAM = "KILOGRAM",
  POUND = "POUND",
}

export const unitsItems: {
  [key in UnitModes]: {
    value: UnitValues;
    label: string;
    system?: MeasurementSystem;
    symbol: string;
    toStdFactor?: number;
    toStd?: (value: any) => any;
    fromStd?: (value: any) => any;
  }[];
} = {
  [UnitModes.Frequency]: [
    {
      value: UnitValues.PER_MINUTE,
      label: "Per Minute",
      symbol: "x Per Minute",
    },
    {
      value: UnitValues.PER_HOUR,
      label: "Per Hour",
      symbol: "x Hourly",
      toStdFactor: 1 / 60,
    },
    {
      value: UnitValues.PER_DAY,
      label: "Per Day",
      symbol: "x Daily",
      toStdFactor: 1 / 1440,
    },
  ],
  [UnitModes.Distance]: [
    {
      value: UnitValues.METER,
      label: "Meter (m)",
      symbol: "m",
      system: MeasurementSystem.Metric,
    },
    {
      value: UnitValues.CENTIMETER,
      label: "Centimeter (cm)",
      symbol: "cm",
      system: MeasurementSystem.Metric,
      toStdFactor: 0.01,
    },
    {
      value: UnitValues.INCH,
      label: "Inch (in)",
      symbol: '"',
      system: MeasurementSystem.Imperial,
      toStdFactor: 0.0254,
    },
    {
      value: UnitValues.FEET,
      label: "Feet (ft)",
      symbol: "ft",
      system: MeasurementSystem.Imperial,
      toStdFactor: 0.3048,
    },
  ],
  [UnitModes.Weight]: [
    {
      value: UnitValues.KILOGRAM,
      label: "Kilogram (kg)",
      symbol: "kg",
      system: MeasurementSystem.Metric,
    },
    {
      value: UnitValues.POUND,
      label: "Pound (lb)",
      symbol: "lb",
      system: MeasurementSystem.Imperial,
      toStdFactor: 0.453592,
    },
  ],
};

export const mmhFields = {
  LIFT: [
    ["Hand Coupling", "Indicates the quality of grip on the object.", "", ""],
    ["Frequency", "Number of lift/lower tasks.", "1 per day", "20 per minute"],
    [
      "Load (Object Weight)",
      "Weight of the object being lifted/lowered.",
      "",
      "",
    ],
    ["Start Hand Height", "Height of hands at the start of the task.", "", ""],
    ["End Hand Height", "Height of hands at the end of the task.", "", ""],
    [
      "Start Hand Distance",
      "Horizontal distance from hands to ankles at start.",
      "0.25 m",
      "2.14 m",
    ],
    [
      "End Hand Distance",
      "Horizontal distance from hands to ankles at end.",
      "0.25 m",
      "2.14 m",
    ],
  ],
  LOWER: [
    ["Hand Coupling", "Indicates the quality of grip on the object.", "", ""],
    ["Frequency", "Number of lift/lower tasks.", "1 per day", "20 per minute"],
    [
      "Load (Object Weight)",
      "Weight of the object being lifted/lowered.",
      "",
      "",
    ],
    [
      "Start Hand Height",
      "Height of hands at the start of the task.",
      "",
      "2.14 m",
    ],
    [
      "End Hand Height",
      "Height of hands at the end of the task.",
      "",
      "2.14 m",
    ],
    [
      "Start Hand Distance",
      "Horizontal distance from hands to ankles at start.",
      "0.25 m",
      "2.14 m",
    ],
    [
      "End Hand Distance",
      "Horizontal distance from hands to ankles at end.",
      "0.25 m",
      "2.14 m",
    ],
  ],
  PUSH: [
    [
      "Frequency",
      "Number of push tasks per minute.",
      "1 per day",
      "20 per minute",
    ],
    [
      "Initial Force",
      "Peak force required to initiate object movement.",
      "0 kg",
      "",
    ],
    [
      "Sustained Force",
      "Average force required to keep the object in motion.",
      "0 kg",
      "",
    ],
    [
      "Horizontal Distance",
      "Distance in meters the object is pushed.",
      "2.1 m",
      "61 m",
    ],
    [
      "Vertical Hand Height",
      "Average hand height above the ground during the task.",
      "0.58 m",
      "1.44 m",
    ],
  ],
  PULL: [
    [
      "Frequency",
      "Number of pull tasks per minute.",
      "1 per day",
      "10 per minute",
    ],
    [
      "Initial Force",
      "Peak force required to initiate object movement.",
      "0 kg",
      "",
    ],
    [
      "Sustained Force",
      "Average force required to keep the object in motion.",
      "0 kg",
      "",
    ],
    [
      "Horizontal Distance",
      "Distance in meters the object is pulled.",
      "2.1 m",
      "61 m",
    ],
    [
      "Vertical Hand Height",
      "Average hand height above the ground during the task.",
      "0.58 m",
      "1.44 m",
    ],
  ],
  CARRY: [
    [
      "Frequency",
      "Number of carry tasks per minute.",
      "1 per day",
      "20 per minute",
    ],
    ["Load (Object Weight)", "Weight of the object being carried.", "0 kg", ""],
    [
      "Horizontal Distance",
      "Distance in meters the object is carried.",
      "2.1 m",
      "10 m",
    ],
    [
      "Vertical Hand Height",
      "Average hand height above the ground during the task.",
      "0.71 m",
      "1.1 m",
    ],
  ],
};

export const modeDescriptions = {
  LIFT: "In the Lifting Task, the ergonomic evaluation focuses on hand-coupling, object weight, and the hand's vertical positioning. A good hand-to-object connection is vital for safe lifting, along with an understanding of the object's weight to ensure it's within a manageable range for the individual. The assessment also examines the vertical movement of hands from the start to the end of the lift, measuring the average height of the hands above the ground at both points. This data aids in identifying ergonomic risks, promoting safer lifting techniques, and minimizing the physical strain associated with manual materials handling tasks.",
  LOWER:
    "In the Lowering Task, the ergonomic assessment revolves around a careful examination of hand-coupling, object weight, and the vertical positioning of hands during the task. Effective hand-coupling, indicating a good grip between the handler and the object, is crucial to ensure safety and ease during lowering operations. The weight of the object is another critical factor, and understanding the force needed to safely lower objects is essential. The start and end heights of hands above ground level during the task are also assessed to provide insights into the ergonomic efficiency of the lowering process, aiming to promote safer and more efficient manual materials handling practices.",
  PUSH: "In the Pushing Task, the ergonomic assessment emphasizes understanding the initial and sustained forces required to move the object. The initial force is the peak force necessary to commence the object's movement, while the sustained force is the average force needed to maintain the object in motion. Moreover, the horizontal distance over which the object is pushed is also evaluated. These parameters are crucial for identifying ergonomic risks and ensuring that the task is carried out safely and efficiently, reducing the potential for strain or injury while performing manual materials handling tasks.",
  PULL: "In the Pulling Task, ergonomic assessment is centered around gauging the initial and sustained forces involved in moving the object. The initial force refers to the peak force needed to initiate movement, while the sustained force is the ongoing average force required to keep the object in motion. Additionally, the horizontal distance that the object is pulled is also taken into account. Through evaluating these parameters, ergonomic risks can be identified, promoting safer handling practices and reducing the likelihood of physical strain or injury during manual materials handling tasks.",
  CARRY:
    "In the Carrying Task, the ergonomic assessment focuses on the weight of the object and the vertical hand height during the task, ensuring a comfortable and safe hand-to-object coupling. The horizontal distance over which the object is carried is also evaluated. These parameters are crucial in identifying potential ergonomic risks, promoting safer manual handling practices, and minimizing the physical strain. Through this assessment, the aim is to create a safer and more efficient working environment, reducing the likelihood of injuries associated with manual materials handling tasks.",
};
