import { Box, Button, Divider, Grow, Stack, Typography } from "@mui/material";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MMHResultType, getResultsFromData, taskModesMapper } from "./constant";
import { MMHResult } from "src/components/molecules/assessment/mmh/Result";
import {
  LiftLowerCarryLoadDto,
  LiftLowerTaskDataDto,
  MmhDto,
  PushPullCarryTaskDataDto,
  PushPullLoadDto,
} from "src/app/services/generatedApi";
import { Container } from "@mui/system";
import { CalcTaskModes } from "src/app/logic/assessment/mmh/constants";
import { Save } from "@mui/icons-material";
import { SaveDialog } from "src/components/molecules/assessment/SaveDialog";
import { SelectTaskMode } from "./SelectTaskMode";
import { TaskDataForm } from "./TaskDataForm";
import { AssessmentContext } from "src/context/AssessmentContext";

type MMHPageProps = {};

export const MMHPage: FC<MMHPageProps> = () => {
  const [hasError, setHasError] = useState({ men: false, women: false });
  const [errorMessage, setErrorMessage] = useState("");

  const showHasError = useRef({ men: false, women: false });

  const { assessmentDataDto, setAssessmentDataDto, id, removeId } =
    useContext(AssessmentContext);
  const [results, setResults] = useState<MMHResultType[]>([]);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const mmhDto = useMemo(
    () => assessmentDataDto?.mmh,
    [assessmentDataDto?.mmh],
  );

  useEffect(() => {
    if (!id || !assessmentDataDto) return;
    submit(assessmentDataDto.mmh);
  }, [id]);

  useEffect(() => {
    if (id) return;

    setResults([]);
  }, [assessmentDataDto?.mmh?.mode]);

  const setMmhDto = (newMmhDto?: MmhDto) => {
    setAssessmentDataDto({ mmh: newMmhDto });
  };

  const checkVelocityError = () => {
    let data;
    let newErrorMessage = "";
    switch (mmhDto?.mode) {
      case "LIFT":
      case "LOWER":
        data = mmhDto.data as LiftLowerTaskDataDto;
        if (
          Math.abs(data.startHandHeight - data.endHandHeight) * data.frequency >
          11
        ) {
          newErrorMessage = "Vertical velocity must not be > 11m/min";
          showHasError.current = { men: true, women: true };
        }
        break;
      case "CARRY":
        data = mmhDto.data as PushPullCarryTaskDataDto;
        if (data.horizontalDistance * data.frequency > 29) {
          newErrorMessage = "Horizontal velocity must not be > 29m/min";
          showHasError.current = { men: true, women: true };
        }
        break;
      case "PUSH":
      case "PULL":
        data = mmhDto.data as PushPullCarryTaskDataDto;
        if (data.horizontalDistance * data.frequency > 37) {
          newErrorMessage = "Horizontal velocity must not be > 37m/min";
          showHasError.current = { men: true, women: true };
        }
        break;
    }

    setErrorMessage(newErrorMessage);
  };

  const submit = (mmhData?: MmhDto) => {
    showHasError.current = hasError;
    if (!mmhData) return;
    setResults(
      mmhData.mode === "PUSH"
        ? [
            getResultsFromData(
              CalcTaskModes.PUSH_INITIAL,
              mmhData.data,
              (mmhData.load as PushPullLoadDto).initialForce,
            ),
            getResultsFromData(
              CalcTaskModes.PUSH_SUSTAINED,
              mmhData.data,
              (mmhData.load as PushPullLoadDto).sustainedForce,
            ),
          ]
        : mmhData.mode === "PULL"
          ? [
              getResultsFromData(
                CalcTaskModes.PULL_INITIAL,
                mmhData.data,
                (mmhData.load as PushPullLoadDto).initialForce,
              ),
              getResultsFromData(
                CalcTaskModes.PULL_SUSTAINED,
                mmhData.data,
                (mmhData.load as PushPullLoadDto).sustainedForce,
              ),
            ]
          : [
              getResultsFromData(
                taskModesMapper[mmhData.mode].calculateModes[0],
                mmhData.data,
                (mmhData.load as LiftLowerCarryLoadDto).objectWeight,
              ),
            ],
    );

    checkVelocityError();
  };

  return (
    <Stack spacing={3}>
      <SelectTaskMode
        mmhDto={mmhDto}
        setMmhDto={(newMmhDto?: MmhDto) => {
          setMmhDto(newMmhDto);
          removeId();
        }}
      />
      <Grow in={!!mmhDto} timeout={500}>
        <Box>
          <TaskDataForm
            mmhDto={mmhDto}
            setMmhDto={setMmhDto}
            onSubmit={() => submit(mmhDto)}
            onChangeHasError={setHasError}
            errorMessage={errorMessage}
          />
        </Box>
      </Grow>

      <Grow in={results.length > 0} timeout={500}>
        <Stack spacing={1} alignItems="center">
          <Divider sx={{ width: "100%" }}>
            <Typography variant="h6">Result</Typography>
          </Divider>
          <Container maxWidth="sm">
            <Stack spacing={1}>
              {results.map((result, index) => (
                <MMHResult
                  result={result}
                  key={index}
                  hasError={showHasError.current}
                />
              ))}
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                fullWidth
                onClick={() => setOpenSaveDialog(true)}
              >
                Save result
              </Button>
            </Stack>
          </Container>
        </Stack>
      </Grow>
      <SaveDialog
        open={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
      />
    </Stack>
  );
};
