import { FC, Dispatch, SetStateAction } from "react";
import { Toolbar, Typography, Grid } from "@mui/material";
import ToolbarFilters from "./ToolbarFilters";

type UsersTableToolbarPropsType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  role: string;
  setRole: Dispatch<SetStateAction<string>>;
};

const UsersTableToolbar: FC<UsersTableToolbarPropsType> = ({
  search,
  setSearch,
  role,
  setRole,
}) => {
  return (
    <Toolbar
      sx={{
        py: 3,
      }}
      component={Grid}
      container
      rowGap={{ xs: 1, sm: 0 }}
      direction={{ xs: "column", sm: "row" }}
      justifyContent={{ xs: "center", sm: "space-between" }}
    >
      <Typography variant="h6" id="tableTitle" component={Grid} item>
        Users
      </Typography>
      <Grid item sm={10} lg={7}>
        <ToolbarFilters
          search={search}
          role={role}
          setRole={setRole}
          setSearch={setSearch}
        />
      </Grid>
    </Toolbar>
  );
};

export default UsersTableToolbar;
