import { Stack } from "@mui/material";
import theme from "src/configs/theme";
import { FC } from "react";
import ReactLoading, { LoadingProps } from "react-loading";

const Loading: FC<LoadingProps> = ({
  color = theme.palette.primary.main,
  height,
  width,
  delay,
  type = "bars",
  className,
}) => {
  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(7px)",
        zIndex: 1000000,
      }}>
      <ReactLoading
        color={color}
        height={height}
        width={width}
        delay={delay}
        type={type}
        className={className}
      />
    </Stack>
  );
};

export default Loading;
