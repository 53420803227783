import { FC, useState, Dispatch, SetStateAction } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useUsersControllerSendResetPasswordEmailMutation } from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const isDevelopment = process.env.NODE_ENV === "development";

const url = isDevelopment
  ? process.env.REACT_APP_DEVELOPMENT_URL_V1
  : process.env.REACT_APP_PRODUCTION_URL_V1;

type ForgotPasswordPropsType = {
  setForgot?: Dispatch<SetStateAction<boolean>>;
};

const ForgotPassword: FC<ForgotPasswordPropsType> = ({ setForgot }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("xs"));

  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [sendMail, { isLoading }] =
    useUsersControllerSendResetPasswordEmailMutation();

  const sendMailHandler = () => {
    if (!email) return;
    sendMail({
      mailResetPasswordDto: {
        email,
        callbackUrl: `${url}/forgotPassword/` || "",
      },
    })
      .unwrap()
      .then(() => {
        toast.success(
          "If the entered email exists in our database, we will send further instructions to it.",
        );
        setForgot && setForgot(false);
        navigate("/login");
      });
  };

  return (
    <>
      {isLoading && <Loading />}
      <Stack
        sx={{ width: "100vw", height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          elevation={isMd ? undefined : 0}
          sx={{
            width: { xs: "100%", sm: "400px" },
            height: { xs: "100%", sm: "auto" },
            p: { xs: 2, sm: 3, md: 4, lg: 5 },
            borderRadius: { xs: 0, sm: 1 },
          }}
          component={Stack}
          direction="column"
          rowGap={4}
        >
          <Box>
            <Typography
              align="center"
              variant="h6"
              color="secondary"
              sx={{ pb: 1 }}
            >
              Password Reset
            </Typography>
            <Typography fontSize={13} color="secondary" align="justify">
              Please enter you email below and we will send you an email with a
              link to reset your password.
            </Typography>
          </Box>

          <TextField
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            label="Email"
            type="email"
            placeholder="Please enter your email here"
            inputProps={{
              enterKeyHint: "send",
              autoComplete: "on",
              name: "email",
            }}
          />
          <Button
            variant="contained"
            onClick={sendMailHandler}
            disabled={!email}
          >
            Send email
          </Button>
        </Paper>
        <Typography
          sx={{
            textDecoration: "none",
            cursor: "pointer",
            mt: 2,
          }}
          color="primary"
          fontSize={14}
          fontWeight="bold"
          onClick={() => setForgot && setForgot(false)}
        >
          Back to sign in menu
        </Typography>
      </Stack>
    </>
  );
};

export default ForgotPassword;
