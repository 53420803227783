import { PersonPose, VideoPose } from "src/types/pose";
import { calculateAngles } from "./angleMeasurement";
import { smoothPoses, smoothAngles } from "./poseSmoothing";

export type PersonPoses = {
  frameIndex: number;
  personPose: Required<PersonPose>;
}[];

export type PersonsRestructured = PersonPoses[];

const restructureByPersons = (videoPose: VideoPose): PersonsRestructured => {
  const persons: PersonsRestructured = [];
  const personsId: Record<number, number> = {};

  videoPose.forEach((framePose, frameIndex) =>
    framePose.forEach((personPose) => {
      if (!personsId[personPose.id]) {
        personsId[personPose.id] = persons.length;
        persons.push([]);
      }
      persons[personsId[personPose.id]].push({
        frameIndex,
        personPose: {
          ...personPose,
          angles: personPose.angles || ({} as any),
          angles3D: personPose.angles3D || ({} as any),
        },
      });
    }),
  );

  return persons;
};

const restructureByFrames = (
  persons: PersonsRestructured,
  frameCount: number,
): VideoPose => {
  const videoPose: VideoPose = Array.from({ length: frameCount }, () => []);
  persons.forEach((personPoses) =>
    personPoses.forEach(({ frameIndex, personPose }) => {
      videoPose[frameIndex].push(personPose);
    }),
  );
  return videoPose;
};

export const processVideoPose = (videoPose: VideoPose): VideoPose => {
  const now = Date.now();
  const persons = restructureByPersons(videoPose);
  smoothPoses(persons);
  calculateAngles(persons);
  smoothAngles(persons);
  const result = restructureByFrames(persons, videoPose.length);
  console.log("process video time: ", Date.now() - now);
  return result;
};
