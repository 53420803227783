import React, { FC, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useMediaControllerGetMediaUrlQuery } from "src/app/services/generatedApi";
import { ArrowDropDown, Download } from "@mui/icons-material";
import { useAppSelector } from "src/app/hooks";

export const DownloadButtons: FC<{
  outFileName?: string;
  inFileName?: string;
  havePose?: boolean;
}> = ({ outFileName, inFileName, havePose = false }) => {
  const { data: outMediaUrl } = useMediaControllerGetMediaUrlQuery(
    { mediaName: outFileName! },
    { skip: !outFileName },
  );

  const { data: inMediaUrl } = useMediaControllerGetMediaUrlQuery(
    { mediaName: inFileName! },
    { skip: !inFileName },
  );

  const getButtonOfOption = (
    option: string,
    fullBorder = false,
    variant?: "text" | "outlined" | "contained",
  ) => {
    return (
      <Button
        sx={{ borderRadius: fullBorder ? "5px" : "5px 0 0 5px" }}
        size="small"
        disabled={
          (option === "Original Video" && !inMediaUrl) ||
          (option === "Processed Video" && !outMediaUrl)
        }
        href={option === "Original Video" ? inMediaUrl?.url : outMediaUrl?.url}
        startIcon={<Download />}
        variant={variant}
      >
        {option}
      </Button>
    );
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (_: any, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  const options = (isAdmin ? ["Original Video"] : []).concat(
    outFileName && !havePose ? ["Processed Video"] : [],
  );

  if (options.length === 0) {
    return <></>;
  }
  if (options.length === 1) {
    return getButtonOfOption(options[selectedIndex], true, "contained");
  }

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        {getButtonOfOption(options[selectedIndex])}
        <Button
          sx={{ borderRadius: "0 5px 5px 0" }}
          size="small"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
