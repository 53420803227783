import { FC, useState, ReactNode, SyntheticEvent } from "react";
import { Tabs, Tab, Box } from "@mui/material";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
    sx={{ mt: 3 }}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </Box>
);

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

type CustomTabsProps = {
  tabsArray: {
    title: string;
    tab: ReactNode;
  }[];
  onTabClick?: (index: number) => void;
};

const CustomTabs: FC<CustomTabsProps> = ({ tabsArray, onTabClick }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabClick?.(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          {tabsArray.map(({ title }, index) => (
            <Tab
              sx={{ fontWeight: "bold", fontSize: 18 }}
              label={title}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {tabsArray.map(({ tab }, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab}
        </TabPanel>
      ))}
    </Box>
  );
};

export default CustomTabs;
