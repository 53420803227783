import { Info } from "@mui/icons-material";
import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { green, grey, orange, red } from "@mui/material/colors";
import { useContext, useMemo } from "react";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import { BodyPartState, TaskContext } from "src/context/TaskContext";
import { TaskPieChart } from "../TaskPieChart";

export const getStateColor = (state: BodyPartState) => {
  switch (state) {
    case BodyPartState.SAFE:
      return green;
    case BodyPartState.HAZARD:
      return red;
    case BodyPartState.CAUTION:
      return orange;
    default:
      return grey;
  }
};

export const OverallRiskScore = () => {
  const { calcSelectedPartStateCount } = useContext(TaskContext);

  const { overallRisk, safePercentage, cautionPercentage, hazardPercentage } =
    useMemo(() => {
      let safe = 0,
        hazard = 0,
        caution = 0;

      settingKeys.forEach((key) => {
        const counts = calcSelectedPartStateCount(key);
        safe += counts[BodyPartState.SAFE];
        hazard += counts[BodyPartState.HAZARD];
        caution += counts[BodyPartState.CAUTION];
      });

      const sum = hazard + caution + safe;
      const score = Math.round(((hazard + 0.5 * caution) / sum) * 100);
      const safePercentage = Math.round((safe / sum) * 100);
      const cautionPercentage = Math.round((caution / sum) * 100);
      const hazardPercentage = Math.round((hazard / sum) * 100);

      return {
        overallRisk: score,
        safePercentage,
        cautionPercentage,
        hazardPercentage,
      };
    }, [calcSelectedPartStateCount]);

  const state =
    overallRisk > 66
      ? BodyPartState.HAZARD
      : overallRisk > 33
        ? BodyPartState.CAUTION
        : BodyPartState.SAFE;

  const stateColor = getStateColor(state);

  return (
    <Paper
      sx={{
        minWidth: 300,
        height: 95,
        p: 1,
        pl: 2,
        position: "relative",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        borderLeftColor: stateColor[300],
        overflow: "hidden",
        bgcolor: stateColor[50],
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box width={80} height={80}>
          <TaskPieChart
            wholeTaskState={[
              { name: "Safe", value: safePercentage },
              { name: "Caution", value: cautionPercentage },
              { name: "Hazard", value: hazardPercentage },
            ]}
          />
        </Box>
        <Stack alignItems="center" justifyContent="center">
          <Typography textAlign="center" fontWeight={500}>
            Postural Risk Score
          </Typography>
          <Typography textAlign="center" fontWeight={600}>
            {overallRisk + "%" || "-"}
          </Typography>
        </Stack>
        <Tooltip title="Score ranges: 0-33% (Safe), 34-66% (Caution), 67-100% (Hazard). Represents the level of risk.">
          <Info sx={{ fontSize: 20 }} color="disabled" />
        </Tooltip>
      </Stack>
    </Paper>
  );
};
