import { Stack } from "@mui/material";
import { FC } from "react";
import { useAppSelector } from "src/app/hooks";
import { TaskRo } from "src/app/services/generatedApi";
import { ReprocessButton } from "src/components/atoms/ReprocessButton";
import { ReportButton } from "src/components/molecules/Report/ReportButton";

export const AdminControls: FC<{
  task: TaskRo;
  assessmentPageIndex: number;
  openReportDialog: boolean;
  setOpenReportDialog: (open: boolean) => void;
}> = ({ task, assessmentPageIndex, openReportDialog, setOpenReportDialog }) => {
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  return (
    <Stack
      justifyContent={isAdmin ? "space-between" : "center"}
      alignItems="center"
      direction={{ sm: "row", xs: "column" }}
    >
      {isAdmin && <ReprocessButton taskId={task.id} />}
      {assessmentPageIndex === 0 && (
        <ReportButton
          task={task}
          openReportDialog={openReportDialog}
          setOpenReportDialog={setOpenReportDialog}
        />
      )}
    </Stack>
  );
};
