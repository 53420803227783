import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Button,
} from "@mui/material";
import { FC, useState } from "react";
import { AssessmentMethodsDetailType } from "./constants";

type SelectAssessmentMethodCardPropsType = {
  onClick: () => void;
  assessmentMethodDetail: AssessmentMethodsDetailType;
};

export const SelectAssessmentMethodCard: FC<
  SelectAssessmentMethodCardPropsType
> = ({ onClick, assessmentMethodDetail }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMore((prevShowMore) => !prevShowMore);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography fontWeight={600}>
            {assessmentMethodDetail.title}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {assessmentMethodDetail.subtitle}
          </Typography>
          <Typography
            mt={1}
            variant="body2"
            align="justify"
            sx={
              showMore
                ? {}
                : {
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }
            }
          >
            {assessmentMethodDetail.detail}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Button
        size="small"
        startIcon={showMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        onMouseDown={toggleShowMore}
        onClick={toggleShowMore}
        sx={{ py: 0.5, mx: 2, my: 0.5 }}
      >
        {showMore ? "Less" : "More"}
      </Button>
    </Card>
  );
};
