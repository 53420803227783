import Konva from "konva";
import { drawingOrgans, handsLines } from "src/app/logic/pose/poseConstants";

export const initHumanBodyGroup = (
  id: number,
  onChangeSelectedPersonId?: (id: number) => void
): Konva.Group => {
  const group = new Konva.Group({
    id: "" + id,
    perfectDrawEnabled: false,
    shadowForStrokeEnabled: false,
  });

  for (let i = 0; i < drawingOrgans.length; i++) {
    group.add(
      new Konva.Circle({
        fill: "white",
        perfectDrawEnabled: false,
        shadowForStrokeEnabled: false,
      })
    );
    group.add(
      new Konva.Circle({
        fill: "white",
        perfectDrawEnabled: false,
        shadowForStrokeEnabled: false,
      })
    );
    group.add(
      new Konva.Line({
        stroke: "red",
        strokeWidth: 3,
        perfectDrawEnabled: false,
        shadowForStrokeEnabled: false,
      })
    );
  }

  for (let i = 0; i < handsLines.length; i++) {
    for (let j = 0; j < handsLines[i].length; j++) {
      group.add(
        new Konva.Circle({
          fill: "white",
          perfectDrawEnabled: false,
          shadowForStrokeEnabled: false,
        })
      );
      group.add(
        new Konva.Circle({
          fill: "white",
          perfectDrawEnabled: false,
          shadowForStrokeEnabled: false,
        })
      );
      group.add(
        new Konva.Line({
          stroke: "red",
          strokeWidth: 1,
          perfectDrawEnabled: false,
          shadowForStrokeEnabled: false,
        })
      );
    }
  }
  group.on("mouseenter", () => {
    (group as any).isHovered = true;
    document.body.style.cursor = "pointer";
  });
  group.on("mouseleave", () => {
    (group as any).isHovered = false;
    document.body.style.cursor = "default";
  });
  group.on("click tap touchend", (event) => {
    onChangeSelectedPersonId?.(id);
    event.evt.preventDefault();
    event.cancelBubble = true;
  });
  return group;
};
