import { FC, MouseEvent, Dispatch } from "react";
import { TableCell, TableRow, Checkbox, TableBody, Chip } from "@mui/material";
import { stateType } from "src/pages/Tasks";
import { blue, green, grey, red } from "@mui/material/colors";
import { MinimalTaskRo } from "src/app/services/generatedApi";
import { useNavigate } from "react-router";
import moment from "moment";
import { Circle } from "@mui/icons-material";
import { BodyPartState } from "src/context/TaskContext";
import { getStateColor } from "src/components/molecules/result/overallRiskScore/OverallRiskScore";

type TasksTableBodyPropsType = {
  rows: MinimalTaskRo[];
  selected: string[];
  setSelected: Dispatch<React.SetStateAction<string[]>>;
};

const TasksTableBody: FC<TasksTableBodyPropsType> = ({
  rows,
  selected,
  setSelected,
}) => {
  const navigate = useNavigate();

  const checkboxClickHandler = (e: MouseEvent<unknown>, row: MinimalTaskRo) => {
    e.stopPropagation();
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const rowClickHandler = (task: MinimalTaskRo) => {
    if (task.state !== stateType.READY) return;
    navigate("/result/" + task.id);
  };

  const statusColorHandler = (state: stateType) => {
    let color: string = grey[500];
    switch (state) {
      case stateType.READY:
        color = green[500];
        break;
      case stateType.PROCESSING:
        color = blue[500];
        break;
      case stateType.ERROR:
        color = red[500];
        break;
      default:
        color = grey[500];
        break;
    }
    return color;
  };

  return (
    <TableBody>
      {(rows as any).map((row: MinimalTaskRo, index: number) => {
        const { id, name, description, riskScore, createdAt, state, tag } = row;
        const isItemSelected = selected.includes(id);
        const labelId = `enhanced-table-checkbox-${id}`;

        const riskState =
          riskScore > 66
            ? BodyPartState.HAZARD
            : riskScore > 33
              ? BodyPartState.CAUTION
              : BodyPartState.SAFE;

        const stateColor = getStateColor(riskState);

        return (
          <TableRow
            hover
            onClick={() => rowClickHandler(row)}
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
            sx={{
              cursor: row.state === stateType.READY ? "pointer" : "default",
            }}
          >
            <TableCell
              padding="checkbox"
              onClick={(e) => checkboxClickHandler(e, row)}
            >
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </TableCell>
            <TableCell
              align="left"
              component="th"
              id={labelId}
              scope="row"
              padding="none"
            >
              {name}
            </TableCell>
            <TableCell align="center">
              {riskScore >= 0 ? (
                <Chip
                  icon={<Circle sx={{ fill: stateColor[300] }} />}
                  label={riskScore + "%"}
                  size="small"
                />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell align="center">{tag?.name || "-"}</TableCell>
            <TableCell align="center">
              {moment(createdAt).format("YYYY/MM/DD")}
            </TableCell>
            <TableCell align="left">{description || "-"}</TableCell>
            <TableCell
              align="center"
              sx={{
                color: statusColorHandler(state as stateType),
              }}
            >
              {stateType[state]}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TasksTableBody;
