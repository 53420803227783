import { FC, useContext, useEffect } from "react";
import { useSyncByVideo } from "src/components/molecules/result/charts/useSyncByVideo";
import { AssessmentContext } from "src/context/AssessmentContext";
import { TaskContext } from "src/context/TaskContext";
import { RebaRulaPage } from "../../assessment/reba-rula/RebaRulaPage";
import { MMHPage } from "../../assessment/mmh/MMHPage";

export const AssessmentPage: FC<{
  fileName: string;
  havePose: boolean;
}> = ({ fileName, havePose }) => {
  const { onChange: setResultVideoSeconds } = useSyncByVideo(true);
  const { fps, changeSelectedPersonIdIfNeeded } = useContext(TaskContext);
  const { frameIndex, selectedMethod, targetId } =
    useContext(AssessmentContext);

  useEffect(() => {
    setResultVideoSeconds((frameIndex || 0) / fps);
  }, [fps, frameIndex, setResultVideoSeconds]);

  useEffect(() => {
    if (targetId !== undefined) {
      changeSelectedPersonIdIfNeeded(targetId, frameIndex || 0);
    }
  }, [changeSelectedPersonIdIfNeeded, frameIndex, targetId]);

  return selectedMethod === "REBA" || selectedMethod === "RULA" ? (
    <RebaRulaPage fileName={fileName} havePose={havePose} />
  ) : (
    <MMHPage />
  );
};
