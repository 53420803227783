import { FC, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { stateToColor } from "src/components/molecules/result/humanBodySVG/constant";

const COLORS = [stateToColor.SAFE, stateToColor.CAUTION, stateToColor.HAZARD];

export const TaskPieChart: FC<{
  wholeTaskState: { name: string; value: number }[];
}> = ({ wholeTaskState }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const onMouseEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(undefined);
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <circle cx="50%" cy="50%" fill="white" r={40} />
        <Pie
          data={wholeTaskState}
          cx="50%"
          cy="50%"
          innerRadius={20}
          outerRadius={35}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          labelLine={false}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          activeIndex={activeIndex}
        >
          {wholeTaskState.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {activeIndex !== undefined && (
          <>
            <text
              x={40}
              y={"50%"}
              width={20}
              textAnchor="middle"
              dominantBaseline="middle"
              fill={COLORS[activeIndex]}
              style={{
                fontSize: "16px",
                fontWeight: 900,
                pointerEvents: "none",
                textAlign: "center",
              }}
            >
              {`${wholeTaskState[activeIndex].value.toFixed(0)}%`}
            </text>
          </>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};
