import {
  Box,
  Typography,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";
import { FC } from "react";

type CircularProgressWithLabelProps = CircularProgressProps & { value: number };

export const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = (
  props,
) => (
  <Box sx={{ position: "relative", display: "inline-flex" }}>
    <CircularProgress
      variant={props.value ? "determinate" : "indeterminate"}
      {...props}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.value > 0 && (
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      )}
    </Box>
  </Box>
);
