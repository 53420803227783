import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from "react";

export type EventHandler = (data: any) => void;

type EventEmitterContextType = {
  emit: (event: string, data: any) => void;
  on: (event: string | string[], callback: EventHandler) => void;
  off: (event: string | string[], callback: EventHandler) => void;
};

const EventEmitterContext = createContext<EventEmitterContextType>({
  emit: () => {},
  on: () => {},
  off: () => {},
});

export const EventEmitterProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const listeners = useRef<Record<string, EventHandler[]>>({});

  const emit = useCallback((event: string, data: any) => {
    listeners.current[event]?.forEach((listener) => {
      setTimeout(() => listener(data), 0);
    });
  }, []);

  const on = useCallback((event: string | string[], callback: EventHandler) => {
    const events = Array.isArray(event) ? event : [event];
    events.forEach((event) => {
      listeners.current[event] = listeners.current[event] || [];
      listeners.current[event].push(callback);
    });
  }, []);

  const off = useCallback(
    (event: string | string[], callback: EventHandler) => {
      const events = Array.isArray(event) ? event : [event];
      events.forEach((event) => {
        listeners.current[event] =
          listeners.current[event]?.filter(
            (listener) => listener !== callback,
          ) || [];
      });
    },
    [],
  );

  return (
    <EventEmitterContext.Provider value={{ emit, on, off }}>
      {children}
    </EventEmitterContext.Provider>
  );
};

export const useEventEmitter = (): EventEmitterContextType =>
  useContext(EventEmitterContext);
