import { FC } from "react";
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  PaperProps,
  Stack,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import Draggable from "react-draggable";
import { Close } from "@mui/icons-material";
import { TaskRo } from "src/app/services/generatedApi";
import { NotesContent } from "./NotesContent";

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"], [class*="MuiButtonBase-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export const NotesDialog: FC<{
  open: boolean;
  onClose: () => void;
  task: TaskRo;
}> = ({ open, onClose, task }) => {
  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      disableScrollLock={true}
      PaperProps={{
        sx: {
          position: "absolute",
          bottom: { xs: "unset", sm: 75 },
          right: { xs: "unset", sm: 16 },
          margin: 0,
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notes</Typography>
          <Stack
            sx={{ flexGrow: 1, height: 40, cursor: "move" }}
            direction="row"
            alignItems="center"
            justifyContent="center"
            id="draggable-dialog-title"
          >
            <Box
              sx={{ width: 30, height: 5, background: "#ccc", borderRadius: 3 }}
            />
          </Stack>
          <IconButton>
            <Close onClick={onClose} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <NotesContent onClose={onClose} task={task} editable />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
