import { AxiosRequestConfig } from "axios";
import {
  MediaControllerUploadVideoApiArg,
  MediaControllerUploadVideoApiResponse,
  TasksRo,
  enhancedApi,
} from "./generatedApi";

const addTagTypes = ["Task", "Category", "Tag", "User", "Assessment"];

export const api = enhancedApi
  .injectEndpoints({
    endpoints: (build) => ({
      customMediaControllerUploadVideo: build.mutation<
        MediaControllerUploadVideoApiResponse,
        MediaControllerUploadVideoApiArg & {
          abortController: AbortController;
          onUploadProgress: AxiosRequestConfig["onUploadProgress"];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => ({
          url: `/api/v1/media`,
          method: "POST",
          body,
          abortController,
          onUploadProgress,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes,
    endpoints: {
      // ======================= TASKS ======================= //
      tasksControllerUpdateUserTask: {
        invalidatesTags: (result, error, { taskUpdateDto }) => [
          { type: "Task", id: taskUpdateDto.id },
        ],
      },
      tasksControllerDeleteMultipleUserTasks: {
        invalidatesTags: () => ["Task"],
      },
      customMediaControllerUploadVideo: {
        invalidatesTags: () => ["Task", "Tag"],
      },
      tasksControllerGetTaskByIdForUser: {
        providesTags: (result, error, { id }) => [{ type: "Task", id }],
      },
      tasksControllerGetAdminTasksWithPagination: {
        providesTags: (result?: TasksRo) =>
          result
            ? [
                ...result.tasks.map(({ id }) => ({
                  type: "Task" as const,
                  id,
                })),
                "Task",
              ]
            : ["Task"],
      },
      tasksControllerUpdateObservationAndRecommendation: {
        invalidatesTags: (
          result,
          error,
          { updateObservationRecommendationDto },
        ) => [{ type: "Task", id: updateObservationRecommendationDto.id }],
      },
      // ======================= CATEGORY ======================= //
      categoryControllerCreateSystemCategory: {
        invalidatesTags: () => ["Category"],
      },
      categoryControllerCreateCategory: {
        invalidatesTags: () => ["Category"],
      },
      categoryControllerDeleteCategory: {
        invalidatesTags: (result, error, { idDto }) => [
          { type: "Category", id: idDto.id },
        ],
      },
      categoryControllerUpdateCategory: {
        invalidatesTags: () => ["Category"],
      },
      categoryControllerGetAllCategories: {
        providesTags: (result) =>
          result
            ? [
                ...result.categories.map(({ id }) => ({
                  type: "Category" as const,
                  id,
                })),
                "Category",
              ]
            : ["Category"],
      },
      // ======================= TAGS ======================= //
      tasksControllerGetUserTagsForCurrentUser: {
        providesTags: (result) =>
          result
            ? [
                ...result.tags.map(({ name }) => ({
                  type: "Tag" as const,
                  name,
                })),
                "Tag",
              ]
            : ["Tag"],
      },
      // ======================= USERS ======================= //
      usersControllerCreateNewUser: {
        invalidatesTags: () => ["User"],
      },
      usersControllerUpdateExistingUser: {
        invalidatesTags: (result, error, { userUpdateDto }) => [
          { type: "User", id: userUpdateDto.username },
        ],
      },
      usersControllerSuspendUserByUsername: {
        invalidatesTags: (result, error, { username }) => [
          { type: "User", id: username },
        ],
      },
      usersControllerActivateUserByUsername: {
        invalidatesTags: (result, error, { username }) => [
          { type: "User", id: username },
        ],
      },
      usersControllerSetUserUsageLimit: {
        invalidatesTags: (result, error, { userLimitDto }) => [
          { type: "User", id: userLimitDto.username },
        ],
      },
      usersControllerSearchUsers: {
        providesTags: (result) =>
          result
            ? [
                ...result.users.map(({ username }) => ({
                  type: "User" as const,
                  id: username,
                })),
                "User",
              ]
            : ["User"],
      },
      usersControllerAssignUserRole: {
        invalidatesTags: () => ["User"],
      },
      // ======================= LOGOUT ======================= //
      authControllerLogin: {
        invalidatesTags: () => [
          "Task",
          "Category",
          "Tag",
          "User",
          "Assessment",
        ],
      },
      // ===================== ASSESSMENT ===================== //
      assessmentsControllerGetAssessments: {
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Assessment" as const,
                  id,
                })),
                "Assessment",
              ]
            : ["Assessment"],
      },
      assessmentsControllerCreateAssessment: {
        invalidatesTags: () => ["Assessment"],
      },
      assessmentsControllerUpdateAssessment: {
        invalidatesTags: () => ["Assessment"],
      },
      assessmentsControllerDeleteAssessment: {
        invalidatesTags: () => ["Assessment"],
      },
    },
  });

export const { useCustomMediaControllerUploadVideoMutation } = api;
