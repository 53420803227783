import {
  LiftLowerTaskDataDto,
  PushPullCarryTaskDataDto,
} from "src/app/services/generatedApi";
import {
  Gender,
  TaskData,
  CalcTaskModes,
  capableZScore,
  cvValues,
  couplingFactor,
} from "./constants";
import { formulas } from "./formulas";
import { MMHResultType } from "src/components/organisms/assessment/mmh/constant";

function erf(x: number): number {
  const a1 = 0.254829592;
  const a2 = -0.284496736;
  const a3 = 1.421413741;
  const a4 = -1.453152027;
  const a5 = 1.061405429;
  const p = 0.3275911;
  const sign = x < 0 ? -1 : 1;
  x = Math.abs(x);
  const t = 1.0 / (1.0 + p * x);
  const y =
    1.0 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
  return sign * y;
}

function cdf(x: number, mean: number, std: number): number {
  const z = (x - mean) / (std * Math.sqrt(2));
  return 0.5 * (1 + erf(z));
}

const calculateMAL = (taskData: TaskData, gender: Gender): number => {
  const { mode, data } = taskData;
  const calculateFunc = formulas[gender][mode];

  if (mode === CalcTaskModes.LIFT || mode === CalcTaskModes.LOWER) {
    const liftLowerData = data as LiftLowerTaskDataDto;
    const H =
      (+liftLowerData.startHandDistance + +liftLowerData.endHandDistance) / 2;
    const VRM =
      (+liftLowerData.startHandHeight + +liftLowerData.endHandHeight) / 2;
    const DV = Math.abs(
      +liftLowerData.endHandHeight - +liftLowerData.startHandHeight,
    );
    const F = +liftLowerData.frequency;
    return calculateFunc(H, VRM, DV, F) * couplingFactor[data.handCoupling];
  }

  if (
    mode === CalcTaskModes.PUSH_INITIAL ||
    mode === CalcTaskModes.PUSH_SUSTAINED ||
    mode === CalcTaskModes.PULL_INITIAL ||
    mode === CalcTaskModes.PULL_SUSTAINED ||
    mode === CalcTaskModes.CARRY
  ) {
    const pushPullData = data as PushPullCarryTaskDataDto;
    const V = pushPullData.verticalHandHeight;
    const DH = pushPullData.horizontalDistance;
    const F = pushPullData.frequency;
    return calculateFunc(V, DH, F);
  }

  throw new Error("Invalid task mode.");
};

const calculateMAL_xPercentile = (
  MAL: number,
  gender: Gender,
  mode: CalcTaskModes,
  percentile: number,
): number => {
  const Z = capableZScore[percentile];
  const CV = cvValues[gender][mode];
  return MAL * (1 - CV * Z);
};

const calculatePercentileCapable = (
  MAL: number,
  gender: Gender,
  actualLoad: number,
  mode: CalcTaskModes,
  percentile: number,
): number => {
  const MAL_xPercentile = calculateMAL_xPercentile(
    MAL,
    gender,
    mode,
    percentile,
  );
  const CV = cvValues[gender][mode];
  const SD = CV * MAL;
  const probability = 1 - cdf(actualLoad, MAL_xPercentile, SD);
  return probability;
};

export const getResultData = (
  taskData: TaskData,
  gender: Gender,
  actualLoad: number,
): MMHResultType["data"][Gender.MALE] => {
  const MAL = calculateMAL(taskData, gender);
  const acceptancePercentage = Math.round(
    calculatePercentileCapable(MAL, gender, actualLoad, taskData.mode, 50) *
      100,
  );
  const percentilesMAL10 =
    Math.round(calculateMAL_xPercentile(MAL, gender, taskData.mode, 10) * 100) /
    100;
  const percentilesMAL25 =
    Math.round(calculateMAL_xPercentile(MAL, gender, taskData.mode, 25) * 100) /
    100;
  const percentilesMAL50 =
    Math.round(calculateMAL_xPercentile(MAL, gender, taskData.mode, 50) * 100) /
    100;
  const percentilesMAL75 =
    Math.round(calculateMAL_xPercentile(MAL, gender, taskData.mode, 75) * 100) /
    100;
  const percentilesMAL90 =
    Math.round(calculateMAL_xPercentile(MAL, gender, taskData.mode, 90) * 100) /
    100;

  return {
    acceptancePercentage,
    percentilesMAL10,
    percentilesMAL25,
    percentilesMAL50,
    percentilesMAL75,
    percentilesMAL90,
  };
};
