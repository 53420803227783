import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuList,
  Box,
  MenuItem,
  Divider,
} from "@mui/material";
import { FC, useState } from "react";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import { SettingDto } from "src/app/services/generatedApi";
import { settingsNamesEnum } from "src/components/organisms/category/CategorySettings";

type SelectBodyPartPropsType = {
  setSelectedPart: (part: keyof SettingDto) => void;
};

export const SelectBodyPart: FC<SelectBodyPartPropsType> = ({
  setSelectedPart,
}) => {
  const [openBodyPartDialog, setOpenBodyPartDialog] = useState(false);
  return (
    <>
      <Button onClick={() => setOpenBodyPartDialog(true)} sx={{ mr: "auto" }}>
        Change selected body part
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openBodyPartDialog}
        onClose={() => setOpenBodyPartDialog(false)}
        disableScrollLock={true}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Select a body part
        </DialogTitle>
        <DialogContent>
          <MenuList>
            {settingKeys.map((item, index) => (
              <Box key={index}>
                <MenuItem
                  value={item}
                  sx={{ textAlign: "center" }}
                  onClick={() => {
                    setSelectedPart(item as keyof SettingDto);
                    setOpenBodyPartDialog(false);
                  }}
                >
                  {settingsNamesEnum[item as keyof SettingDto]}
                </MenuItem>
                {index < settingKeys.length - 1 && (
                  <Divider orientation="horizontal" />
                )}
              </Box>
            ))}
          </MenuList>
        </DialogContent>
      </Dialog>
    </>
  );
};
