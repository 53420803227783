import { FC, MouseEvent, Dispatch } from "react";
import {
  TableCell,
  TableRow,
  Checkbox,
  TableBody,
  IconButton,
} from "@mui/material";
import { stateType, tableOrderType } from "src/pages/Tasks";
import { blue, green, grey, red } from "@mui/material/colors";
import {
  MinimalTaskRo,
  useTasksControllerChangeTaskStateAsAdminMutation,
} from "src/app/services/generatedApi";
import { useNavigate } from "react-router";
import moment from "moment";
import { useAppSelector } from "src/app/hooks";
import { Refresh } from "@mui/icons-material";
import { toast } from "react-toastify";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type TasksTableBodyPropsType = {
  rows: MinimalTaskRo[];
  selected: string[];
  setSelected: Dispatch<React.SetStateAction<string[]>>;
  order: tableOrderType;
  orderBy: keyof MinimalTaskRo;
};

const TasksTableBody: FC<TasksTableBodyPropsType> = ({
  rows,
  selected,
  setSelected,
  order,
  orderBy,
}) => {
  const navigate = useNavigate();
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);
  const [changeTaskState] = useTasksControllerChangeTaskStateAsAdminMutation();

  const checkboxClickHandler = (e: MouseEvent<unknown>, row: MinimalTaskRo) => {
    e.stopPropagation();
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const rowClickHandler = (task: MinimalTaskRo) => {
    if (task.state !== stateType.READY) return;
    navigate("/result/" + task.id);
  };

  function getComparator<Key extends keyof any>(
    order: tableOrderType,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const statusColorHandler = (state: stateType) => {
    let color: string = grey[500];
    switch (state) {
      case stateType.READY:
        color = green[500];
        break;
      case stateType.PROCESSING:
        color = blue[500];
        break;
      case stateType.ERROR:
        color = red[500];
        break;
      default:
        color = grey[500];
        break;
    }
    return color;
  };

  const refresh = (taskId: string) => {
    changeTaskState({ changeTaskStateDto: { id: taskId, state: "PENDING" } })
      .unwrap()
      .then(() => toast.success("Send reprocessing request."));
  };

  return (
    <TableBody>
      {(rows as any)
        .slice()
        .sort(getComparator(order, orderBy))
        .map((row: MinimalTaskRo, index: number) => {
          const {
            id,
            name,
            description,
            createdAt,
            state,
            creator,
            isDeleted,
            riskScore,
          } = row;
          const isItemSelected = selected.includes(id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={() => rowClickHandler(row)}
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
              sx={{
                cursor: row.state === stateType.READY ? "pointer" : "default",
              }}
            >
              <TableCell
                padding="checkbox"
                onClick={(e) => checkboxClickHandler(e, row)}
              >
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </TableCell>
              <TableCell
                align="left"
                component="th"
                id={labelId}
                scope="row"
                padding="none"
              >
                {name}
              </TableCell>
              <TableCell align="center">{creator}</TableCell>
              <TableCell align="center" scope="row" padding="none">
                {riskScore >= 0 ? riskScore : "-"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: isDeleted ? red[500] : undefined,
                  fontWeight: "bold",
                }}
              >
                {isDeleted ? "Yes" : "No"}
              </TableCell>
              <TableCell align="center">
                {moment(createdAt).format("YYYY/MM/DD")}
              </TableCell>
              <TableCell align="left">{description || "-"}</TableCell>
              <TableCell
                align="center"
                sx={{
                  color: statusColorHandler(state as stateType),
                }}
              >
                {stateType[state]}
                {isAdmin &&
                  stateType[state] !== stateType.PENDING &&
                  stateType[state] !== stateType.PROCESSING && (
                    <IconButton onClick={() => refresh(id)}>
                      <Refresh />
                    </IconButton>
                  )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default TasksTableBody;
