import { FC } from "react";
import AspectRatioBox from "src/components/atoms/AspectRatioBox";

export const Human3d: FC<{ maxHeight: number }> = ({ maxHeight }) => {
  return (
    <AspectRatioBox maxWidth={maxHeight}>
      <iframe
        id="3d-view-iframe"
        title="3d view"
        src="/3d-view/index.html"
        frameBorder="none"
      />
    </AspectRatioBox>
  );
};
