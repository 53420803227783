import { FC } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import notFoundSvg from "src/assets/images/404.svg";

const NotFound: FC = () => {
  const navigate = useNavigate();

  const homeClickHandler = () => navigate("/");
  const returnClickHandler = () => navigate(-1);

  return (
    <Box
      component={Stack}
      sx={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: "fixed",
        width: "100vw",
        height: "100vh",
      }}
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ width: "100%", maxWidth: "1000px" }}>
        <Stack direction="column" rowGap={5}>
          <Box>
            <Typography fontWeight="bold" fontSize={30} align="center">
              Page not found
            </Typography>
            <Typography fontSize={20} align="center">
              It looks like the page that you are looking for does not exist
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnGap={3}
          >
            <Button
              size="large"
              sx={{
                minWidth: "150px",
              }}
              onClick={returnClickHandler}
              variant="contained"
            >
              <Typography variant="button">Return</Typography>
            </Button>
            <Button
              size="large"
              sx={{
                minWidth: "150px",
              }}
              onClick={homeClickHandler}
              variant="contained"
            >
              <Typography variant="button">Home</Typography>
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          <img src={notFoundSvg} alt="page not found" />
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
