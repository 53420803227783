import { Box, Button, Stack } from "@mui/material";
import { FC } from "react";
import { TaskRo } from "src/app/services/generatedApi";
import { ReportButton } from "src/components/molecules/Report/ReportButton";
import { DownloadButtons } from "src/components/molecules/result/DownloadButtons";
import { Assessment, PictureAsPdf } from "@mui/icons-material";

export const DownloadButtonsSection: FC<{
  task: TaskRo;
  assessmentPageIndex: number;
  openReportDialog: boolean;
  setOpenReportDialog: (open: boolean) => void;
  downloadCsvReport: () => void;
}> = ({
  task,
  assessmentPageIndex,
  openReportDialog,
  setOpenReportDialog,
  downloadCsvReport,
}) => (
  <Box mt={2}>
    <Stack direction="row" spacing={2}>
      <DownloadButtons
        inFileName={task.inFileName}
        outFileName={task.outFileName}
        havePose={task.havePose}
      />
      {assessmentPageIndex === 0 && (
        <ReportButton
          task={task}
          openReportDialog={openReportDialog}
          setOpenReportDialog={setOpenReportDialog}
        />
      )}
      {!task.havePose && (
        <Button
          variant="outlined"
          startIcon={<Assessment />}
          size="small"
          onClick={downloadCsvReport}
        >
          CSV Data
        </Button>
      )}
      <Button
        variant="outlined"
        startIcon={<PictureAsPdf />}
        size="small"
        onClick={() => setOpenReportDialog(true)}
      >
        PDF Report
      </Button>
    </Stack>
  </Box>
);
