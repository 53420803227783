import {
  Box,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { UnitModes, UnitValues, unitsItems } from "./constants";
import { useAppSelector } from "src/app/hooks";

export type MMHFormFieldPropsType = {
  preUnitText?: string;
  label: string;
  textFieldProps?: TextFieldProps;
  icon: ReactNode;
  unitMode: UnitModes;
  min?: number;
  max?: number;
  minF?: number;
  maxF?: number;
  minM?: number;
  maxM?: number;
  selectedUnitItems?: UnitValues[];
};

export const MMHFormField: FC<
  MMHFormFieldPropsType & {
    value?: number;
    onChange: (value: number) => void;
    setErrorState: (errorState: { men: boolean; women: boolean }) => void;
  }
> = ({
  preUnitText,
  label,
  value,
  textFieldProps,
  icon,
  unitMode,
  min,
  max,
  minF,
  maxF,
  minM,
  maxM,
  selectedUnitItems,
  onChange,
  setErrorState,
}) => {
  const system = useAppSelector((store) => store.assessments.system);
  const [unitIndex, setUnitIndex] = useState<number>(0);
  const [tempValue, setTempValue] = useState<string>("");
  const [error, setError] = useState<string[]>([]);
  const prevValueRef = useRef<number | undefined>();

  const currentSystemUnitItems = useMemo(
    () =>
      unitsItems[unitMode].filter(
        (unitItem) =>
          (!unitItem.system || unitItem.system === system) &&
          (!selectedUnitItems?.length ||
            selectedUnitItems.includes(unitItem.value)),
      ),
    [selectedUnitItems, system, unitMode],
  );

  const currentUnit = currentSystemUnitItems[unitIndex];
  const toStdFactor = currentUnit.toStdFactor || 1;

  useEffect(() => {
    setUnitIndex(0);
  }, [system]);

  useEffect(() => {
    if (value !== prevValueRef.current) {
      updateDisplayValue();
    }
  }, [value]);

  useEffect(() => {
    updateDisplayValue();
  }, [unitIndex]);

  useEffect(() => {
    if (textRef.current?.value) {
      handleNumberChange(textRef.current.value);
    }
  }, [system, unitIndex]);

  const textRef = useRef<HTMLInputElement>();

  const computeDisplayValue = (
    numericValue: number,
    degree: number = 3,
    fixed: boolean = false,
  ): string => {
    const newValue =
      Math.round((numericValue / toStdFactor) * 10 ** degree) / 10 ** degree;

    return fixed && newValue !== Math.round(newValue)
      ? newValue.toFixed(degree)
      : String(newValue);
  };

  const updateDisplayValue = () => {
    if (value !== undefined && !Number.isNaN(value)) {
      setTempValue(computeDisplayValue(value));
      prevValueRef.current = value;
    }
  };

  const handleNumberChange = (rawValue: string): void => {
    const cleanedValue = rawValue.replace(/^0+/, "");
    const intermediateValue =
      rawValue === "0" || rawValue.startsWith("0.") ? rawValue : cleanedValue;
    if (!/^-?\d*(\.\d{0,4})?$/.test(intermediateValue)) return;

    const newNumericValue = parseFloat(intermediateValue) * toStdFactor;
    setTempValue(intermediateValue);
    validateAndSetError(newNumericValue);

    if (newNumericValue !== prevValueRef.current) {
      onChange(newNumericValue);
      prevValueRef.current = newNumericValue;
    }
  };

  const validateAndSetError = (value: number): void => {
    const errorMessages: string[] = [];
    const ranges = [
      { min, max, labelSuffix: "" },
      { min: minM, max: maxM, labelSuffix: " for males" },
      { min: minF, max: maxF, labelSuffix: " for females" },
    ];

    let menError = false,
      womenError = false;

    ranges.forEach(({ min, max, labelSuffix }) => {
      if (min !== undefined && value < min) {
        errorMessages.push(
          `The minimum allowable ${label} ${labelSuffix} is ${computeDisplayValue(
            min,
            2,
            true,
          )}${currentUnit.symbol}`,
        );
        if (labelSuffix.includes("males")) menError = true;
        if (labelSuffix.includes("females")) womenError = true;
      }
      if (max !== undefined && value > max) {
        errorMessages.push(
          `The maximum allowable ${label} ${labelSuffix} is ${computeDisplayValue(
            max,
            2,
            true,
          )}${currentUnit.symbol}`,
        );
        if (labelSuffix.includes("males")) menError = true;
        if (labelSuffix.includes("females")) womenError = true;
      }
    });

    setErrorState({ men: menError, women: womenError });
    setError(errorMessages);
  };

  return (
    <Stack
      direction="row"
      width="100%"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <TextField
        inputRef={textRef}
        variant="filled"
        sx={{ width: "100%", minWidth: "50%", maxWidth: 500 }}
        label={label}
        type="number"
        value={tempValue}
        onChange={(e) => handleNumberChange(e.target.value)}
        InputProps={{
          ...(icon && {
            endAdornment: (
              <InputAdornment position="end">{icon}</InputAdornment>
            ),
          }),
          onWheel: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
            setTimeout(() => {
              e.target.focus();
            }, 0);
          },
        }}
        {...textFieldProps}
        autoFocus={false}
        error={!!error?.length}
        helperText={error?.map((err) => <Box key={err}>{err}</Box>)}
      />

      <TextField
        select
        value={unitIndex}
        sx={{
          width: "100%",
          minWidth: "20%",
          maxWidth: 200,
          alignSelf: "start",
        }}
        onChange={(e) => setUnitIndex(Number(e.target.value))}
        disabled={currentSystemUnitItems.length <= 1}
      >
        {currentSystemUnitItems.map((item, index) => (
          <MenuItem key={index} value={index}>
            {(preUnitText ? preUnitText + " " : "") + item.label}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};
