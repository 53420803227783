import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";

const CustomLegend: FC = () => {
  return (
    <Stack direction="row-reverse" alignItems="center" columnGap={2}>
      <Stack direction="row" alignItems="center" columnGap={0.5}>
        <Box
          sx={{
            width: "10px",
            height: "10px",
            backgroundColor: green[500],
            borderRadius: "50px",
          }}
        ></Box>
        <Typography fontSize={14} color={green[500]}>
          Safe
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" columnGap={0.5}>
        <Box
          sx={{
            width: "10px",
            height: "10px",
            backgroundColor: yellow[600],
            borderRadius: "50px",
          }}
        ></Box>
        <Typography fontSize={14} color={yellow[600]}>
          Caution
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" columnGap={0.5}>
        <Box
          sx={{
            width: "10px",
            height: "10px",
            backgroundColor: red[500],
            borderRadius: "50px",
          }}
        ></Box>
        <Typography fontSize={14} color={red[500]}>
          Hazard
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CustomLegend;
