import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

const AspectRatioBox: FC<{
  children: ReactNode;
  ratio?: number;
  maxWidth?: number;
}> = ({ children, ratio = 1, maxWidth }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          maxWidth,
          maxHeight: maxWidth,
          "& > *": { height: "100%", width: "100%" },
        }}
      >
        {children}
      </Box>
      <Box style={{ paddingBottom: (1 / ratio) * 100 + "%" }} />
    </Box>
  );
};

export default AspectRatioBox;
