import { FC } from "react";
import { useAppSelector } from "src/app/hooks";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute: FC = () => {
  const { auth } = useAppSelector((state) => state);

  return auth?.token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
