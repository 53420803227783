import { FC } from "react";
import Router from "src/routes/Router";
import { CacheProvider } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import createCache from "@emotion/cache";
import { ThemeProvider } from "@mui/material/styles";
import theme from "src/configs/theme";
import "react-toastify/dist/ReactToastify.css";
import "src/styles/globals.css";
import { CssBaseline } from "@mui/material";

console.log("APP_VERSION: ", "1.1.22");

const App: FC = () => {
  return (
    <>
      <CssBaseline />
      <CacheProvider value={createCache({ key: "css" })}>
        <ThemeProvider theme={theme}>
          <ToastContainer limit={3} />
          <Router />
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default App;
