import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const PullIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M22.2491 10.8503H14.2965C14.4925 11.001 14.617 11.2267 14.6396 11.473C14.6623 11.7194 14.5812 11.9639 14.4159 12.1478C14.2505 12.3319 14.016 12.4385 13.7686 12.4422V18.2707C13.7686 18.3644 13.8059 18.4543 13.8721 18.5205C13.9384 18.5868 14.0283 18.624 14.122 18.624H22.2491C22.3428 18.624 22.4327 18.5868 22.499 18.5205C22.5652 18.4543 22.6024 18.3644 22.6024 18.2707V11.2036C22.6024 11.1099 22.5652 11.02 22.499 10.9537C22.4327 10.8875 22.3428 10.8503 22.2491 10.8503V10.8503ZM16.5955 14.1189V11.2921H19.7756V14.1189H16.5955Z" />
      <path d="M4.03471 9.94213C4.14639 9.92478 4.25666 9.89906 4.36456 9.86547C4.18094 10.1158 4.11611 10.4341 4.18741 10.7364C4.25871 11.0386 4.45905 11.2945 4.73525 11.4363C5.01144 11.5781 5.33611 11.5917 5.62319 11.4735C6.26411 11.203 6.99133 11.2265 7.61347 11.538C8.23545 11.8496 8.68992 12.4179 8.85715 13.0931C8.88018 13.2321 8.9794 13.336 9.01048 13.4599C9.1881 14.3746 8.97924 15.322 8.43344 16.0773C7.9965 16.6438 7.3849 17.0505 6.69366 17.2347C6.44978 17.3002 6.25938 17.4907 6.19408 17.7346C6.12893 17.9785 6.19866 18.2388 6.37738 18.4172C6.55595 18.5956 6.81623 18.6651 7.0601 18.5997C8.04932 18.3331 8.92196 17.7453 9.54099 16.9288C10.16 16.1123 10.4902 15.1133 10.4797 14.0888C10.9375 14.2465 11.3141 14.5793 11.527 15.0141C11.8572 15.7885 11.5122 16.7675 10.7068 17.342C10.5014 17.4885 10.3895 17.7337 10.4138 17.985C10.438 18.2363 10.5945 18.4555 10.8241 18.5601C11.0538 18.6648 11.3218 18.639 11.5274 18.4924C13.8652 16.8074 13.3968 13.5742 10.9388 12.7512V12.7513C10.901 12.4778 10.82 12.2119 10.6994 11.9635C11.7286 12.141 12.7769 12.1819 13.8169 12.0847C14.1084 12.058 14.3231 11.8001 14.2964 11.5086C14.2698 11.2172 14.0119 11.0025 13.7203 11.0292C12.4511 11.1461 11.1712 11.0417 9.93773 10.7205C8.88006 9.32048 6.65471 8.84772 5.25491 9.35848V9.35833C5.73353 8.90955 5.99788 8.2776 5.98165 7.62153C5.9654 6.96547 5.66994 6.34757 5.16958 5.92294C4.66923 5.49831 4.01156 5.30742 3.36151 5.39812C0.369533 5.94741 1.01251 10.2841 4.03462 9.94202L4.03471 9.94213Z" />
      <path d="M10.8538 8.52993L13.07 9.80959V9.80974C13.1246 9.84129 13.1919 9.84129 13.2467 9.80974C13.3012 9.7782 13.335 9.71983 13.335 9.65673V8.90698H17.3023C17.5951 8.90698 17.8323 8.66973 17.8323 8.37695C17.8323 8.08433 17.5951 7.84692 17.3023 7.84692H13.335V7.09733C13.335 7.03423 13.3012 6.97587 13.2467 6.94432C13.1919 6.91277 13.1246 6.91277 13.07 6.94432L10.8538 8.22397C10.7991 8.25552 10.7655 8.31389 10.7655 8.37699C10.7655 8.44009 10.7991 8.49845 10.8538 8.53001V8.52993Z" />
    </SvgIcon>
  );
};
