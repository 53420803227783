import { FC, useState } from "react";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import SignIn from "src/components/organisms/login/SignIn";
import { useTheme } from "@mui/system";
import Tabs from "src/components/molecules/Tabs";
import { useAppSelector } from "src/app/hooks";
import { Navigate } from "react-router";
import ForgotPassword from "src/components/organisms/login/SendMail";

const Login: FC = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { auth } = useAppSelector((state) => state);

  const tabsArray = [{ title: "Sign in", tab: <SignIn /> }];

  if (auth?.token) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        backgroundColor: isXs ? "#fff" : undefined,
        width: "100vw",
        height: "100vh",
        overflow: "auto",
      }}
    >
      {forgotPassword ? (
        <ForgotPassword setForgot={setForgotPassword} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          {!isXs ? (
            <Paper
              sx={{
                maxWidth: "450px",
                overflow: "auto",
              }}
            >
              <Tabs tabsArray={tabsArray} />
            </Paper>
          ) : (
            <>
              <Tabs tabsArray={tabsArray} />
              <Typography
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                  mt: 4,
                  width: "100%",
                }}
                align="center"
                color="primary"
                fontSize={14}
                fontWeight="bold"
                onClick={() => setForgotPassword(true)}
              >
                Forgot your password?
              </Typography>
            </>
          )}
          {!isXs && (
            <Typography
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                mt: 2,
              }}
              color="primary"
              fontSize={14}
              fontWeight="bold"
              onClick={() => setForgotPassword(true)}
            >
              Forgot your password?
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Login;
