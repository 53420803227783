import { useEffect, useState } from "react";
import { useEventEmitter } from "src/components/hook/useEventEmitter";

export const useSyncByVideo = (enable: boolean = true) => {
  const { emit, on, off } = useEventEmitter();

  const [dotX, setDotX] = useState<number>();

  useEffect(() => {
    if (!enable) return;

    const onChangeTime = (currentTime: number) => {
      setDotX(currentTime);
    };

    on("changeTime_video", onChangeTime);
    return () => {
      off("changeTime_video", onChangeTime);
    };
  }, [enable, off, on]);

  return {
    onChange: (seconds: number) => {
      emit("changeTime_chart", seconds);
    },
    dotX,
  };
};
