import { FC } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";

type StackBarChartTooltipPropsType = {
  active?: boolean;
  payload?: { name: string; value: number; payload: { name: string } }[];
  label?: string;
};

const StackBarChartTooltip: FC<StackBarChartTooltipPropsType> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        component={Stack}
        direction="column"
        rowGap={1}
        sx={{ p: 1 }}
        elevation={3}
      >
        <Typography align="center" fontWeight="bold" fontSize={14}>
          {label}
        </Typography>
        <Typography color={green[400]}>
          {payload[0].name}: {payload[0].value}%
        </Typography>
        <Typography color={orange[400]}>
          {payload[1].name}: {payload[1].value}%
        </Typography>
        <Typography color={red[400]}>
          {payload[2].name}: {payload[2].value}%
        </Typography>
      </Paper>
    );
  }

  return null;
};

export default StackBarChartTooltip;
