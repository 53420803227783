import { SettingDto } from "src/app/services/generatedApi";
import { PersonPose } from "src/types/pose";
import { settingKeys } from "./poseConstants";
import { PersonPoses, PersonsRestructured } from "./poseProcessor";

const getNearFramesIndexes = (
  frameIndexes: number[],
  i: number,
  j: number,
  nearFrameCount = 8,
  maxDiff = 8,
): { nearFrameIndexes: number[]; nearRealFrameIndexes: number[] } => {
  const nearFrameIndexes: number[] = [];
  const nearRealFrameIndexes: number[] = [];
  const s = Math.max(0, j - nearFrameCount / 2);
  const e = Math.min(frameIndexes.length - 1, j + nearFrameCount / 2);
  for (let k = s; k <= e; k++) {
    if (frameIndexes[k] - i < maxDiff) {
      nearFrameIndexes.push(k);
      nearRealFrameIndexes.push(frameIndexes[k]);
    }
  }
  return { nearFrameIndexes, nearRealFrameIndexes };
};

const scaleArray = (array: number[], scale: number): number[] =>
  array.map((i) => i * scale);

const getTimeFactor = (
  indexes: number[],
  baseIndex: number,
  baseFactor = 1.5,
): number[] => {
  const timeFactor = indexes.map((currentIndex) =>
    currentIndex === baseIndex
      ? baseFactor
      : 1 / Math.abs(baseIndex - currentIndex),
  );
  const sum = timeFactor.reduce((a, b) => a + b, 0);
  return sum <= 0 ? timeFactor : scaleArray(timeFactor, 1 / sum);
};

const averageFrames = (
  nearFrames: Required<PersonPose>[],
  timeFactor: number[],
): Required<PersonPose> => {
  if (nearFrames.length === 0) {
    throw new Error("Empty Error");
  }

  const averagePoints = (points: number[][][]): number[][] =>
    points[0].map((_, j) =>
      points[0][j].map((_, k) =>
        points.reduce(
          (sum, point) => sum + point[j][k] * timeFactor[points.indexOf(point)],
          0,
        ),
      ),
    );

  const body = averagePoints(nearFrames.map((frame) => frame.keypoints.body));
  const hands = averagePoints(
    nearFrames.map((frame) => frame.keypoints.hands as number[][]),
  );
  const keypoints3d = averagePoints(
    nearFrames.map((frame) => frame.keypoints3d),
  );

  return {
    ...nearFrames[0],
    keypoints: {
      ...nearFrames[0].keypoints,
      body,
      hands: hands as [number[], number[]],
    },
    keypoints3d,
  };
};

const smoothPersonPoses = (personPoses: PersonPoses): PersonPoses => {
  const frameIndexes = personPoses.map(({ frameIndex }) => frameIndex);
  const endFrameIndex = frameIndexes[frameIndexes.length - 1];
  const newPersonPoses: PersonPoses = [];

  for (let i = 0, j = 0; i < endFrameIndex; i++) {
    while (frameIndexes[j] < i) j++;

    const { nearFrameIndexes, nearRealFrameIndexes } = getNearFramesIndexes(
      frameIndexes,
      i,
      j,
      6,
      6,
    );
    if (nearFrameIndexes.length < 4) continue;

    const timeFactor = getTimeFactor(nearRealFrameIndexes, i);
    const personPose = averageFrames(
      nearFrameIndexes.map((index) => personPoses[index].personPose),
      timeFactor,
    );
    newPersonPoses.push({ frameIndex: i, personPose });
  }
  return newPersonPoses;
};

export const smoothPoses = (persons: PersonsRestructured): void => {
  persons.forEach((person, i) => (persons[i] = smoothPersonPoses(person)));
};

const smoothJointAngle = (
  angleFrames: {
    frameIndexes: number;
    angle: number;
  }[],
  settingKey: keyof SettingDto,
  frameIndexes: number[],
  personPoses: PersonPoses,
): void => {
  const angleFrameIndexes = angleFrames.map(({ frameIndexes }) => frameIndexes);

  frameIndexes.forEach((frameIndex, idx) => {
    const currentPersonPose = personPoses[idx].personPose;
    if (currentPersonPose.angles[settingKey] === undefined) {
      return;
    }

    const j = angleFrameIndexes.findIndex((i) => i >= frameIndex);

    const { nearFrameIndexes, nearRealFrameIndexes } = getNearFramesIndexes(
      angleFrameIndexes,
      frameIndex,
      j,
    );
    if (nearFrameIndexes.length < 3) {
      currentPersonPose.angles[settingKey] = undefined;
      return;
    }
    const timeFactor = getTimeFactor(nearRealFrameIndexes, frameIndex, 1);

    const angle =
      nearFrameIndexes.reduce(
        (sum, nearFrameIndex, idx) =>
          sum + angleFrames[nearFrameIndex].angle * timeFactor[idx],
        0,
      ) / timeFactor.reduce((a, b) => a + b, 0);
    currentPersonPose.angles[settingKey] = Math.round(angle * 10) / 10;
  });
};

const smoothPersonAngles = (personPoses: PersonPoses): void => {
  const frameIndexes = personPoses.map(({ frameIndex }) => frameIndex);
  settingKeys.forEach((settingKey, settingIndex) => {
    const angleFrames = personPoses
      .filter((item) => item.personPose.angles?.[settingKey])
      .map((item) => ({
        frameIndexes: item.frameIndex,
        angle: item.personPose.angles![settingKey] as number,
      }));
    smoothJointAngle(angleFrames, settingKey, frameIndexes, personPoses);
  });
};

export const smoothAngles = (persons: PersonsRestructured): void => {
  persons.forEach(smoothPersonAngles);
};
