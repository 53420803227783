import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { FC, useContext } from "react";
import { AssessmentContext } from "src/context/AssessmentContext";
import { assessmentMethodsDetail } from "./constants";
import { SelectAssessmentMethodCard } from "./SelectAssessmentMethodCard";

type SelectAssessmentMethodDialogPropsType = {
  open: boolean;
  handleClose: () => void;
};

export const SelectAssessmentMethodDialog: FC<
  SelectAssessmentMethodDialogPropsType
> = ({ open, handleClose }) => {
  const { selectMethod } = useContext(AssessmentContext);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select Assessment Method</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {assessmentMethodsDetail.map((assessmentMethodDetail) => (
            <SelectAssessmentMethodCard
              key={assessmentMethodDetail.method}
              onClick={() => {
                selectMethod(assessmentMethodDetail.method);
                handleClose();
              }}
              assessmentMethodDetail={assessmentMethodDetail}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
