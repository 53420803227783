import {
  forwardRef,
  useEffect,
  useRef,
  useMemo,
  useState,
  useContext,
  useImperativeHandle,
  useCallback,
} from "react";
import { IconButton, Slider, Stack } from "@mui/material";
import {
  FastLayer,
  Image as KonvaImage,
  Layer,
  Rect,
  Stage,
} from "react-konva";
import Konva from "konva";
import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import { drawPersons } from "./draw";
import { TaskContext } from "src/context/TaskContext";
import { initHumanBodyGroup } from "./constants";
import { VideoPlayerPropsType } from "../VideoPlayer";
// import initWasm from "src/app/wasm-pkg/wasm_app";

Konva.pixelRatio = 1;

export const KonvaVideoPlayer = forwardRef<
  HTMLVideoElement,
  VideoPlayerPropsType
>(
  (
    {
      src,
      controls = true,
      size,
      onUpdate,
      onLoaded,
      mouseListening,
      initSelectedPersonId,
    },
    ref,
  ) => {
    const videoLayerRef = useRef<Konva.Layer | null>(null);
    const personsLayerRef = useRef<Konva.Layer | null>(null);
    const personsGroupRef = useRef<Record<number, Konva.Group>>([]);
    const lastVideoTime = useRef(0);

    const {
      smoothedVideoPose,
      selectedPersonId,
      fps,
      onChangeSelectedPersonId,
      calcAllPartState,
    } = useContext(TaskContext);

    const setSelectedPersonId = useCallback(
      !controls || !onChangeSelectedPersonId
        ? () => {}
        : onChangeSelectedPersonId,
      [controls, onChangeSelectedPersonId],
    );

    const getPersonsId = useCallback((): number[] => {
      const ids = new Set<number>();
      smoothedVideoPose?.forEach((framePose) =>
        framePose.forEach((personPose) => ids.add(personPose.id)),
      );
      return Array.from(ids);
    }, [smoothedVideoPose]);

    useEffect(() => {
      if (!smoothedVideoPose || !personsLayerRef.current) {
        return;
      }
      personsLayerRef.current.removeChildren();
      personsLayerRef.current.on("click tap touchend", () => {
        setSelectedPersonId(undefined);
      });

      const ids = getPersonsId();
      ids.forEach((id: number) => {
        const group = initHumanBodyGroup(id, setSelectedPersonId);
        personsGroupRef.current[id] = group;
        personsLayerRef?.current?.add(group);
      });
    }, [smoothedVideoPose, setSelectedPersonId, controls, getPersonsId]);

    const [isPlaying, setIsPlaying] = useState(false);
    const [position, setPosition] = useState(0);

    const video = useMemo(() => {
      const videoEl = document.createElement("video");
      let hasPlayed = false;

      videoEl.loop = true;
      lastVideoTime.current = 0;
      videoEl.oncanplaythrough = () => {
        if (!hasPlayed) {
          hasPlayed = true;
          setIsPlaying(false);
          onLoaded();
        }
      };

      videoEl.src = src;

      videoEl.crossOrigin = "Anonymous";

      return videoEl;
    }, [src, onLoaded]);

    useImperativeHandle(ref, () => video);

    const onChangeSlider = (value: number) => {
      if (!fps || !video) return;
      video.currentTime = value / fps;
      setPosition(value);
    };

    useEffect(() => {
      const anim = new Konva.Animation(() => {
        if (lastVideoTime.current !== video.currentTime) {
          lastVideoTime.current = video.currentTime;
          onUpdate();
        }

        const frameIndex = Math.round(video.currentTime * fps);
        const framePose = smoothedVideoPose?.[frameIndex];
        if (!framePose || !personsGroupRef.current || !personsLayerRef.current)
          return;

        setPosition(frameIndex);

        const calculateAllPartState = (personId: number) =>
          calcAllPartState(video.currentTime, personId);

        const personId =
          initSelectedPersonId !== undefined
            ? initSelectedPersonId
            : typeof selectedPersonId === "number"
              ? selectedPersonId
              : selectedPersonId?.[frameIndex];

        // initWasm().then(() => {
        drawPersons(
          framePose,
          personsGroupRef.current,
          size,
          calculateAllPartState,
          personId,
        );
        // });

        // do nothing in animation, it will just automatically redraw a layer
      }, [videoLayerRef.current, personsLayerRef.current]);
      anim.start();
      return () => {
        anim.stop();
      };
    }, [
      selectedPersonId,
      size,
      video,
      smoothedVideoPose,
      fps,
      calcAllPartState,
      onUpdate,
      initSelectedPersonId,
    ]);

    return (
      <>
        {controls && (
          <Stack
            sx={{
              position: "absolute",
              zIndex: 1,
              bottom: 0,
              left: 0,
              width: "100%",
              px: "4%",
              "&:hover": {
                background: "#cccccc55",
              },
              transition: "all 100ms",
            }}
            alignItems="center"
            justifyContent="center"
            spacing={2}
            direction="row"
          >
            <IconButton
              onClick={() => {
                setIsPlaying((prev) => {
                  try {
                    if (prev) {
                      video.pause();
                    } else {
                      video.play();
                    }
                    return !prev;
                  } catch {
                    return prev;
                  }
                });
              }}
            >
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <Slider
              size="small"
              value={position}
              min={0}
              step={1}
              max={smoothedVideoPose?.length || 100}
              onChange={(_, value) => onChangeSlider(value as number)}
              sx={{
                height: 4,
                color: "#333",
                "& .MuiSlider-thumb": {
                  width: 8,
                  height: 8,
                  transition: "width 0.3s cubic-bezier(.47,1.64,.41,.8)",
                  "&:before": {
                    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible": {
                    boxShadow: `0px 0px 0px 8px ${"rgb(0 0 0 / 16%)"}`,
                  },
                  "&.Mui-active": {
                    width: 15,
                    height: 15,
                  },
                },
                "& .MuiSlider-rail": {
                  opacity: 0.28,
                },
                "& .MuiSlider-track": {
                  transition: "unset!important",
                },
              }}
            />
          </Stack>
        )}
        <Stage
          width={size.width}
          height={size.height}
          listening={mouseListening}
        >
          <FastLayer
            ref={videoLayerRef}
            x={0}
            y={0}
            width={size.width}
            height={size.height}
            listening={false}
          >
            <Rect
              x={0}
              y={0}
              width={size.width}
              height={size.height}
              fill="white"
              listening={false}
            />
            <KonvaImage
              image={video}
              x={0}
              y={0}
              width={size.width}
              height={size.height}
              listening={false}
            />
          </FastLayer>
          <Layer
            ref={personsLayerRef}
            x={0}
            y={0}
            width={size.width}
            height={size.height}
            listening={mouseListening}
          ></Layer>
        </Stage>
      </>
    );
  },
);
