import { Component } from "react";
import { Button, Link, Paper, Stack, Typography } from "@mui/material";

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const reloadPageHandler = () => {
      window.location.reload();
    };

    if ((this as any).state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack
          sx={{
            height: "100vh",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Paper sx={{ p: { xs: 2, sm: 5 } }}>
            <Stack direction="column" rowGap={5}>
              <Typography
                fontSize={{ xs: 20, sm: 25, md: 30, lg: 35, xl: 40 }}
                align="center"
              >
                Sorry, something went wrong
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                columnGap={4}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => reloadPageHandler()}
                  sx={{
                    width: "150px",
                  }}
                >
                  Reload the page
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "150px",
                  }}
                  LinkComponent={Link}
                  href={"/"}
                >
                  Go home
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
