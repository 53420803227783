import { Box, Paper, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import ChangePassword from "src/components/organisms/profile/ChangePassword";
import Template from "src/components/templates/Template";
import { FC, useState } from "react";
import { ProfileOverView } from "src/components/organisms/profile/ProfileOverView";

const Profile: FC = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Template>
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          borderRadius: { xs: 0, md: 1 },
          overflow: "hidden",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)}>
            <Tab label="Overview" />
            <Tab label="Change Password" />
            <Tab />
          </Tabs>
        </Box>
        <Box
          p={{
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            background: 'url("/wave.svg")',
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          {tabIndex === 0 ? <ProfileOverView /> : <ChangePassword />}
        </Box>
      </Paper>
    </Template>
  );
};

export default Profile;
