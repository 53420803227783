import { settingsNamesEnum } from "../../category/CategorySettings";

const minSettingKeys = {
  neck: ["neck"],
  back: ["back"],
  upperArm: ["leftUpperArm", "rightUpperArm"],
  lowerArm: ["leftLowerArm", "rightLowerArm"],
  wrist: ["leftWrist", "rightWrist"],
  knee: ["leftKnee", "rightKnee"],
};

export const calculateMinChartData = (
  chartData: {
    key: settingsNamesEnum;
    l: {
      score: number;
      visibleCount: number;
    };
    r: {
      score: number;
      visibleCount: number;
    };
  }[],
) =>
  Object.entries(minSettingKeys).map(([minKey, keys]) => {
    const { totalLScore, totalLVisible, totalRScore, totalRVisible } =
      keys.reduce(
        (acc, key) => {
          const item = chartData.find(
            ({ key: chartKey }) => chartKey === (settingsNamesEnum as any)[key],
          );
          if (item) {
            acc.totalLScore += item.l.score;
            acc.totalLVisible += item.l.visibleCount;
            acc.totalRScore += item.r.score;
            acc.totalRVisible += item.r.visibleCount;
          }
          return acc;
        },
        { totalLScore: 0, totalLVisible: 0, totalRScore: 0, totalRVisible: 0 },
      );

    return {
      key: (settingsNamesEnum as any)[minKey],
      l: totalLVisible ? totalLScore / totalLVisible : 0,
      r: totalRVisible ? totalRScore / totalRVisible : 0,
    };
  });
