import {
  Box,
  Stack,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useEventEmitter } from "src/components/hook/useEventEmitter";
import { TaskContext } from "src/context/TaskContext";
import {
  defaultColor,
  humanBodyParts,
  HumanBodyPartType,
  stateToColor,
} from "./constant";
import { Human3d } from "./Human3d";
import { useAppSelector } from "src/app/hooks";

const HumanBodyPartSVG: FC<HumanBodyPartType & { isAverage: boolean }> = ({
  path,
  part,
  isAverage,
}) => {
  const { calcSelectedPartState } = useContext(TaskContext);

  const partRef = useRef<SVGPathElement | null>(null);

  const { on, off } = useEventEmitter();

  useEffect(() => {
    if (!partRef?.current) return;

    if (part === "any") {
      partRef.current.style.fill = defaultColor;
      return;
    }

    const onChangeTime = (currentTime: number) => {
      if (!partRef?.current) return;

      if (isAverage) {
        const state = calcSelectedPartState(part);
        partRef.current.style.fill = stateToColor[state];
        return;
      }

      const state = calcSelectedPartState(part, currentTime);
      partRef.current.style.fill = stateToColor[state];
    };

    on(["changeTime_video", "changeTime_chart"], onChangeTime);
    return () => {
      off(["changeTime_video", "changeTime_chart"], onChangeTime);
    };
  }, [calcSelectedPartState, isAverage, off, on, part]);

  return (
    <path
      ref={partRef}
      style={{
        fill:
          part === "any"
            ? defaultColor
            : isAverage
              ? stateToColor[calcSelectedPartState(part)]
              : defaultColor,
      }}
      d={path}
    />
  );
};

export const HumanBodySVG: FC<{
  maxHeight?: number;
  defaultAverage?: boolean;
}> = ({ maxHeight = 440, defaultAverage = false }) => {
  const [mode, setMode] = useState<"live" | "avg" | "3d">(
    defaultAverage ? "avg" : "live",
  );

  const { havePose } = useContext(TaskContext);

  const typeToggleHandler = (e: any, value: "live" | "avg" | "3d" | null) => {
    if (value === null) return;
    setMode(value);
  };

  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  return (
    <Stack
      direction="column"
      sx={{ position: { xl: "relative" } }}
      alignItems={{ xs: "center", xl: undefined }}
    >
      <Box
        sx={{
          position: { xl: "absolute" },
          top: { xl: "5%" },
          left: { xl: "0" },
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          size="small"
          onChange={typeToggleHandler}
          sx={{
            "& .MuiToggleButton-root": {
              px: 0.5,
              py: 0.1,
            },
          }}
        >
          <ToggleButton value="live">live</ToggleButton>
          <ToggleButton value="avg">avg</ToggleButton>
          {havePose && isAdmin && <ToggleButton value="3d">3d</ToggleButton>}
        </ToggleButtonGroup>
      </Box>
      {mode === "3d" ? (
        <Box
          sx={{
            height: "100%",
            maxHeight: maxHeight + "px",
            width: "100%",
            pt: 8,
          }}
        >
          <Human3d maxHeight={maxHeight - 100} />
        </Box>
      ) : (
        <Box id="humanBodyItem">
          <SvgIcon
            sx={{
              height: "100%",
              maxHeight: maxHeight + "px",
              width: "100%",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="1000"
            height="1500"
            viewBox="0 0 1000 1500"
          >
            {humanBodyParts.map(({ part, path }, index) => (
              <HumanBodyPartSVG
                key={index}
                part={part}
                path={path}
                isAverage={mode === "avg"}
              />
            ))}
          </SvgIcon>
        </Box>
      )}
    </Stack>
  );
};
