import { FramePose, VideoPose } from "src/types/pose";
import { calculateArea } from "./geometryUtils";

type PersonScore = { id: number; score: number };

const calculatePersonScore = (
  person: FramePose[number],
  maxPersonArea: number,
): number => {
  const pointScore = person.keypoints.body.reduce(
    (sum, point) => sum + point[2],
    0,
  );
  const bboxScore = person.bbox[4];
  const areaScore = calculateArea(person.bbox) / maxPersonArea;
  return pointScore / 10 + bboxScore + areaScore * 2;
};

const calculateFrameScores = (framePose: FramePose): PersonScore[] => {
  const maxPersonArea = Math.max(
    ...framePose.map((person) => calculateArea(person.bbox)),
  );
  return framePose.map((person) => ({
    id: person.id,
    score: calculatePersonScore(person, maxPersonArea),
  }));
};

const determineBestTarget = (
  framesBestId: (number | undefined)[],
  framesScores: PersonScore[][],
): number | undefined => {
  const tagScore: Record<number, number> = {};
  framesScores.forEach((frameScores, i) => {
    const sign =
      framesBestId[i] === undefined || framesBestId[i] === -1 ? 1 : -0.5;
    frameScores.forEach(({ id, score }) => {
      tagScore[id] = (tagScore[id] || 0) + sign * Math.abs(score);
    });
  });

  let bestId: number | undefined;
  let maxScore = 0;
  for (const key in tagScore) {
    if (tagScore[key] > maxScore) {
      bestId = +key;
      maxScore = tagScore[key];
    }
  }
  return bestId;
};

export const calcBestPerson = (
  videoPose: VideoPose,
): (number | undefined)[] => {
  const framesScores = videoPose.map(calculateFrameScores);

  let visited = 0;
  const framesBestId: (number | undefined)[] = videoPose.map(() => -1);

  while (visited < videoPose.length) {
    const bestId = determineBestTarget(framesBestId, framesScores);

    for (let index = 0; index < framesScores.length; index++) {
      const frameScore = framesScores[index];
      if (framesBestId[index] === -1) {
        if (bestId === undefined) {
          framesBestId[index] = bestId;
          visited++;
        } else {
          const personScore = frameScore.find(
            (personScore) => personScore.id === bestId,
          );
          if (personScore) {
            const value = personScore.score;
            framesBestId[index] = bestId;
            visited++;
            for (let i = 0; i < frameScore.length; i++) {
              frameScore[i].score -= value;
            }
          }
        }
      }
    }
  }
  return framesBestId;
};
