import { Box, Divider, Grid, Stack, Switch, Typography } from "@mui/material";
import { FC, useState } from "react";

import maleImage from "src/assets/images/mmh/male.png";
import femaleImage from "src/assets/images/mmh/female.png";
import { Gender } from "src/app/logic/assessment/mmh/constants";
import { green, red, yellow } from "@mui/material/colors";
import { MMHResultType } from "src/components/organisms/assessment/mmh/constant";

type FixedGenderMMHResultProps = {
  data: MMHResultType["data"][Gender.MALE];
  gender: Gender;
  isMetricUnit: boolean;
  hasError: boolean;
};

const FixedGenderMMHResult: FC<FixedGenderMMHResultProps> = ({
  data,
  gender,
  isMetricUnit,
  hasError,
}) => {
  const factor = isMetricUnit ? 100 : 220.462;
  const unit = isMetricUnit ? "kg" : "lb";

  const getValue = (value?: number) =>
    value === undefined || isNaN(value) || value === Infinity || value < 0
      ? "N/A"
      : Math.round(value * factor) / 100;

  const mals = [
    { title: "10%", value: getValue(data.percentilesMAL10) },
    { title: "25%", value: getValue(data.percentilesMAL25) },
    { title: "50%", value: getValue(data.percentilesMAL50) },
    { title: "75%", value: getValue(data.percentilesMAL75) },
    { title: "90%", value: getValue(data.percentilesMAL90) },
  ];

  const genderImage = gender === Gender.FEMALE ? femaleImage : maleImage;
  const acceptanceText =
    data.acceptancePercentage > 90
      ? "> 90%"
      : data.acceptancePercentage < 1
        ? "< 1%"
        : `${data.acceptancePercentage}%`;

  return (
    <Grid item xs>
      <Stack spacing={1.5} p={1}>
        <img
          src={genderImage}
          alt={gender}
          style={{ maxWidth: 100, display: "block", margin: "auto" }}
        />
        {hasError ? (
          <Typography align="center" variant="h6">
            Not Applicable
          </Typography>
        ) : (
          <>
            <Typography align="center" variant="h6">
              {`Acceptable to: ${acceptanceText}`}
            </Typography>
            <Stack>
              {mals.map((mal) => (
                <Typography key={mal.title} align="center" variant="subtitle1">
                  MAL<span style={{ fontSize: 12 }}>{mal.title}</span>
                  {": " + mal.value}
                  {mal.value !== "N/A" && (
                    <span style={{ fontSize: 12 }}>{unit}</span>
                  )}
                </Typography>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Grid>
  );
};

type MMHResultProps = {
  result: MMHResultType;
  hasError: { men: boolean; women: boolean };
};

export const MMHResult: FC<MMHResultProps> = ({ result, hasError }) => {
  const [isMetricUnit, setIsMetricUnit] = useState(true);

  const color =
    result.data.FEMALE.acceptancePercentage > 50
      ? green
      : result.data.FEMALE.acceptancePercentage > 30
        ? yellow
        : red;

  return (
    <Box
      sx={{
        border: `1px solid ${color[600]}`,
        borderRadius: 3,
        p: 3,
        background: color[50],
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography>Imperial Units</Typography>
        <Switch
          checked={isMetricUnit}
          onChange={() => setIsMetricUnit((prev) => !prev)}
        />
        <Typography>Metric Units</Typography>
      </Stack>

      <Typography align="center" gutterBottom>
        {result.title}
      </Typography>
      <Grid container>
        <FixedGenderMMHResult
          isMetricUnit={isMetricUnit}
          data={result.data.FEMALE}
          gender={Gender.FEMALE}
          hasError={hasError.women}
        />
        <Divider orientation="vertical" flexItem />
        <FixedGenderMMHResult
          isMetricUnit={isMetricUnit}
          data={result.data.MALE}
          gender={Gender.MALE}
          hasError={hasError.men}
        />
      </Grid>
    </Box>
  );
};
