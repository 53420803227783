import { FC, useState } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { EditTaskDialog } from "src/components/molecules/result/about/EditTaskDialog";
import { TaskRo } from "src/app/services/generatedApi";

type labelValueType = {
  label: string;
  value?: string;
};

const labelValue = ({ label, value }: labelValueType) => {
  if (value) {
    return (
      <Grid
        item
        xs={12}
        sm="auto"
        sx={{
          minWidth: "80px",
          maxWidth: "200px",
          overflow: "hidden",
          cursor: "default",
        }}
      >
        <Stack
          direction={{ xs: "row", sm: "column" }}
          columnGap={1}
          alignItems={{ xs: "start", sm: "center" }}
        >
          <Typography fontSize={14} fontWeight="bold">
            {label}
          </Typography>
          <Tooltip arrow title={value}>
            <Typography
              sx={{ maxWidth: "200px" }}
              fontSize={14}
              noWrap
              textOverflow="ellipsis"
              overflow="hidden"
              color={
                label === "Angle threshold" && value === "Deleted"
                  ? "error"
                  : "default"
              }
            >
              {value || ""}
            </Typography>
          </Tooltip>
        </Stack>
      </Grid>
    );
  } else {
    return null;
  }
};

type AboutPropsType = { task?: TaskRo };

const About: FC<AboutPropsType> = ({ task }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const theme = useTheme(),
    isMd = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          pt: 2,
          px: 2,
          pb: 1,
          borderRadius: { xs: 0, md: 1 },
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: 3, top: 3 }}
          onClick={() => setOpenEdit(true)}
        >
          <EditIcon />
        </IconButton>
        <Stack direction="column" spacing={2}>
          <Grid
            container
            rowGap={{ xs: 0, sm: 1, md: 2, lg: 3 }}
            columnGap={{ xs: 2, md: 5 }}
            justifyContent="center"
          >
            {labelValue({ label: "Name", value: task?.name })}
            {labelValue({ label: "Tag", value: task?.tag?.name })}
            {labelValue({ label: "State", value: task?.state })}
            {labelValue({
              label: "Description",
              value: task?.description,
            })}
          </Grid>
        </Stack>
      </Paper>
      {task && (
        <EditTaskDialog
          task={task}
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
        />
      )}
    </>
  );
};

export default About;
