import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { FC, useContext } from "react";
import { useParams } from "react-router";

import {
  useAssessmentsControllerCreateAssessmentMutation,
  useAssessmentsControllerUpdateAssessmentMutation,
} from "src/app/services/generatedApi";
import { AssessmentContext } from "src/context/AssessmentContext";

type SaveDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export const SaveDialog: FC<SaveDialogProps> = ({ open, handleClose }) => {
  const { id: taskId } = useParams();
  const {
    id,
    name,
    setName,
    assessmentDataDto,
    frameIndex,
    selectedMethod,
    targetId,
  } = useContext(AssessmentContext);

  const [createAssessment, { isLoading: isCreating }] =
    useAssessmentsControllerCreateAssessmentMutation();
  const [updateAssessment, { isLoading: isUpdating }] =
    useAssessmentsControllerUpdateAssessmentMutation();

  const isLoading = isCreating || isUpdating;

  const handleSave = async () => {
    if (!taskId || !selectedMethod || !assessmentDataDto) return;

    const action = id
      ? updateAssessment({
          updateAssessmentDto: {
            id,
            name,
            method: selectedMethod,
            frameIndex,
            data: assessmentDataDto,
          },
        })
      : createAssessment({
          assessmentDto: {
            taskId,
            method: selectedMethod,
            name,
            frameIndex,
            data: assessmentDataDto,
            targetId,
          },
        });

    try {
      await action.unwrap();
      toast.success("Saved Successfully.");
      handleClose();
    } catch (error) {
      toast.error("Failed to save.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Save</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a name for this assessment result.
        </DialogContentText>
        <TextField
          value={name}
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={isLoading} onClick={handleSave} autoFocus>
          {id ? "Update" : "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
