import { FC, useContext, useEffect } from "react";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import { BodyPartState, TaskContext } from "src/context/TaskContext";

export const TaskStateListener: FC<{
  onChangePartsState: (data: { score: number; visibleCount: number }[]) => void;
  onChangeWholeState: (safe: number, hazard: number, caution: number) => void;
}> = ({ onChangePartsState, onChangeWholeState }) => {
  const { calcSelectedPartStateCount } = useContext(TaskContext);

  useEffect(() => {
    let safe = 0,
      hazard = 0,
      caution = 0;
    const data = settingKeys.map((key) => {
      const counts = calcSelectedPartStateCount(key);
      safe += counts[BodyPartState.SAFE];
      caution += counts[BodyPartState.CAUTION];
      hazard += counts[BodyPartState.HAZARD];
      return {
        score:
          50 * counts[BodyPartState.CAUTION] +
          100 * counts[BodyPartState.HAZARD],
        visibleCount:
          counts[BodyPartState.SAFE] +
          counts[BodyPartState.CAUTION] +
          counts[BodyPartState.HAZARD],
      };
    });
    onChangePartsState(data);

    const sum = (safe + caution + hazard) / 100;
    onChangeWholeState(
      Math.round(safe / sum),
      Math.round(caution / sum),
      Math.round(hazard / sum),
    );
  }, [calcSelectedPartStateCount, onChangePartsState, onChangeWholeState]);

  return <></>;
};
