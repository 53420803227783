import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ChangeEvent, FC, useState } from "react";
import { toast } from "react-toastify";

import { TaskRo } from "src/app/services/generatedApi";
import {
  ReportSection,
  defaultParticipantInformation,
} from "src/app/logic/report/constants";
import { CircularProgressWithLabel } from "../../atoms/CircularProgressWithLabel";
import { generateReport } from "src/app/logic/report/reportGenerator";

import { ParticipantInformationInputs } from "./ParticipantInformationInputs";

export const ReportButton: FC<{
  task: TaskRo;
  openReportDialog: boolean;
  setOpenReportDialog: (open: boolean) => void;
}> = ({ task, openReportDialog, setOpenReportDialog }) => {
  const [generatingReport, setGeneratingReport] = useState(-1);
  const [participantInformation, setParticipantInformation] = useState(
    defaultParticipantInformation,
  );
  const [neededSections, setNeededSections] = useState([
    ReportSection.ASSESSMENT_INFORMATION,
    ReportSection.ANALYSIS_SNAPSHOT_AND_BODY_MAP,
    ReportSection.BODY_JOINT_ANGLES_AND_RISK_LEVELS,
    ReportSection.ASSESSMENTS,
    ReportSection.OBSERVATION_AND_RECOMMENDATION,
  ]);
  const [participantInformationExpanded, setParticipantInformationExpanded] =
    useState(true);

  const resetDialogState = () => {
    setGeneratingReport(-1);
    setParticipantInformation(defaultParticipantInformation);
    setNeededSections([
      ReportSection.ASSESSMENT_INFORMATION,
      ReportSection.ANALYSIS_SNAPSHOT_AND_BODY_MAP,
      ReportSection.BODY_JOINT_ANGLES_AND_RISK_LEVELS,
      ReportSection.ASSESSMENTS,
      ReportSection.OBSERVATION_AND_RECOMMENDATION,
    ]);
    setParticipantInformationExpanded(true);
  };

  const handleCloseReportDialog = () => {
    resetDialogState();
    setOpenReportDialog(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setParticipantInformation((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNeededSectionChange = (section: ReportSection) => {
    setNeededSections((prev) =>
      prev.includes(section)
        ? prev.filter((sec) => sec !== section)
        : [...prev, section],
    );
  };

  const handleGenerateReport = () => {
    setGeneratingReport(0);
    setTimeout(() => {
      generateReport(
        participantInformation,
        neededSections,
        task,
        setGeneratingReport,
      )
        .catch((err) => {
          toast.error("There is an issue! Please try again later.");
          console.error(err);
        })
        .then(() => setGeneratingReport(-1));
    }, 50);
  };

  return (
    <Dialog
      open={openReportDialog}
      onClose={handleCloseReportDialog}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Generate Report</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {Object.keys(ReportSection).map((section) =>
            section === "ASSESSMENT_INFORMATION" ? (
              <Accordion
                key={section}
                component="div"
                defaultExpanded
                expanded={
                  neededSections.includes(
                    ReportSection.ASSESSMENT_INFORMATION,
                  ) && participantInformationExpanded
                }
                onChange={() =>
                  setParticipantInformationExpanded((prev) => !prev)
                }
              >
                <AccordionSummary
                  expandIcon={
                    neededSections.includes(
                      ReportSection.ASSESSMENT_INFORMATION,
                    ) && <ExpandMore />
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={neededSections.includes(
                          ReportSection.ASSESSMENT_INFORMATION,
                        )}
                      />
                    }
                    label={section.replaceAll("_", " ")}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleNeededSectionChange(
                        ReportSection.ASSESSMENT_INFORMATION,
                      );
                      setParticipantInformationExpanded(true);
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <ParticipantInformationInputs
                    participantInformation={participantInformation}
                    handleInputChange={handleInputChange}
                  />
                </AccordionDetails>
              </Accordion>
            ) : (
              <FormControlLabel
                key={section}
                control={
                  <Checkbox checked={neededSections.includes(section as any)} />
                }
                label={section.replaceAll("_", " ")}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNeededSectionChange(section as any);
                }}
              />
            ),
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{ height: 50, width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          {generatingReport < 0 ? (
            <Button fullWidth onClick={handleGenerateReport}>
              Generate
            </Button>
          ) : (
            <Stack sx={{ width: "100%" }} alignItems="center">
              <CircularProgressWithLabel value={generatingReport} />
            </Stack>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
