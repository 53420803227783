import { EventRepeat as EventRepeatIcon } from "@mui/icons-material";
import { FC } from "react";
import {
  PushPullCarryTaskDataDto,
  PushPullLoadDto,
} from "src/app/services/generatedApi";
import { HorizontalDistanceIcon } from "src/components/atoms/Icons/HorizontalDistanceIcon";
import { PullIcon } from "src/components/atoms/Icons/PullIcon";
import { PushIcon } from "src/components/atoms/Icons/PushIcon";
import { VerticalIcon } from "src/components/atoms/Icons/VerticalIcon";
import {
  UnitModes,
  UnitValues,
} from "src/components/molecules/assessment/mmh/constants";
import { MMHFormField } from "src/components/molecules/assessment/mmh/FormField";

export const PushPull: FC<{
  push?: boolean;
  data: PushPullCarryTaskDataDto;
  load: PushPullLoadDto;
  onChangeData: (name: keyof PushPullCarryTaskDataDto, value: any) => void;
  onChangeLoad: (name: keyof PushPullLoadDto, value: any) => void;
  onChangeError: (
    name: string
  ) => (errorState: { men: boolean; women: boolean }) => void;
}> = ({
  push = false,
  data,
  load,
  onChangeData,
  onChangeLoad,
  onChangeError,
}) => {
  return (
    <>
      <MMHFormField
        label="Frequency"
        icon={<EventRepeatIcon />}
        unitMode={UnitModes.Frequency}
        min={1 / 1440}
        max={10}
        value={data.frequency}
        onChange={(value) => onChangeData("frequency", value)}
        setErrorState={onChangeError("frequency")}
      />

      <MMHFormField
        label="Initial Force"
        icon={push ? <PushIcon /> : <PullIcon />}
        unitMode={UnitModes.Weight}
        textFieldProps={{
          inputProps: {
            step: 0.5,
          },
        }}
        min={0}
        value={load.initialForce}
        onChange={(value) => onChangeLoad("initialForce", value)}
        setErrorState={onChangeError("initialForce")}
      />

      <MMHFormField
        label="Sustained Force"
        icon={push ? <PushIcon /> : <PullIcon />}
        unitMode={UnitModes.Weight}
        textFieldProps={{
          inputProps: {
            step: 0.5,
          },
        }}
        min={0}
        value={load.sustainedForce}
        onChange={(value) => onChangeLoad("sustainedForce", value)}
        setErrorState={onChangeError("sustainedForce")}
      />

      <MMHFormField
        label="Horizontal Distance"
        icon={<HorizontalDistanceIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.FEET, UnitValues.METER]}
        min={2.1}
        max={61}
        value={data.horizontalDistance}
        onChange={(value) => onChangeData("horizontalDistance", value)}
        setErrorState={onChangeError("horizontalDistance")}
      />

      <MMHFormField
        label="Vertical Hand Height"
        icon={<VerticalIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.INCH, UnitValues.CENTIMETER]}
        minF={0.58}
        maxF={1.33}
        minM={0.63}
        maxM={1.44}
        value={data.verticalHandHeight}
        onChange={(value) => onChangeData("verticalHandHeight", value)}
        setErrorState={onChangeError("verticalHandHeight")}
      />
    </>
  );
};
