import { UserUsageAndLimitRo } from "src/app/services/generatedApi";
import { UserWithUsages } from "src/types/userTypes";

export type HeadIdType =
  | Exclude<keyof UserWithUsages, "firstName" | "lastName">
  | "name"
  | "action";

type HeadCell = {
  id: HeadIdType;
  disablePadding: boolean;
  label: string;
  sortable: boolean;
};

export const headCells: readonly HeadCell[] = [
  {
    id: "isActive",
    disablePadding: false,
    label: "",
    sortable: true,
  },
  {
    id: "username",
    disablePadding: false,
    label: "Username",
    sortable: true,
  },
  {
    id: "name",
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    sortable: true,
  },
  {
    id: "roleNames",
    disablePadding: false,
    label: "Role",
    sortable: true,
  },
  {
    id: "usage",
    disablePadding: false,
    label: "Usage",
    sortable: true,
  },
  {
    id: "limit",
    disablePadding: false,
    label: "Limit",
    sortable: true,
  },
  {
    id: "action",
    disablePadding: false,
    label: "",
    sortable: false,
  },
];

export const comparators: {
  [x in HeadIdType]?: (a: any, b: any) => -1 | 0 | 1;
} = {
  usage: (a: UserUsageAndLimitRo["usage"], b: UserUsageAndLimitRo["usage"]) => {
    const [x, y] = [a.duration, b.duration];
    if (!x) return -1;
    if (!y) return 1;
    return x > y ? 1 : x < y ? -1 : 0;
  },
  limit: (a: UserUsageAndLimitRo["limit"], b: UserUsageAndLimitRo["limit"]) => {
    const [x, y] = [a.count, b.count];
    if (!x) return -1;
    if (!y) return 1;
    return x > y ? 1 : x < y ? -1 : 0;
  },
};
