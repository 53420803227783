import { FC } from "react";
import {
  Box,
  SwipeableDrawer,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import ListItems from "./ListItems";
import { useTheme } from "@mui/system";
import { grey } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { changeSidebarMenuStateAction } from "src/app/slice/sidebarSlice";
import { logoutAction } from "src/app/slice/authSlice";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { authenticatedUserType } from "src/types/authTypes";
import { Logout as LogoutIcon } from "@mui/icons-material";

const Sidebar: FC = () => {
  const {
      sidebar: { showMenu },
    } = useAppSelector((state) => state),
    dispatch = useAppDispatch(),
    theme = useTheme(),
    isLg = useMediaQuery(theme.breakpoints.up("md")),
    navigate = useNavigate();

  const {
    user: { firstName, lastName, username },
  } = useAppSelector((state) => state.auth as authenticatedUserType);

  const logoutHandler = () => {
    dispatch(logoutAction());
    toast.success("You are successfully logged out");
    navigate("/login");
  };

  return (
    <SwipeableDrawer
      variant={isLg ? "permanent" : "temporary"}
      anchor="left"
      open={showMenu}
      onClose={() => dispatch(changeSidebarMenuStateAction(false))}
      onOpen={() => dispatch(changeSidebarMenuStateAction(true))}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: "260px",
          overflow: "hidden",
        },
      }}
    >
      <Box
        bgcolor="primary.main"
        py={5}
        px={1}
        sx={{
          overflow: "hidden",
        }}
      >
        <Typography
          align="center"
          variant="h6"
          color="white"
          fontWeight="bold"
          noWrap
        >
          {firstName + " " + lastName}
        </Typography>
        <Typography align="center" color="white" noWrap>
          ({username})
        </Typography>
      </Box>
      <ListItems />
      <Button
        fullWidth
        sx={{
          borderRadius: "50px",
          width: "220px",
          pt: "10px",
          pb: "10px",
          pr: "20px",
          pl: "20px",
          m: "20px",
          marginTop: "auto",
          color: grey[600],
          justifyContent: "start",
          fontSize: "1rem",
        }}
        onClick={logoutHandler}
        startIcon={
          <LogoutIcon
            fontSize="large"
            sx={{ color: "rgba(0,0,0,0.5)", width: "24px", height: "24px" }}
          />
        }
      >
        Logout
      </Button>
    </SwipeableDrawer>
  );
};

export default Sidebar;
