import { green, red, yellow } from "@mui/material/colors";
import { FC, useMemo, useCallback } from "react";
import { ThresholdDto } from "src/app/services/generatedApi";

type ThresholdChartLinearGradientProps = {
  id: string;
  minMax: { min: number; max: number };
  threshold: ThresholdDto;
};

export const ThresholdChartLinearGradient: FC<
  ThresholdChartLinearGradientProps
> = ({ id, minMax, threshold }) => {
  const calculateOffset = useCallback(
    (thresh: number) => {
      if (minMax.max === minMax.min) return 0;
      return ((minMax.max - thresh) / (minMax.max - minMax.min)) * 100;
    },
    [minMax.max, minMax.min],
  );

  const [offset1, offset2, offset3, offset4] = useMemo(
    () =>
      [
        threshold.threshold1,
        threshold.threshold2,
        threshold.threshold3,
        threshold.threshold4,
      ].map(calculateOffset),
    [calculateOffset, threshold],
  );

  const startColor =
    offset4 >= 0
      ? red[500]
      : offset3 >= 0
        ? yellow[500]
        : offset2 >= 0
          ? green[500]
          : offset1 >= 0
            ? yellow[500]
            : red[500];

  const endColor =
    offset1 <= 100
      ? red[500]
      : offset2 <= 100
        ? yellow[500]
        : offset3 <= 100
          ? green[500]
          : offset4 <= 100
            ? yellow[500]
            : red[500];

  const createStop = (offset: number, color1: string, color2: string) =>
    offset >= 0 &&
    offset <= 100 && (
      <>
        <stop offset={`${offset}%`} stopColor={color1} />
        <stop offset={`${offset}%`} stopColor={color2} />
      </>
    );

  return (
    <linearGradient id={id} x1="0" y1="0%" x2="0" y2="100%">
      <stop offset="0%" stopColor={startColor} />
      {createStop(offset4, red[500], yellow[500])}
      {createStop(offset3, yellow[500], green[500])}
      {createStop(offset2, green[500], yellow[500])}
      {createStop(offset1, yellow[500], red[500])}
      <stop offset="100%" stopColor={endColor} />
    </linearGradient>
  );
};
