import { Dialog, DialogContent, DialogTitle, MenuList } from "@mui/material";
import { FC } from "react";
import { SettingDto } from "src/app/services/generatedApi";
import CategorySettings from "../../../organisms/category/CategorySettings";

type ThresholdDialogPropsType = {
  open: boolean;
  onClose: (state: boolean) => any;
  value: SettingDto;
};

const ThresholdDialog: FC<ThresholdDialogPropsType> = ({
  open,
  onClose,
  value,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableScrollLock
    >
      <DialogTitle sx={{ textAlign: "center" }}>Angle Thresholds</DialogTitle>
      <DialogContent>
        <MenuList>
          <CategorySettings value={value} />
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

export default ThresholdDialog;
