import { Button } from "@mui/material";
import { FC, useState } from "react";
import { SettingDto } from "src/app/services/generatedApi";
import ThresholdDialog from "src/components/molecules/result/dialogs/ThresholdDialog";

export const ShowAngleThreshold: FC<{ setting: SettingDto }> = ({
  setting,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button onClick={() => setShowDialog(true)}>Show angle thresholds</Button>
      <ThresholdDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        value={setting}
      />
    </>
  );
};
