import { FC } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { stateToColor } from "src/components/molecules/result/humanBodySVG/constant";

const COLORS = [stateToColor.SAFE, stateToColor.CAUTION, stateToColor.HAZARD];

export const TaskPieChart: FC<{
  wholeTaskState: {
    name: string;
    value: number;
  }[];
}> = ({ wholeTaskState }) => {
  return (
    <ResponsiveContainer>
      <PieChart>
        <text x="50%" y={15} textAnchor="middle" fontWeight={600}>
          Overall Risk
        </text>
        <Pie
          data={wholeTaskState}
          cx="50%"
          cy="60%"
          innerRadius={20}
          outerRadius={40}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label={({ name, percent }) => `${(percent * 100).toFixed(0)}%`}
        >
          {wholeTaskState.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
