import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import autoTable from "jspdf-autotable";
import { participantInformationInputs } from "./constants";
import { robotoLight } from "src/assets/fonts/roboto/Roboto-Light";
import { robotoMedium } from "src/assets/fonts/roboto/Roboto-Medium";
import { robotoBold } from "src/assets/fonts/roboto/Roboto-Bold";
import { robotoBlack } from "src/assets/fonts/roboto/Roboto-Black";

export const centeredText = (pdf: jsPDF, text: string, y: number) => {
  const pageWidth = pdf.internal.pageSize.getWidth();
  const textWidth = pdf.getTextWidth(text);
  const x = (pageWidth - textWidth) / 2;
  pdf.text(text, x, y);
};

export const loadFonts = (pdf: jsPDF) => {
  Object.values(jsPdfFonts).forEach((font) => {
    pdf.addFileToVFS(font.file, font.value);
    pdf.addFont(font.file, "roboto", font.style);
  });
};

const drawImageFromCanvas = async (
  pdf: jsPDF,
  selector: string,
  x: number,
  y: number,
  styleWidth: string,
  delay = 0,
  imgWidth?: number,
  imgHeight?: number,
): Promise<number> => {
  const element = document.querySelector(selector) as HTMLElement;
  if (!element) return 0;
  element.style.width = styleWidth;

  return new Promise((resolve) => {
    setTimeout(async () => {
      const canvas = await html2canvas(element);
      const ratio = canvas.width / canvas.height;
      const image = canvas.toDataURL("image/png");

      imgHeight = imgHeight || (imgWidth || 0) / ratio;
      imgWidth = imgWidth || (imgHeight || 0) * ratio;

      pdf.addImage(image, "PNG", x, y, imgWidth, imgHeight);

      element.style.width = null as any;
      resolve(ratio);
    }, delay);
  });
};

const drawImageItems = async (
  pdf: jsPDF,
  containerSelector: string,
  itemSelector: string,
  x: number,
  y: number,
  maxWidthStyle: string,
  imgWidth?: number,
  imgHeight?: number,
): Promise<number> => {
  const container = document.querySelector(containerSelector) as HTMLElement;
  if (!container) return 0;

  const items = container.querySelectorAll(itemSelector);
  for (let index = 0; index < items.length; index++) {
    (items[index] as HTMLElement).style.maxWidth = maxWidthStyle;
  }

  const ratio = await drawImageFromCanvas(
    pdf,
    containerSelector,
    x,
    y,
    "1400px",
    2000,
    imgWidth,
    imgHeight,
  );

  for (let index = 0; index < items.length; index++) {
    (items[index] as HTMLElement).style.maxWidth = "";
  }

  return ratio;
};

export const drawBodyPartsOverallChart = async (pdf: jsPDF): Promise<void> => {
  pdf.setFillColor("#04b485").rect(0, 26, 5, 5, "F");
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  const sectionTitle = "Risk Level Summary";
  const sectionTitleX = 10;
  const sectionTitleY = 30;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);

  await drawImageFromCanvas(
    pdf,
    "#body-parts-overall",
    10,
    55,
    "1000px",
    2000,
    180,
  );
};

export const drawBodyPartsOverallItems = async (pdf: jsPDF): Promise<void> => {
  await drawImageItems(
    pdf,
    "#body-parts-overall-items",
    ".MuiGrid-item",
    20,
    40,
    "1%",
    180,
  );
};

export const drawCompareBodyPartsChart = async (
  pdf: jsPDF,
  initY = 150,
): Promise<void> => {
  pdf.setFillColor("#04b485").rect(0, initY, 5, 5, "F");
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  const sectionTitle = "Body Joint Angles";
  const sectionTitleX = 10;
  const sectionTitleY = initY + 4;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);

  await drawImageItems(
    pdf,
    "#compare-body-parts-chart",
    ".MuiGrid-item",
    30,
    initY + 10,
    "50%",
    160,
  );
};

export const drawAssessmentListItems = async (pdf: jsPDF): Promise<void> => {
  pdf.setFillColor("#04b485").rect(0, 26, 5, 5, "F");
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  const sectionTitle = "Assessments";
  const sectionTitleX = 10;
  const sectionTitleY = 30;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);

  const width = 170;

  const ratio = await drawImageItems(
    pdf,
    "#assessment-items",
    ".MuiGrid-item",
    20,
    50,
    "25%",
    width,
  );

  const y = 50 + width / (ratio || 1);

  pdf.text("REBA Score and MSD Risk Levels", 20, y + 25);

  const headers = [[" ", "Score", "Level of MSD Risk"]];

  const data = [
    [" ", "1", "Low risk, no action needed"],
    [" ", "2-3", "Low to moderate risk, monitor regularly"],
    [" ", "4-7", "Moderate risk, action needed soon"],
    [" ", "8-10", "High risk, immediate action needed"],
    [
      " ",
      "11+",
      "Very high risk, work should stop until corrective action is taken",
    ],
  ];

  autoTable(pdf, {
    head: headers,
    body: data,
    columnStyles: { 0: { cellWidth: "auto" }, 1: { cellWidth: "wrap" } },
    margin: { left: 23, right: 25 },
    startY: y + 30,
    headStyles: { textColor: [0, 0, 0] },
    bodyStyles: { textColor: [0, 0, 0] },
    didParseCell: (data) => {
      if (data.column.index === 1) {
        data.cell.styles.halign = "center";
      }
      switch (data.row.index) {
        case 0:
          if (data.section !== "body") {
            data.cell.styles.fillColor = "#dddddd";
          } else {
            data.cell.styles.fillColor = "#B2DFDB";
          }
          break;
        case 1:
          data.cell.styles.fillColor = "#FFF176";
          break;
        case 2:
          data.cell.styles.fillColor = "#FFB74D";
          break;
        case 3:
          data.cell.styles.fillColor = "#EF5350";
          break;
        case 4:
          data.cell.styles.fillColor = "#B71C1C";
          break;
      }
    },
    didDrawCell: function (data) {
      if (data.section === "body" && data.column.index === 0) {
        pdf.addImage(
          `/report/reba_icon${data.row.index + 1}.png`,
          "PNG",
          data.cell.x + 2,
          data.cell.y + 1,
          5,
          5,
        );
      }
    },
  });
};

export const drawObservationAndRecommendation = async (
  pdf: jsPDF,
  observation: string,
  recommendation: string,
): Promise<void> => {
  pdf.setFillColor("#04b485").rect(0, 26, 5, 5, "F");
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");

  let sectionTitle = "Observation";
  const sectionTitleX = 10;
  let sectionTitleY = 30;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);

  if (observation) {
    pdf.setFontSize(14).setFont("roboto", "light");
    const observationText = pdf.splitTextToSize(observation, 180);
    pdf.text(observationText, 10, sectionTitleY + 10);
  }

  pdf.setFillColor("#04b485").rect(0, sectionTitleY + 60, 5, 5, "F");
  sectionTitle = "Recommendation";
  const recommendationTitleY = sectionTitleY + 64;
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  pdf.text(sectionTitle, sectionTitleX, recommendationTitleY);

  if (recommendation) {
    pdf.setFontSize(14).setFont("roboto", "light");
    const recommendationText = pdf.splitTextToSize(recommendation, 180);
    pdf.text(recommendationText, 10, recommendationTitleY + 10);
  }
};

export const drawLeftLogo = (pdf: jsPDF) =>
  pdf.addImage("/report/logo1.png", "PNG", 5, 7, 35, 18);

export const drawRightLogo = (pdf: jsPDF) =>
  pdf.addImage("/report/logo2.png", "PNG", 165, 7, 40, 15);

export const writeTitle = (pdf: jsPDF) => {
  pdf.setFontSize(30).setFont("roboto", "bold").setTextColor("black");
  centeredText(pdf, "Pose Checker Analysis Report", 50);
};

export const writeSubTitle = (pdf: jsPDF) => {
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  centeredText(pdf, "Report Date: " + moment().format("DD MMM YYYY"), 65);
};

export const drawFirstPageImage = (pdf: jsPDF) => {
  const pageWidth = pdf.internal.pageSize.getWidth();
  const imageWidth = 160;
  const x = (pageWidth - imageWidth) / 2;

  pdf.addImage("/report/Picture1.png", "JPG", x, 80, imageWidth, 100);
};

export const writeParticipantInformation = (
  pdf: jsPDF,
  participantInformationType: Record<string, string>,
  taskCreationTime: string,
) => {
  pdf.setFillColor("#04b485").rect(0, 190, 220, 100, "F");
  pdf.saveGraphicsState();
  pdf.setGState(pdf.GState({ opacity: 0.3 }));
  pdf.addImage("/report/logo3.png", "JPG", 152, 208, 48, 60);
  pdf.restoreGraphicsState();

  pdf.setFontSize(21).setFont("roboto", "black").setTextColor("#ffffff");
  const sectionTitle = "Assessment Information";
  const sectionTitleX = 20;
  const sectionTitleY = 208;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);
  pdf.setLineWidth(0.5);
  pdf.setDrawColor(255, 255, 255);
  pdf.line(
    sectionTitleX,
    sectionTitleY + 1,
    sectionTitleX + pdf.getTextWidth(sectionTitle),
    sectionTitleY + 1,
  );

  let lineIndex = 0;

  const items = [
    ["Video Analysis Date", taskCreationTime],
    ...Object.entries(participantInformationInputs).map(([key, value]) => [
      value.label,
      participantInformationType[key],
    ]),
  ];

  pdf.setFontSize(16).setFont("roboto", "bold");
  items.forEach(([key, value]) => {
    const keyText = "- " + key + ":";
    pdf.text(keyText, 30, 217 + lineIndex * 8);

    const splitTitle = pdf.splitTextToSize(
      value || "",
      170 - pdf.getTextWidth(keyText),
    );
    pdf.setFont("roboto", "light");
    pdf.text(splitTitle, 34 + pdf.getTextWidth(keyText), 217 + lineIndex * 8);

    lineIndex += splitTitle.length;
  });
};

export const drawSelectedFrame = async (pdf: jsPDF) => {
  pdf.setFillColor("#04b485").rect(0, 26, 5, 5, "F");
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  const sectionTitle = "Analysis Snapshot";
  const sectionTitleX = 10;
  const sectionTitleY = 30;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);

  const video = document.querySelector("#videoPlayerContainer video");
  const canvases = (
    video
      ? [await html2canvas(video as HTMLElement)]
      : Array.from(document.querySelectorAll("#videoPlayerContainer canvas")) ||
        []
  ) as HTMLCanvasElement[];

  for (let canvas of canvases) {
    const ratio = canvas.width / canvas.height;
    const imgHeight = 100;
    const imgWidth = imgHeight * ratio;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const x = (pageWidth - imgWidth) / 2;

    const image = canvas.toDataURL("image/png");

    pdf.setLineWidth(1).rect(x, 45, imgWidth, imgHeight).setLineWidth(0);
    pdf.addImage(image, "PNG", x, 45, imgWidth, imgHeight);
  }
};

export const drawHumanBodyItem = async (pdf: jsPDF, initY = 150) => {
  pdf.setFillColor("#04b485").rect(0, initY, 5, 5, "F");
  pdf.setFontSize(16).setFont("roboto", "normal").setTextColor("black");
  const sectionTitle = "Body Map Summary";
  const sectionTitleX = 10;
  const sectionTitleY = initY + 5;
  pdf.text(sectionTitle, sectionTitleX, sectionTitleY);

  const humanBodyItem = document.querySelector("#humanBodyItem") as HTMLElement;

  const canvas = await html2canvas(humanBodyItem);

  const ratio = canvas.width / canvas.height;
  const imgHeight = 85;
  const imgWidth = imgHeight * ratio;
  const pageWidth = pdf.internal.pageSize.getWidth();
  const x = (pageWidth - imgWidth) / 2;

  const image = canvas.toDataURL("image/png");

  pdf.addImage(image, "PNG", x, initY + 20, imgWidth, imgHeight);
  const itemsX = (pageWidth + x + imgWidth) / 2;
  pdf.setFontSize(14).setFont("roboto", "normal");
  pdf.setFillColor("#e57373").rect(itemsX - 5, initY + 40, 3, 3, "F");
  pdf.text("Hazard", itemsX, initY + 43);
  pdf.setFillColor("#ffb74d").rect(itemsX - 5, initY + 45, 3, 3, "F");
  pdf.text("Caution", itemsX, initY + 48);
  pdf.setFillColor("#04b485").rect(itemsX - 5, initY + 50, 3, 3, "F");
  pdf.text("Safe", itemsX, initY + 53);
};

export const drawFooterLogo = (pdf: jsPDF) => {
  pdf.addImage("/report/ewiworksLogo.png", "PNG", 10, 265, 40, 10);
};

export const jsPdfFonts = {
  robotoLight: { file: "robotoLight.ttf", value: robotoLight, style: "light" },
  robotoMedium: {
    file: "robotoMedium.ttf",
    value: robotoMedium,
    style: "normal",
  },
  robotoBold: { file: "robotoBold.ttf", value: robotoBold, style: "bold" },
  robotoBlack: { file: "robotoBlack.ttf", value: robotoBlack, style: "black" },
};
