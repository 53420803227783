import { FC } from "react";
import { Box } from "@mui/material";

const Footer: FC = () => {
  return (
    <Box sx={{ height: 50, mt: 2, mb: 1 }}>
      <img
        src="/poweredBy2.png"
        alt="Powered By Pose Checker"
        style={{ height: "100%" }}
      />
    </Box>
  );
};

export default Footer;
