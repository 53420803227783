import { FC, useEffect, useState, useRef } from "react";
import {
  useMediaQuery,
  Paper,
  Stack,
  Switch,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Chip,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Warning as WarningIcon } from "@mui/icons-material";

import Dropzone from "src/components/organisms/dropzone/Dropzone";
import Template from "src/components/templates/Template";
import Loading from "src/components/atoms/Loading";
import AutocompleteWithAdd, {
  nameIdType,
} from "src/components/atoms/AutocompleteWithAdd";
import { UploadProcessDialog } from "src/components/organisms/addVideo/uploadProcess/UploadProcessDialog";

import {
  useCategoryControllerGetAllCategoriesQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  useUsersControllerCheckUserLimitReachedQuery,
} from "src/app/services/generatedApi";
import { useCustomMediaControllerUploadVideoMutation } from "src/app/services/api";

export const tagModifier = (tag: string | nameIdType | null) => {
  if (!tag) return "";
  return typeof tag === "string"
    ? tag
    : tag.name.replaceAll('"', "").replace("Add ", "");
};

const AddVideo: FC = () => {
  const { data: allCategories, isLoading: getCategoryLoading } =
    useCategoryControllerGetAllCategoriesQuery();
  const { data: allTags, isLoading: getTagsLoading } =
    useTasksControllerGetUserTagsForCurrentUserQuery();
  const { data: limitResult, isLoading: checkLimitLoading } =
    useUsersControllerCheckUserLimitReachedQuery(null as any, {
      refetchOnMountOrArgChange: 10,
    });

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<any>(null);
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState<string | nameIdType | null>(null);
  const [description, setDescription] = useState("");
  const [blurFace, setBlurFace] = useState(false);
  const [blurBack, setBlurBack] = useState(false);
  const [multiTarget, setMultiTarget] = useState(false);

  useEffect(() => {
    if (allCategories) {
      const defaultCategory = allCategories.categories.find(
        (cat) => cat.name === "Default" && cat.isSystemCategory,
      );
      if (defaultCategory) {
        setCategory(defaultCategory.id);
      }
    }
  }, [allCategories]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));
  const [createTask] = useCustomMediaControllerUploadVideoMutation();
  const [uploadProcessEvent, setUploadProcessEvent] = useState<
    { loaded: number; total?: number } | undefined
  >();
  const abortController = useRef<AbortController | undefined>();
  const navigate = useNavigate();
  const someFieldIsEmpty = !fileName || !file || !category;

  const submitHandler = () => {
    if (someFieldIsEmpty) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("categoryId", category);
    formData.append("name", fileName);
    formData.append("description", description);
    formData.append("tagName", tagModifier(tag) || "");
    formData.append("blurFace", blurFace as any);
    formData.append("blurBack", blurBack as any);
    formData.append("multiTarget", multiTarget as any);

    abortController.current = new AbortController();

    createTask({
      body: formData as any,
      abortController: abortController.current,
      onUploadProgress: setUploadProcessEvent,
    })
      .unwrap()
      .then(() => {
        setFileName("");
        setFile(null);
        setCategory("");
        setTag(null);
        setDescription("");
        toast.success("Task successfully added");
        navigate("/results");
      });
  };

  if (getCategoryLoading || getTagsLoading || checkLimitLoading) {
    return (
      <Template>
        <Loading />
      </Template>
    );
  }

  if (limitResult?.reached) {
    return (
      <Template>
        <Paper sx={{ p: 2 }}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <WarningIcon fontSize="large" color="error" />
            <Typography>
              {"You've reached your subscription limit."}
              <br />
              {"Please contact us at "}
              <a href="mailto:info@posechecker.com">info@posechecker.com</a>
              {" to renew your subscription."}
            </Typography>
          </Stack>
        </Paper>
      </Template>
    );
  }

  return (
    <Template>
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          p: { xs: 2, sm: 3, md: 4, lg: 5 },
          borderRadius: { xs: 0, md: 1 },
        }}
      >
        {allCategories && allCategories.categories.length < 1 ? (
          <Stack justifyContent="center" alignItems="center" rowGap={4}>
            <Typography align="center" variant="h6">
              It looks like you have not created any angle thresholds yet,
              please first create one and then get back to add new video.
            </Typography>
            <RouterLink
              to="/angleThresholds"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" color="primary">
                Create Angle thresholds
              </Button>
            </RouterLink>
          </Stack>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="File name"
                    fullWidth
                    value={fileName}
                    onChange={({ target: { value } }) => setFileName(value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="angleThresholdsLabelId">
                      Angle Thresholds
                    </InputLabel>
                    <Select
                      required
                      labelId="angleThresholdsLabelId"
                      id="angleThresholds"
                      value={category}
                      label="AngleThresholds"
                      onChange={({ target: { value } }) => setCategory(value)}
                    >
                      {allCategories?.categories &&
                        [...allCategories.categories]
                          .reverse()
                          .map(({ name, id, isSystemCategory }, index) => (
                            <MenuItem key={index} value={id}>
                              <Typography component="span">{name}</Typography>
                              {isSystemCategory && (
                                <Typography
                                  component="span"
                                  color="primary"
                                  fontSize={13}
                                  fontWeight="bold"
                                >
                                  &nbsp;(SYSTEM ANGLE THRESHOLDS)
                                </Typography>
                              )}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteWithAdd
                onChange={setTag}
                options={(allTags?.tags as any) || []}
                label="Tag"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={blurFace}
                      onChange={() => setBlurFace(!blurFace)}
                    />
                  }
                  label="Blur Faces"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={blurBack}
                      onChange={() => setBlurBack(!blurBack)}
                    />
                  }
                  label="Blur Background"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={multiTarget}
                      onChange={() => setMultiTarget(!multiTarget)}
                    />
                  }
                  label={
                    <Stack direction="row" spacing={1}>
                      <Typography>Multi-Subject Analysis</Typography>
                      <Tooltip title="The multi-subject feature, still in development, is designed to analyze and conduct assessments on several individuals within a single uploaded video. This innovative functionality enables users to seamlessly toggle between the analysis results of each person included in the video.">
                        <Chip label="Beta" size="small" color="warning" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={6}
                label="Description"
                fullWidth
                value={description}
                onChange={({ target: { value } }) => setDescription(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropzone onChange={setFile} />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={someFieldIsEmpty}
                onClick={submitHandler}
                sx={{ width: "100%", maxWidth: "330px" }}
                variant="contained"
              >
                <Typography fontWeight="bold">Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
      <UploadProcessDialog
        handleClose={() => setUploadProcessEvent(undefined)}
        uploadProcessEvent={uploadProcessEvent as any}
        cancel={() => {
          abortController.current?.abort();
          setUploadProcessEvent(undefined);
        }}
      />
    </Template>
  );
};

export default AddVideo;
