import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import auth from "./slice/authSlice";
import sidebar from "./slice/sidebarSlice";
import assessments from "./slice/assessmentsSlice";
import { api } from "./services/api";
import { authMiddleware } from "./services/authMiddleware";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth,
    sidebar,
    assessments,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware, api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateType,
  unknown,
  Action<string>
>;
