import { FC, useMemo } from "react";
import { MenuItem, TextField } from "@mui/material";
import {
  useRolesControllerFindRoleQuery,
  useUsersControllerAssignUserRoleMutation,
} from "src/app/services/generatedApi";
import { toast } from "react-toastify";
import Loading from "src/components/atoms/Loading";
import { useAppSelector } from "src/app/hooks";

type AdminAccessSwitchPropsType = {
  username: string;
  roleNames: string[];
};

const AdminAccessSwitch: FC<AdminAccessSwitchPropsType> = ({
  username,
  roleNames,
}) => {
  const isAdmin = useMemo(
    () => roleNames.some((x) => x.toUpperCase() === "ADMIN"),
    [roleNames],
  );

  const { auth } = useAppSelector((state) => state);

  const [assignRole, { isLoading: assignRoleLoading }] =
    useUsersControllerAssignUserRoleMutation();
  const { data: allRoles, isLoading: getRolesLoading } =
    useRolesControllerFindRoleQuery();

  const adminSwitchHandler = (toAdmin: boolean) => {
    if (!allRoles?.roles || allRoles.roles.length < 1 || toAdmin === isAdmin)
      return;

    const adminRoleId = allRoles.roles.find(({ name }) => name === "admin")?.id;

    assignRole({
      userRoleDto: {
        username,
        roleIds: toAdmin && adminRoleId ? [adminRoleId] : [],
      },
    })
      .unwrap()
      .then(() => {
        toast.success("User role successfully updated");
      });
  };

  return (
    <>
      {(assignRoleLoading || getRolesLoading) && <Loading />}
      <TextField
        select
        value={isAdmin ? "1" : "0"}
        onChange={(e) => adminSwitchHandler(e.target.value === "1")}
        disabled={username === auth?.user.username}
        size="small"
        SelectProps={{ MenuProps: { autoFocus: false } }}
        variant="standard"
        sx={{
          "& .MuiInput-root::before": {
            borderBottom: "0!important",
          },
        }}
      >
        <MenuItem value="0">User</MenuItem>
        <MenuItem value="1">Admin</MenuItem>
      </TextField>
    </>
  );
};

export default AdminAccessSwitch;
