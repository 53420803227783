import { TextField, MenuItem, Stack } from "@mui/material";
import { ChangeEvent, FC } from "react";

import {
  participantInformationInputs,
  InputTypes,
} from "src/app/logic/report/constants";

interface ParticipantInformationInputsProps {
  participantInformation: Record<string, string>;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ParticipantInformationInputs: FC<
  ParticipantInformationInputsProps
> = ({ participantInformation, handleInputChange }) => {
  return (
    <Stack spacing={1}>
      {Object.entries(participantInformationInputs).map(([key, inputInfo]) => (
        <TextField
          size="small"
          key={key}
          name={key}
          label={inputInfo.label}
          type={
            inputInfo.type === InputTypes.OPTIONS ? "string" : inputInfo.type
          }
          inputProps={
            inputInfo.type !== InputTypes.OPTIONS
              ? { min: inputInfo.min, max: inputInfo.max }
              : {}
          }
          onChange={handleInputChange}
          select={inputInfo.type === InputTypes.OPTIONS}
          value={participantInformation[key]}
        >
          {inputInfo.type === InputTypes.OPTIONS &&
            inputInfo.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </TextField>
      ))}
    </Stack>
  );
};
