import { FC } from "react";
import { SvgIcon } from "@mui/material";

type ListItemCustomSvgPropsType = {
  svgPath: any;
  selected?: boolean;
};

const ListItemCustomSvg: FC<ListItemCustomSvgPropsType> = ({
  svgPath,
  selected = false,
}) => {
  return (
    <SvgIcon
      width="24px"
      height="24px"
      sx={{ fill: selected ? "#fff" : "rgba(0,0,0,0.5)" }}
    >
      {svgPath}
    </SvgIcon>
  );
};

export default ListItemCustomSvg;
