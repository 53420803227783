import { FC, useState } from "react";
import { Grid, Stack, Button, Box } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Dropzone from "src/components/organisms/addVideo/dropzone/Dropzone";
import { useCustomMediaControllerUploadVideoMutation } from "src/app/services/api";
import { UploadFileCard } from "./UploadFileCard";
import { tagModifier } from "./tagModifier";
import { nameIdType } from "src/components/atoms/AutocompleteWithAdd";

type UploadStepProps = {
  initialValues: {
    taskName: string;
    category: string;
    tag: string | nameIdType | null;
    description: string;
    blurFace: boolean;
    blurBack: boolean;
    multiTarget: boolean;
  };
  onCompleted: () => void;
  handleBack: () => void;
};

type FileUploadState = {
  file: File;
  progress: number;
  status: "uploading" | "success" | "error";
  abortController: AbortController;
};

const removeFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) return fileName;
  return fileName.substring(0, lastDotIndex);
};

export const UploadStep: FC<UploadStepProps> = ({
  initialValues,
  onCompleted,
  handleBack,
}) => {
  const [fileUploadStates, setFileUploadStates] = useState<FileUploadState[]>(
    [],
  );
  const [isUploading, setIsUploading] = useState(false);
  const [createTask] = useCustomMediaControllerUploadVideoMutation();
  const navigate = useNavigate();

  const submitHandler = async () => {
    setIsUploading(true);
    for (let i = 0; i < fileUploadStates.length; i++) {
      const { file, abortController } = fileUploadStates[i];
      try {
        await uploadFile(file, i, abortController);
      } catch (error) {
        toast.error("Failed to upload file");
      }
    }
    onCompleted();
    navigate("/results");
    setIsUploading(false);
  };

  const handleCancelAll = () => {
    fileUploadStates.forEach(({ abortController }) => abortController.abort());
    setFileUploadStates([]);
    handleBack();
  };

  const uploadFile = async (
    file: File,
    index: number,
    abortController: AbortController,
  ) => {
    const cleanFileName = removeFileExtension(file.name);
    const taskName =
      initialValues.taskName + (cleanFileName ? " - " + cleanFileName : "");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("categoryId", initialValues.category);
    formData.append("name", taskName);
    formData.append("description", initialValues.description);
    formData.append("tagName", tagModifier(initialValues.tag));
    formData.append("blurFace", initialValues.blurFace as any);
    formData.append("blurBack", initialValues.blurBack as any);
    formData.append("multiTarget", initialValues.multiTarget as any);

    try {
      await createTask({
        body: formData as any,
        abortController,
        onUploadProgress: (event) => {
          setFileUploadStates((prev) =>
            prev.map((state, i) =>
              i === index
                ? {
                    ...state,
                    progress: (event.loaded / (event.total || 1)) * 100,
                    status: "uploading",
                  }
                : state,
            ),
          );
        },
      }).unwrap();
      setFileUploadStates((prev) =>
        prev.map((state, i) =>
          i === index ? { ...state, status: "success" } : state,
        ),
      );
    } catch (error) {
      if (abortController.signal.aborted) {
        console.log(`Upload aborted: ${file.name}`);
      } else {
        setFileUploadStates((prev) =>
          prev.map((state, i) =>
            i === index ? { ...state, status: "error" } : state,
          ),
        );
        throw error;
      }
    }
  };

  const handleFilesChange = (files: File[]) => {
    const newFileStates = files.map((file) => ({
      file,
      progress: 0,
      status: "uploading",
      abortController: new AbortController(),
    }));
    setFileUploadStates((prev) => [...prev, ...newFileStates] as any);
  };

  const handleCancel = (index: number) => {
    const { abortController } = fileUploadStates[index];
    abortController.abort();
    const updatedFiles = fileUploadStates.filter((_, i) => i !== index);
    setFileUploadStates(updatedFiles);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Dropzone onAddFiles={handleFilesChange} />
          <Box>
            {fileUploadStates?.map((state, index) => (
              <UploadFileCard
                key={index}
                file={state.file}
                progress={state.progress}
                status={state.status}
                cancel={() => handleCancel(index)}
              />
            ))}
            <Stack
              direction="row"
              sx={{ width: "100%" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                disabled={isUploading || fileUploadStates.length === 0}
                onClick={submitHandler}
                sx={{ width: "100%", maxWidth: "330px", fontWeight: 700 }}
                variant="contained"
              >
                {isUploading ? "Uploading..." : "Submit"}
              </Button>

              <Button onClick={handleCancelAll}>Back</Button>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
