import { FC, useCallback, useEffect, useState } from "react";
import { TextField, Stack, Button, Grid } from "@mui/material";
import {
  TaskRo,
  useTasksControllerUpdateObservationAndRecommendationMutation,
} from "src/app/services/generatedApi";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

type NoteContentProps = {
  onClose?: () => void;
  task: TaskRo;
  editable?: boolean;
};

const NoteField: FC<{
  label: string;
  value: string;
  editable: boolean;
  onChange: (value: string) => void;
}> = ({ label, value, editable, onChange }) => (
  <TextField
    label={label}
    multiline
    rows={!editable && !value ? 2 : 4}
    variant={editable ? "outlined" : "filled"}
    fullWidth
    value={value || ""}
    onChange={(e) => editable && onChange(e.target.value)}
    disabled={!editable}
    sx={{
      "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: !editable ? "#f5f5f5" : "#fff",
      },
      "& *": {
        color: "black!important",
        textFillColor: "black!important",
      },
    }}
  />
);

export const NotesContent: FC<NoteContentProps> = ({
  onClose,
  task,
  editable = false,
}) => {
  const [update, { isLoading }] =
    useTasksControllerUpdateObservationAndRecommendationMutation();
  const [observation, setObservation] = useState(task.observation || "");
  const [recommendation, setRecommendation] = useState(
    task.recommendation || "",
  );

  const resetData = useCallback(() => {
    setObservation(task.observation || "");
    setRecommendation(task.recommendation || "");
  }, [task.observation, task.recommendation]);

  useEffect(() => {
    resetData();
  }, [resetData]);

  const handleClose = () => {
    resetData();
    onClose?.();
  };

  const handleSave = async () => {
    try {
      await update({
        updateObservationRecommendationDto: {
          id: task.id,
          observation,
          recommendation,
        },
      }).unwrap();
      toast.success("Observation and recommendation updated successfully");
    } catch (error) {
      toast.error("Failed to update observation and recommendation");
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={editable ? 12 : 6}>
          <NoteField
            label="Observation"
            value={observation}
            editable={editable}
            onChange={setObservation}
          />
        </Grid>
        <Grid item xs={12} md={editable ? 12 : 6}>
          <NoteField
            label="Recommendation"
            value={recommendation}
            editable={editable}
            onChange={setRecommendation}
          />
        </Grid>
      </Grid>
      {editable && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%", mt: 1 }}
        >
          <LoadingButton
            loading={isLoading}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            Save
          </LoadingButton>
          <Button onClick={handleClose}>Cancel</Button>
        </Stack>
      )}
    </>
  );
};
