import { FC, useState, SyntheticEvent, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { FilterOptionsState } from "@mui/material";

export type nameIdType = {
  inputValue?: string;
  name: string;
  id?: number;
};

const filter = createFilterOptions<nameIdType>();

type AutocompleteWithAddPropsType = {
  onChange: (value: string | nameIdType | null) => any;
  options: readonly nameIdType[];
  label?: string;
  initValue?: string;
};

const AutocompleteWithAdd: FC<AutocompleteWithAddPropsType> = ({
  onChange,
  options,
  label,
  initValue,
}) => {
  const [value, setValue] = useState<nameIdType | null>(
    initValue
      ? {
          name: initValue,
        }
      : null
  );

  useCallback(() => {
    if (!value) {
      onChange(null);
    }
  }, [value, onChange]);

  const inputChangeHandler = (
    event: SyntheticEvent<Element, Event>,
    newValue: string | nameIdType | null
  ) => {
    onChange(newValue);
    if (typeof newValue === "string") {
      setValue({
        name: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        name: newValue.inputValue,
      });
    } else {
      setValue(newValue);
    }
  };

  const filterOptionsHandler = (
    options: nameIdType[],
    params: FilterOptionsState<nameIdType>
  ) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }
    return filtered;
  };

  const getOptionLabelHandler = (option: nameIdType | string) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.name;
  };

  return (
    <Autocomplete
      value={value}
      onChange={inputChangeHandler}
      filterOptions={filterOptionsHandler}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={label}
      options={options}
      getOptionLabel={getOptionLabelHandler}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField
          onChange={({ target: { value } }) => {
            if (!value) {
              onChange(null);
            }
          }}
          {...params}
          label={label}
        />
      )}
    />
  );
};

export default AutocompleteWithAdd;
