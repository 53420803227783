import {
  RebaRulaQuestionCheck,
  RebaRulaQuestionSelect,
} from "../reba-rula/types";

export enum RulaItems {
  UPPER_ARM_ANGLE = "UPPER_ARM_ANGLE",
  UPPER_ARM_ADJUST = "UPPER_ARM_ADJUST",
  LOWER_ARM_ANGLE = "LOWER_ARM_ANGLE",
  LOWER_ARM_ADJUST = "LOWER_ARM_ADJUST",
  WRIST_ANGLE = "WRIST_ANGLE",
  BENT_WRIST = "BENT_WRIST",
  WRIST_TWIST = "WRIST_TWIST",
  MUSCLE_USE = "MUSCLE_USE",
  NECK_ANGLE = "NECK_ANGLE",
  NECK_ADJUST = "NECK_ADJUST",
  TRUNK_ANGLE = "TRUNK_ANGLE",
  TRUNK_ADJUST = "TRUNK_ADJUST",
  LEG_SUPPORTED = "LEG_SUPPORTED",
  FORCE_MODE = "FORCE_MODE",
}

export const rulaItemsList: RulaItems[] = Object.keys(RulaItems) as any[];

export type RulaQuestions = {
  [RulaItems.UPPER_ARM_ANGLE]: RebaRulaQuestionSelect<RulaUpperArm>;
  [RulaItems.UPPER_ARM_ADJUST]: RebaRulaQuestionCheck;
  [RulaItems.LOWER_ARM_ANGLE]: RebaRulaQuestionSelect<RulaLowerArm>;
  [RulaItems.LOWER_ARM_ADJUST]: RebaRulaQuestionCheck;
  [RulaItems.WRIST_ANGLE]: RebaRulaQuestionSelect<RulaWrist>;
  [RulaItems.BENT_WRIST]: RebaRulaQuestionCheck;
  [RulaItems.WRIST_TWIST]: RebaRulaQuestionSelect<boolean>;
  [RulaItems.MUSCLE_USE]: RebaRulaQuestionCheck;
  [RulaItems.FORCE_MODE]: RebaRulaQuestionSelect<RulaForceMode>;
  [RulaItems.NECK_ANGLE]: RebaRulaQuestionSelect<RulaNeck>;
  [RulaItems.NECK_ADJUST]: RebaRulaQuestionCheck;
  [RulaItems.TRUNK_ANGLE]: RebaRulaQuestionSelect<RulaTrunk>;
  [RulaItems.TRUNK_ADJUST]: RebaRulaQuestionCheck;
  [RulaItems.LEG_SUPPORTED]: RebaRulaQuestionSelect<boolean>;
};

export enum RulaNeck {
  ANGLE_0_MINUS = "ANGLE_0_MINUS",
  ANGLE_0_10 = "ANGLE_0_10",
  ANGLE_10_20 = "ANGLE_10_20",
  ANGLE_20_PLUS = "ANGLE_20_PLUS",
}

export enum RulaTrunk {
  ANGLE_0_10 = "ANGLE_0_10",
  ANGLE_10_20 = "ANGLE_10_20",
  ANGLE_20_60 = "ANGLE_20_60",
  ANGLE_60_PLUS = "ANGLE_60_PLUS",
}

export enum RulaForceMode {
  LOAD_0_4_LBS = "LOAD_0_4_LBS",
  LOAD_4_22_LBS = "LOAD_4_22_LBS",
  LOAD_4_22_LBS_REPEATED = "LOAD_4_22_LBS_REPEATED",
  LOAD_22_PLUS_LBS = "LOAD_22_PLUS_LBS",
}

export enum RulaUpperArm {
  ANGLE__20_MINUS = "ANGLE__20_MINUS",
  ANGLE__20_20 = "ANGLE__20_20",
  ANGLE_20_45 = "ANGLE_20_45",
  ANGLE_45_90 = "ANGLE_45_90",
  ANGLE_90_PLUS = "ANGLE_90_PLUS",
}

export enum RulaLowerArm {
  ANGLE_0_60_OR_100_PLUS = "ANGLE_0_60_OR_100_PLUS",
  ANGLE_60_100 = "ANGLE_60_100",
}

export enum RulaWrist {
  ANGLE_0 = "ANGLE_0",
  ANGLE__15_15 = "ANGLE__15_15",
  ANGLE_GT_15 = "ANGLE_GT_15",
}
