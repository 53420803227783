import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const LiftIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.14757 14.9537C8.05326 15.5991 9.13524 15.9506 10.2472 15.9612C10.7117 15.9694 11.452 15.9527 11.3854 15.2966C11.3469 14.9899 11.0677 14.7722 10.7611 14.8096C8.92903 15.0326 7.59489 13.9924 6.93974 13.3214C6.73691 12.4936 6.13808 11.7004 5.20206 12.0907H5.20189C5.64554 11.4672 5.76889 10.6712 5.53506 9.94272C5.30106 9.21423 4.73723 8.63874 4.01373 8.39004C3.29006 8.14137 2.49156 8.24854 1.85924 8.67937C1.22692 9.11005 0.834896 9.81388 0.801403 10.5784C0.780736 11.1847 0.987071 11.7771 1.37992 12.2394C1.77275 12.7017 2.32392 13.0011 2.92561 13.0786C3.52742 13.1562 4.13644 13.0066 4.63377 12.6589C4.49576 12.9637 4.50227 13.3144 4.65143 13.6141C5.31575 14.8148 4.61043 16.1967 3.86112 16.6951C3.60495 16.8907 3.44545 17.1872 3.4231 17.5087C3.40077 17.8304 3.51794 18.1459 3.7446 18.3751C3.97144 18.6042 4.28579 18.7247 4.60762 18.7057C4.86096 18.6884 5.10145 18.5879 5.29195 18.4201L6.48679 17.8227L6.11329 19.6848C6.07112 19.9039 6.12846 20.1304 6.26996 20.3031C6.41129 20.4758 6.62212 20.5766 6.84528 20.5782H7.96528C8.18177 20.5732 8.38578 20.4759 8.52579 20.3109C8.66596 20.1459 8.72895 19.9289 8.69912 19.7144C8.66912 19.5001 8.54896 19.3088 8.36895 19.1884C8.18895 19.0683 7.96612 19.0306 7.7566 19.0849L8.25128 16.6186C8.28828 16.4088 8.23661 16.1929 8.10861 16.0226C7.98078 15.8522 7.78794 15.7424 7.57613 15.7194C7.3643 15.6964 7.15246 15.7621 6.99079 15.9009C7.07978 15.5924 7.13229 15.2746 7.14762 14.9538L7.14757 14.9537Z" />
      <path d="M20.5372 16.7927L23.1879 6.91065C23.2395 6.71182 23.1212 6.50848 22.9227 6.45514L19.32 5.48814L15.1835 4.37932L11.5436 3.40499V3.40515C11.4476 3.37899 11.3451 3.39215 11.2587 3.44199C11.1726 3.49182 11.1097 3.57382 11.0844 3.67016L8.43739 13.5522C8.41173 13.6477 8.42539 13.7495 8.47506 13.8352C8.52489 13.9207 8.60672 13.9827 8.70256 14.0077L20.0818 17.0578V17.0577C20.1773 17.0833 20.2791 17.0698 20.3646 17.02C20.4501 16.9703 20.5123 16.8885 20.5373 16.7927L20.5372 16.7927ZM18.0027 10.074L13.8662 8.96514L14.9564 4.89213L19.0929 6.00096L18.0027 10.074Z" />
      <path d="M3.77606 14.2753L3.35456 13.4323C3.34156 13.4065 3.31706 13.3885 3.28873 13.384C3.26023 13.3793 3.23139 13.3887 3.21106 13.409L2.78939 13.8305C2.76906 13.8508 2.75973 13.8798 2.76439 13.9083C2.76906 13.9367 2.78706 13.9612 2.81273 13.974L3.65574 14.3955C3.69024 14.4128 3.73207 14.4062 3.75924 14.3788C3.78657 14.3515 3.79323 14.3098 3.77606 14.2753Z" />
      <path d="M2.58181 16.4206C2.59415 16.4466 2.61831 16.4651 2.64664 16.4703C2.67498 16.4755 2.70415 16.4666 2.72481 16.4466L3.40381 15.7928V15.793C3.43164 15.7661 3.43914 15.7246 3.42264 15.6898C3.40597 15.655 3.36897 15.6348 3.33064 15.6395L2.39513 15.7551H2.3953C2.36663 15.7586 2.34147 15.7756 2.32763 15.801C2.3138 15.8261 2.31297 15.8565 2.3253 15.8825L2.58181 16.4206Z" />
      <path d="M2.44698 14.4857L2.29565 15.0622C2.28831 15.0902 2.29481 15.1199 2.31315 15.142C2.33148 15.1642 2.35931 15.1764 2.38815 15.1744L3.32865 15.1132C3.36715 15.1107 3.39965 15.0839 3.40948 15.0465C3.41931 15.0092 3.40415 14.9699 3.37181 14.9489L2.58265 14.4334C2.55848 14.4177 2.52832 14.4145 2.50149 14.4249C2.47465 14.4352 2.45431 14.4579 2.44698 14.4857Z" />
    </SvgIcon>
  );
};
