import {
  LiftLowerCarryLoadDto,
  LiftLowerTaskDataDto,
  PushPullCarryTaskDataDto,
  PushPullLoadDto,
} from "src/app/services/generatedApi";

export const defaultLiftLowerTaskData: LiftLowerTaskDataDto = {
  frequency: NaN,
  handCoupling: "GOOD",
  startHandHeight: NaN,
  endHandHeight: NaN,
  startHandDistance: NaN,
  endHandDistance: NaN,
};

export const defaultLiftLowerCarryLoad: LiftLowerCarryLoadDto = {
  objectWeight: NaN,
};

export const defaultPushPullCarryTaskData: PushPullCarryTaskDataDto = {
  frequency: NaN,
  horizontalDistance: NaN,
  verticalHandHeight: NaN,
};

export const defaultPushPullLoad: PushPullLoadDto = {
  initialForce: NaN,
  sustainedForce: NaN,
};

export const handCouplingSelectItems: {
  mode: LiftLowerTaskDataDto["handCoupling"];
  text: string;
}[] = [
  {
    mode: "GOOD",
    text: "Good",
  },
  {
    mode: "FAIR",
    text: "Fair",
  },
  {
    mode: "POOR",
    text: "Poor",
  },
];
