import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import { twoDecimalPlaces } from "src/utils/chartsUtils";

import { AssessmentContext } from "src/context/AssessmentContext";
import { TaskContext } from "src/context/TaskContext";
import {
  extractRebaData,
  getRebaResult,
  getRebaScore,
} from "src/app/logic/assessment/reba/Reba";
import { getRebaQuestions } from "src/app/logic/assessment/reba/RebaQuestions";
import { AssessmentsChart } from "../../charts/lineChart/typeTwo/AssessmentsChart";
import { AssessmentPage } from "./AssessmentPage";
import {
  extractRulaData,
  getRulaResult,
  getRulaScore,
} from "src/app/logic/assessment/rula/Rula";
import { getRulaQuestions } from "src/app/logic/assessment/rula/RulaQuestions";

export const Assessments: FC<{
  fileName: string;
  havePose: boolean;
}> = ({ fileName, havePose }) => {
  const {
    frameIndex,
    setAssessmentDataDto,
    assessmentPageIndex,
    setAssessmentPageIndex,
    selectedMethod,
    setTargetId,
  } = useContext(AssessmentContext);
  const { selectedAngles, selectedAngles3D, fps, selectedPersonId, isSited } =
    useContext(TaskContext);

  const pid =
    selectedPersonId === undefined || !frameIndex
      ? undefined
      : typeof selectedPersonId === "number"
        ? selectedPersonId
        : selectedPersonId[frameIndex];

  const chartData = useMemo(
    () =>
      selectedAngles?.map((frameAngles, index) => ({
        angle:
          selectedMethod === "RULA"
            ? getRulaScore(
                getRulaResult(
                  extractRulaData(
                    frameAngles,
                    getRulaQuestions(),
                    isSited,
                    selectedAngles3D?.[index],
                  ),
                ),
              )
            : getRebaScore(
                getRebaResult(
                  extractRebaData(
                    frameAngles,
                    getRebaQuestions(),
                    selectedAngles3D?.[index],
                  ),
                ),
              ),
        seconds: twoDecimalPlaces(index / fps),
      })) || [],
    [fps, isSited, selectedAngles, selectedMethod, selectedAngles3D],
  );

  const handleUseSelectedFrame = () => {
    if (frameIndex) {
      if (selectedMethod === "REBA") {
        const reba = getRebaResult(
          extractRebaData(
            selectedAngles[frameIndex],
            getRebaQuestions(),
            selectedAngles3D?.[frameIndex],
          ),
        );
        setAssessmentDataDto({ reba });
      } else if (selectedMethod === "RULA") {
        const rula = getRulaResult(
          extractRulaData(
            selectedAngles[frameIndex],
            getRulaQuestions(),
            isSited,
            selectedAngles3D?.[frameIndex],
          ),
        );
        setAssessmentDataDto({ rula });
      }
      setAssessmentPageIndex(2);
      setTargetId(pid);
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        pt: 2,
        px: 2,
        pb: 1,
        borderRadius: { xs: 0, md: 1 },
      }}
    >
      {assessmentPageIndex === 1 ? (
        <Stack spacing={3}>
          <Typography variant="h5">Please Select Assessment Frame</Typography>
          <Box sx={{ height: "300px", mt: 3 }}>
            <AssessmentsChart
              assessmentAlgorithm={selectedMethod || "REBA"}
              data={chartData}
            />
          </Box>
          <Stack justifyContent="end" direction="row">
            <Button
              disabled={!frameIndex}
              variant="contained"
              onClick={handleUseSelectedFrame}
            >
              Use Selected Frame
            </Button>
          </Stack>
        </Stack>
      ) : (
        <AssessmentPage fileName={fileName} havePose={havePose} />
      )}
    </Paper>
  );
};
