import { FC, useState, MouseEvent, ChangeEvent } from "react";
import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Template from "src/components/templates/Template";
import TasksTableToolbar from "src/components/organisms/tasks/toolbar/TasksTableToolbar";
import TasksTableHead from "src/components/organisms/tasks/TasksTableHead";
import TasksTableBody from "src/components/organisms/tasks/TasksTableBody";
import {
  MinimalCategoryRo,
  MinimalTaskRo,
  TagRo,
  useTasksControllerGetUserTasksWithPaginationQuery,
} from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";

export enum stateType {
  PROCESSING = "PROCESSING",
  PENDING = "PENDING",
  READY = "READY",
  ERROR = "ERROR",
}
export type tableOrderType = "asc" | "desc";

const EnhancedTable: FC = () => {
  const [order, setOrder] = useState<tableOrderType>("desc");
  const [orderBy, setOrderBy] = useState<keyof MinimalTaskRo>("createdAt");
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tag, setTag] = useState<TagRo>({ id: "all", name: "All" });
  const [category, setCategory] = useState<MinimalCategoryRo>({
    name: "All",
    id: "all",
  });
  const [search, setSearch] = useState<string>("");

  const theme = useTheme(),
    isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const { data, isLoading, isFetching } =
    useTasksControllerGetUserTasksWithPaginationQuery(
      {
        page: page + 1,
        limit: rowsPerPage,
        search,
        category: category.id,
        tag: tag.id,
        orderBy,
        order,
      },
      {
        pollingInterval: 20000,
      },
    );

  const rows = data?.tasks || [];

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof MinimalTaskRo,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      const newSelecteds = rows.map(({ id }) => id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  return (
    <Template>
      {isLoading && <Loading />}
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: { xs: 0, md: 1 },
        }}
      >
        <TasksTableToolbar
          selectedRows={selected}
          setSelectedRows={setSelected}
          rows={rows}
          category={category}
          setCategory={setCategory}
          tag={tag}
          setTag={setTag}
          search={search}
          setSearch={setSearch}
          isFetching={isFetching}
        />
        <TableContainer>
          <Table>
            <TasksTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rows={rows}
            />
            <TasksTableBody
              rows={rows}
              selected={selected}
              setSelected={setSelected}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.meta?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Template>
  );
};

export default EnhancedTable;
