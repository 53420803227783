import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { logoutAction } from "../slice/authSlice";
import { RootStateType } from "../store";

export const getBaseUrl = () => {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_URL_V1
    : process.env.REACT_APP_PRODUCTION_URL_V1;
};

const createHeaders = (
  authToken: string | undefined,
  customHeaders?: AxiosRequestConfig["headers"],
) => {
  const headers = customHeaders ? { ...customHeaders } : {};
  if (authToken) {
    headers.authorization = `Bearer ${authToken}`;
  }
  return headers;
};

const handleErrorResponse = (e: AxiosError, dispatch: any) => {
  const error = {
    status: e.response?.status,
    data: (e.response?.data || e.message) as any,
  };

  console.log("rtk query error handler => ", error);

  if (error.data === "canceled") {
    return { error };
  }

  if (error.status === 401) {
    dispatch(logoutAction());
  } else if (error.status !== 404) {
    toast.error(
      error.data?.message
        ? error.status
          ? `${error.data.message} (status code: ${error.status})`
          : error.data.message
        : "Something went wrong, please try again later.",
    );
  }

  return { error };
};

export const baseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig["method"];
    body?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosRequestConfig["headers"];
    abortController?: AbortController;
    onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
  },
  unknown,
  unknown
> = async (
  {
    url,
    method = "GET",
    body,
    params,
    headers,
    abortController,
    onUploadProgress,
  },
  { getState, dispatch },
) => {
  const { auth } = getState() as RootStateType;
  const baseUrl = getBaseUrl();

  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      data: body,
      params,
      headers: createHeaders(auth?.token, headers),
      signal: abortController?.signal,
      onUploadProgress,
    });

    return { data: result.data };
  } catch (error) {
    return handleErrorResponse(error as AxiosError, dispatch);
  }
};
