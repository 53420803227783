import { FC, useCallback } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { grey } from "@mui/material/colors";
import { toast } from "react-toastify";
import theme from "src/configs/theme";
import uploadSvg from "src/assets/icons/upload_file.svg";

const supportedDuration = 5; // in minutes
const supportedSize = 400; // in MB

const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    let errorCount = 0;
    const URL = window.URL || window.webkitURL;

    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.onerror = () => {
      errorCount++;
      if (errorCount === 2) reject(new Error("Error loading video metadata"));
    };

    video.src = URL.createObjectURL(file);

    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result as string | undefined);
      media.onloadedmetadata = () => {
        resolve(media.duration);
      };
    };
    reader.onerror = () => {
      errorCount++;
      if (errorCount === 2) reject(new Error("Error reading file"));
    };

    reader.readAsDataURL(file);
  });
};

type DropzonePropsType = {
  dropzoneOptions?: DropzoneOptions;
  onAddFiles: (files: File[]) => void;
  dropzoneText?: string;
};

const Dropzone: FC<DropzonePropsType> = ({
  dropzoneOptions = {
    maxSize: supportedSize * 1024 * 1024,
    accept: {
      "video/*": [
        ".mp4",
        ".mkv",
        ".webm",
        ".ogg",
        ".avi",
        ".mov",
        ".wmv",
        ".m4v",
      ],
    },
    multiple: true,
  },
  onAddFiles,
  dropzoneText,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      handleFileRejections(fileRejections);

      const validFilesPromises = acceptedFiles.map((file) =>
        getVideoDuration(file).then((duration) => {
          if (Math.round(duration) >= 60 * supportedDuration + 10) {
            toast.error(
              `Selected file length is more than ${supportedDuration} minutes`,
            );
            return null;
          } else {
            return file;
          }
        }),
      );

      Promise.all(validFilesPromises).then((validFiles) => {
        const nonNullFiles = validFiles.filter(
          (file) => file !== null,
        ) as File[];
        onAddFiles(nonNullFiles);
      });
    },
    [onAddFiles],
  );

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          toast.error(`Error: File is larger than ${supportedSize} MB`);
        }
        if (err.code === "file-invalid-type") {
          toast.error(`Error: ${err.message}`);
        }
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px dashed rgba(0, 0, 0, 0.5)",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(4, 2),
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Stack justifyContent="center" alignItems="center" direction="column">
        <img src={uploadSvg} alt="upload_dropzone" />
        <Typography
          sx={{ color: grey[400] }}
          fontWeight="bold"
          align="center"
          lineHeight="2rem"
        >
          {dropzoneText ||
            "Drag and Drop video files here or click to select video files"}
        </Typography>
        <br />
        <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
          {`* Supported file duration is less than ${supportedDuration} minute${
            supportedDuration > 1 ? "s" : ""
          }`}
        </Typography>
        <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
          {`* Supported file size is less than ${supportedSize} MB`}
        </Typography>
        <Typography fontWeight="bold" sx={{ color: grey[400] }} align="center">
          * Supported file types: .mp4, .mkv, .webm, .ogg, .avi, .mov, .wmv,
          .m4v
        </Typography>
      </Stack>
    </Box>
  );
};

export default Dropzone;
