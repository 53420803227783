import { FC } from "react";
import Template from "src/components/templates/Template";
import { useTasksControllerGetTaskByIdForUserQuery } from "src/app/services/generatedApi";
import { Navigate, useParams } from "react-router";
import Loading from "src/components/atoms/Loading";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import { Result } from "src/components/organisms/result/Result";

const Task: FC = () => {
  let { id } = useParams() as { id: string };

  const {
    data: task,
    isLoading,
    isError,
  } = useTasksControllerGetTaskByIdForUserQuery({ id });

  if (isError) {
    toast.error("There is a problem, please contact support.");
    return <Navigate to="/results" />;
  }

  return (
    <Template>
      {isLoading && <Loading />}
      {task && (
        <Stack spacing={2}>
          <Result task={task} isCompare={false} />
        </Stack>
      )}
    </Template>
  );
};

export default Task;
