import { FC, MouseEvent, useMemo, useState } from "react";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Loading from "src/components/atoms/Loading";
import {
  UserCreateDto,
  useUsersControllerCreateNewUserMutation,
} from "src/app/services/generatedApi";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const userInitialInfo: UserCreateDto = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

type AddUserPropsType = {
  closeDialogHandler: () => any;
};

const AddUser: FC<AddUserPropsType> = ({ closeDialogHandler }) => {
  const [showPassword, setShowPassword] = useState(false),
    [userInfo, setUserInfo] = useState(userInitialInfo);

  const [createUser, { isLoading }] = useUsersControllerCreateNewUserMutation();

  const isEmpty = useMemo(() => {
    const { username, firstName, lastName, email, password } = userInfo;
    let result = true;
    if (username && firstName && lastName && email && password) {
      result = false;
    }
    return result;
  }, [userInfo]);

  const showPasswordHandler = () => setShowPassword((prevState) => !prevState),
    handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) =>
      event.preventDefault(),
    submitHandler = () => {
      if (isEmpty) return;
      createUser({ userCreateDto: userInfo })
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("New user successfully added");
            closeDialogHandler();
          }
        });
    };

  return (
    <>
      {isLoading && <Loading />}
      <DialogContent>
        <Typography align="center" variant="h6" sx={{ pb: 3 }}>
          Add new user
        </Typography>
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="First Name"
                value={userInfo.firstName}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    firstName: value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Last Name"
                value={userInfo.lastName}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    lastName: value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Username"
                value={userInfo.username}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    username: value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Email"
                type="email"
                value={userInfo.email}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({ ...prevState, email: value }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Password"
                value={userInfo.password}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={showPasswordHandler}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText="The password must be between 8 and 20 characters long and contain at least one uppercase, one lowercase, and one number."
                inputProps={{
                  autoComplete: "off",
                }}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    password: value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions
        sx={{ pr: { xs: 1, sm: 2, md: 4 }, pb: { xs: 1, sm: 2, md: 3 } }}
      >
        <Button
          sx={{ width: "100%", maxWidth: "150px" }}
          onClick={closeDialogHandler}
        >
          Cancel
        </Button>
        <Button
          onClick={submitHandler}
          disabled={isEmpty}
          sx={{ width: "100%", maxWidth: "150px" }}
          variant="contained"
          type="submit"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default AddUser;
