import { FC, Dispatch, SetStateAction } from "react";
import { alpha } from "@mui/material/styles";
import {
  useMediaQuery,
  Toolbar,
  Typography,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/system";
import {
  MinimalCategoryRo,
  MinimalTaskRo,
  TagRo,
} from "src/app/services/generatedApi";
import ToolbarActions from "./ToolbarActions";
import ToolbarFilters from "./ToolbarFilters";

type TasksTableToolbarPropsType = {
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  rows: MinimalTaskRo[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  category?: MinimalCategoryRo;
  setCategory?: Dispatch<SetStateAction<MinimalCategoryRo>>;
  tag?: TagRo;
  setTag?: Dispatch<SetStateAction<TagRo>>;
  isFetching: boolean;
};

const TasksTableToolbar: FC<TasksTableToolbarPropsType> = ({
  selectedRows,
  setSelectedRows,
  rows,
  search,
  category,
  setSearch,
  setCategory,
  tag,
  setTag,
  isFetching,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Toolbar
      sx={{
        pt: 3,
        pb: 3,
        ...(selectedRows.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        width="100%"
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Box sx={{ width: 20, height: 20 }}>
            {isFetching && <CircularProgress size={20} />}
          </Box>
          {selectedRows.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selectedRows.length} selected
            </Typography>
          ) : (
            isMd && (
              <Typography variant="h6" id="tableTitle" component="div">
                Videos
              </Typography>
            )
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          {selectedRows.length > 0 ? (
            <ToolbarActions
              selectedRows={selectedRows}
              rows={rows}
              setSelectedRows={setSelectedRows}
            />
          ) : (
            <ToolbarFilters
              search={search}
              category={category}
              setSearch={setSearch}
              setCategory={setCategory}
              tag={tag}
              setTag={setTag}
            />
          )}
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default TasksTableToolbar;
