import { Gender, CalcTaskModes } from "./constants";

const ln = Math.log;

export const formulas: {
  [g in Gender]: { [m in CalcTaskModes]: (...args: number[]) => number };
} = {
  [Gender.FEMALE]: {
    [CalcTaskModes.LIFT]: (H: number, VRM: number, DV: number, F: number) =>
      34.9 *
      (1.2602 - H / 0.7686) *
      (0.9877 + VRM / 13.69 - VRM ** 2 / 9.221) *
      (0.8199 - ln(DV) / 7.696) *
      (0.6767 - ln(F) / 12.59 - ln(F) ** 2 / 228.2),
    [CalcTaskModes.LOWER]: (H: number, VRM: number, DV: number, F: number) =>
      37.0 *
      (1.2602 - H / 0.7686) *
      (0.9877 + VRM / 13.69 - VRM ** 2 / 9.221) *
      (0.8199 - ln(DV) / 7.696) *
      (0.6767 - ln(F) / 12.59 - ln(F) ** 2 / 228.2),
    [CalcTaskModes.PUSH_INITIAL]: (V: number, DH: number, F: number) =>
      36.9 *
      (-0.5304 + V / 0.3361 - V ** 2 / 0.6915) *
      (1.0286 - DH / 72.22 + DH ** 2 / 9782) *
      (0.7251 - ln(F) / 13.19 - ln(F) ** 2 / 197.3),
    [CalcTaskModes.PULL_INITIAL]: (V: number, DH: number, F: number) =>
      36.9 *
      (-0.5304 + V / 0.3361 - V ** 2 / 0.6915) *
      (1.0286 - DH / 72.22 + DH ** 2 / 9782) *
      (0.7251 - ln(F) / 13.19 - ln(F) ** 2 / 197.3),
    [CalcTaskModes.PUSH_SUSTAINED]: (V: number, DH: number, F: number) =>
      25.5 *
      (-0.6539 + V / 0.2941 - V ** 2 / 0.5722) *
      (1.0391 - DH / 52.91 + DH ** 2 / 7975) *
      (0.6086 - ln(F) / 11.95 - ln(F) ** 2 / 304.4),
    [CalcTaskModes.PULL_SUSTAINED]: (V: number, DH: number, F: number) =>
      25.5 *
      (-0.6539 + V / 0.2941 - V ** 2 / 0.5722) *
      (1.0391 - DH / 52.91 + DH ** 2 / 7975) *
      (0.6086 - ln(F) / 11.95 - ln(F) ** 2 / 304.4),
    [CalcTaskModes.CARRY]: (V: number, DH: number, F: number) =>
      28.6 *
      (1.1645 - V / 4.437) *
      (1.0101 - DH / 207.8) *
      (0.6224 - ln(F) / 16.33),
  },
  [Gender.MALE]: {
    [CalcTaskModes.LIFT]: (H: number, VRM: number, DV: number, F: number) =>
      82.6 *
      (1.3532 - H / 0.7079) *
      (0.7746 + VRM / 1.912 - VRM ** 2 / 3.2962) *
      (0.8695 - ln(DV) / 10.62) *
      (0.6259 - ln(F) / 9.092 - ln(F) ** 2 / 125.0),
    [CalcTaskModes.LOWER]: (H: number, VRM: number, DV: number, F: number) =>
      95.9 *
      (1.3532 - H / 0.7079) *
      (0.7746 + VRM / 1.912 - VRM ** 2 / 3.2962) *
      (0.8695 - ln(DV) / 10.62) *
      (0.5773 - ln(F) / 10.8 - ln(F) ** 2 / 255.9),
    [CalcTaskModes.PUSH_INITIAL]: (V: number, DH: number, F: number) =>
      70.3 *
      (1.2737 - V / 1.335 + V ** 2 / 2.576) *
      (1.079 - ln(DH) / 9.392) *
      (0.6281 - ln(F) / 13.07 - ln(F) ** 2 / 379.5),
    [CalcTaskModes.PULL_INITIAL]: (V: number, DH: number, F: number) =>
      69.8 *
      (1.7186 - V / 0.6888 + V ** 2 / 2.025) *
      (1.079 - ln(DH) / 9.392) *
      (0.6281 - ln(F) / 13.07 - ln(F) ** 2 / 379.5),
    [CalcTaskModes.PUSH_SUSTAINED]: (V: number, DH: number, F: number) =>
      65.3 *
      (2.294 - V / 0.3345 + V ** 2 / 0.6887) *
      (1.1035 - ln(DH) / 7.17) *
      (0.4896 - ln(F) / 10.2 - ln(F) ** 2 / 403.9),
    [CalcTaskModes.PULL_SUSTAINED]: (V: number, DH: number, F: number) =>
      61.0 *
      (2.1977 - V / 0.385 + V ** 2 / 0.9047) *
      (1.1035 - ln(DH) / 7.17) *
      (0.4896 - ln(F) / 10.2 - ln(F) ** 2 / 403.9),
    [CalcTaskModes.CARRY]: (V: number, DH: number, F: number) =>
      74.9 *
      (1.5505 - V / 1.417) *
      (1.1172 - ln(DH) / 6.332) *
      (0.5149 - ln(F) / 7.958 - ln(F) ** 2 / 131.1),
  },
};
