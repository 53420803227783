import { FC, useContext } from "react";
import { Button, Grid, Paper, Stack } from "@mui/material";
import { TaskRo } from "src/app/services/generatedApi";
import { OverallChart } from "src/components/molecules/result/charts/OverallChart";
import { AllBodyPartsSection } from "src/components/molecules/result/AllBodyPartsSection";
import { BodyPartsSection } from "src/components/molecules/result/BodyPartSection";
import { VideoPlayerSection } from "./VideoPlayerSection";
import { HumanBodySection } from "./sections/HumanBodySection";
import { AssessmentSections } from "./sections/AssessmentSections";
import { NotesContent } from "src/components/molecules/assessment/note/NotesContent";
import { EditOutlined } from "@mui/icons-material";
import { TaskContext } from "src/context/TaskContext";

type MainContentProps = {
  task: TaskRo;
  isCompare: boolean;
  assessmentPageIndex: number;
  downloadCsvReport: () => void;
  openReportDialog: boolean;
  setOpenReportDialog: (open: boolean) => void;
  setAssessmentPageIndex: (index: number) => void;
};

export const MainContent: FC<MainContentProps> = ({
  task,
  isCompare,
  assessmentPageIndex,
  downloadCsvReport,
  openReportDialog,
  setOpenReportDialog,
  setAssessmentPageIndex,
}) => {
  const { setOpenNotes } = useContext(TaskContext);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <VideoPlayerSection
        task={task}
        assessmentPageIndex={assessmentPageIndex}
        downloadCsvReport={downloadCsvReport}
        openReportDialog={openReportDialog}
        setOpenReportDialog={setOpenReportDialog}
      />
      <HumanBodySection task={task} />
      {(isCompare || !assessmentPageIndex) && (
        <>
          <Grid item xs={12}>
            <BodyPartsSection />
          </Grid>
          <Grid item xs={12}>
            <OverallChart />
          </Grid>
          <Grid item xs={12}>
            <AllBodyPartsSection />
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <NotesContent task={task} />
              <Stack spacing={1} sx={{ mt: 2 }} alignItems="center">
                <Button
                  startIcon={<EditOutlined />}
                  onClick={() => setOpenNotes(true)}
                >
                  Edit
                </Button>
              </Stack>
            </Paper>
          </Grid>
        </>
      )}
      {!isCompare && (
        <AssessmentSections
          task={task}
          assessmentPageIndex={assessmentPageIndex}
          setAssessmentPageIndex={setAssessmentPageIndex}
        />
      )}
    </Grid>
  );
};
