import Konva from "konva";
import { calculateArea, getAveragePoint } from "src/app/logic/pose/geometryUtils";
import { SettingDto } from "src/app/services/generatedApi";
import { BodyPartState } from "src/context/TaskContext";
import { FramePose } from "src/types/pose";
import {
  drawingOrgans,
  handsLines,
  organs,
  organsKey,
} from "src/app/logic/pose/poseConstants";
import { stateToColor } from "../../humanBodySVG/constant";

const hideOrgan = (shapes: Konva.Shape[]) => {
  shapes[0].visible(false);
  shapes[1].visible(false);
  shapes[2].visible(false);
};

export const drawPersons = (
  framePose: FramePose,
  groups: Record<number, Konva.Group>,
  size: { width: number; height: number },
  calcAllPartState: (personId: number) => {
    [key in keyof SettingDto]?: BodyPartState;
  },
  selectedPersonId?: number,
) => {
  for (let id in groups) {
    groups[id].visible(false);
  }

  framePose.forEach((person) => {
    const group = groups[person.id];
    group.visible(true);

    const personArea = calculateArea(person.bbox) * size.width;
    const radius = Math.max(2, Math.min(7, Math.floor(personArea / 12)));

    const isHovered = (group as any).isHovered as boolean;
    const isSelected = selectedPersonId === person.id;

    if (isSelected) {
      (
        document.getElementById("3d-view-iframe") as HTMLIFrameElement
      )?.contentWindow?.postMessage(person.keypoints3d, "*");
    }

    const shapes = group.getChildren() as Konva.Shape[];

    const allStates = calcAllPartState(person.id);

    const drawOrgan = (
      points: { point: number[]; isVisible: boolean }[],
      currentShapes: Konva.Shape[],
      joint?: keyof SettingDto,
      isHand: boolean = false,
    ) => {
      if (!points[0].isVisible || !points[1].isVisible) {
        hideOrgan(currentShapes);
        return;
      }
      const state = (joint && allStates[joint]) || BodyPartState.NONE;
      if (isHand) {
        currentShapes[0].visible(false);
        currentShapes[1].visible(false);
      } else {
        currentShapes[0].visible(true);
        currentShapes[1].visible(true);
      }
      currentShapes[2].visible(true);

      for (let i = 0; i < 2; i++) {
        currentShapes[i].setAttrs({
          x: points[i].point[0] * size.width,
          y: points[i].point[1] * size.height,
          width: isSelected ? radius + 1 : radius,
          height: isSelected ? radius + 1 : radius,
          fill: isSelected ? "#33aaaa" : "#333",
          stroke: isSelected ? "#66ffff" : null,
          strokeWidth: isSelected ? 1 : 0,
        });
      }

      currentShapes[2].setAttrs({
        points: [
          points[0].point[0] * size.width,
          points[0].point[1] * size.height,
          points[1].point[0] * size.width,
          points[1].point[1] * size.height,
        ],
        strokeWidth: isHand ? 2 : isHovered || isSelected ? 5 : 3,
        stroke: stateToColor[state],
      });
    };

    const drawHand = (name: "left" | "right", currentIndex: number) => {
      handsLines[name === "left" ? 0 : 1].forEach((pointsIndex, index) => {
        const shapeIndex = currentIndex + index;
        const currentShapes = [
          shapes[3 * shapeIndex],
          shapes[3 * shapeIndex + 1],
          shapes[3 * shapeIndex + 2],
        ];
        const points = pointsIndex.map((pointsIndex) =>
          getAveragePoint(pointsIndex, person.keypoints),
        );
        drawOrgan(
          points,
          currentShapes,
          name === "left" ? "leftWrist" : "rightWrist",
          true,
        );
      });
    };

    let drawedHandsCount = 0;

    drawingOrgans
      .map((drawingOrgan, index) => {
        const points = organs[drawingOrgan].points.map((pointsIndex) =>
          getAveragePoint(pointsIndex, person.keypoints.body),
        );
        return { index, points };
      })
      .sort((i1: any, i2: any) => i1.points[0].point[2] - i2.points[0].point[2])
      .forEach(({ index, points }, zIndex) => {
        const currentIndex = zIndex + drawedHandsCount * handsLines[0].length;
        const drawingOrgan = drawingOrgans[index];
        const joint = organs[drawingOrgan].joint;
        const currentShapes = [
          shapes[3 * currentIndex],
          shapes[3 * currentIndex + 1],
          shapes[3 * currentIndex + 2],
        ];

        drawOrgan(points, currentShapes, joint);

        if (drawingOrgan === organsKey.right_lower_arm) {
          drawHand("right", currentIndex + 1);
          drawedHandsCount++;
        }
        if (drawingOrgan === organsKey.left_lower_arm) {
          drawHand("left", currentIndex + 1);
          drawedHandsCount++;
        }
      });
  });
};
