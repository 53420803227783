import { FC, Dispatch, useCallback, SetStateAction } from "react";
import { Grid, Typography } from "@mui/material";
import Slider, { SliderProps } from "@mui/material/Slider";
import { alpha, styled } from "@mui/material/styles";
import { red, green, yellow, blue, grey } from "@mui/material/colors";
import { SettingDto, ThresholdDto } from "src/app/services/generatedApi";

export enum settingsNamesEnum {
  neck = "Neck",
  back = "Back",
  knee = "Knee",
  rightKnee = "Right knee",
  leftKnee = "Left knee",
  upperArm = "Upper arm",
  rightUpperArm = "Right upper arm",
  leftUpperArm = "Left upper arm",
  lowerArm = "Lower arm",
  rightLowerArm = "Right lower arm",
  leftLowerArm = "Left lower arm",
  wrist = "Wrist",
  rightWrist = "Right wrist",
  leftWrist = "Left wrist",
}

const valuetext = (value: number) => {
  return `${value}°`;
};

const CustomSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  "&.MuiSlider-root": {
    height: "7px",
  },
  "& .MuiSlider-rail": {
    backgroundColor: red[200],
  },
  "& .MuiSlider-thumb": {
    width: "15px",
    height: "15px",
    border: "1px solid " + grey[500],
    backgroundColor: grey[300],
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(blue[300], 0.16)}`,
    },
    "&.Mui-active": {
      boxShadow: `0px 0px 0px 14px ${alpha(blue[300], 0.16)}`,
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-markLabel": {
    fontSize: "14px",
  },
}));

type marksFuncType = {
  spaceBetween: number;
  start: number;
  end: number;
};

const marks = ({ spaceBetween, start, end }: marksFuncType) => {
  const result = [];
  for (let i = start; i <= end; i += spaceBetween) {
    result.push({
      value: i,
      label: `${i}°`,
    });
  }
  return result;
};

type CategorySettingsPropsType = {
  value: SettingDto;
  onChange?: Dispatch<SetStateAction<SettingDto>>;
};

const CategorySettings: FC<CategorySettingsPropsType> = ({
  value,
  onChange,
}) => {
  const handleChange = (newValue: number | number[], itemName: string) => {
    if (!Array.isArray(newValue) || !onChange) return;
    const result: ThresholdDto = {
      threshold1: newValue[0],
      threshold2: newValue[1],
      threshold3: newValue[2],
      threshold4: newValue[3],
    };
    const name = Object.keys(value).find((item) => item === itemName);

    onChange((prevState) => ({ ...prevState, [name as string]: result }));
  };

  const minMax = useCallback(
    (itemName: keyof SettingDto) => {
      const { threshold1, threshold2, threshold3, threshold4 } =
        value[itemName];

      return {
        min: Math.min(threshold1, threshold2, threshold3, threshold4),
        max: Math.max(threshold1, threshold2, threshold3, threshold4),
      };
    },
    [value],
  );

  const angleToPercentage = ({
    number,
    itemName,
  }: {
    number: number;
    itemName: keyof SettingDto;
  }) =>
    ((number - minMax(itemName).min) /
      (minMax(itemName).max - minMax(itemName).min)) *
    100;

  return (
    <Grid container>
      {Object.keys(value).map((item, index) => {
        let start = 0,
          end = 180,
          spaceBetween = 30;

        if (item === "neck") {
          start = -90;
          end = 90;
        }
        const { threshold2, threshold3 } = value[item as keyof SettingDto];

        const point2 = angleToPercentage({
          number: threshold2,
          itemName: item as keyof SettingDto,
        });
        const point3 = angleToPercentage({
          number: threshold3,
          itemName: item as keyof SettingDto,
        });
        return (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              py: 0.5,
              px: { xs: 0, sm: 2, md: 3, lg: 4 },
            }}
            key={index}
          >
            <Typography fontWeight={500} fontSize={16}>
              {settingsNamesEnum[item as keyof typeof settingsNamesEnum]}
            </Typography>
            <CustomSlider
              sx={{
                "& .MuiSlider-track": {
                  background: `linear-gradient(90deg,
                    ${yellow[600]} ${point2}%,
                    ${green[500]} ${point2}%,
                    ${green[500]} ${point3}%,
                    ${yellow[600]} ${point3}%,
                    ${yellow[600]} 100%)`,
                },
              }}
              track="inverted"
              getAriaLabel={() => "Temperature range"}
              value={Object.values((value as any)[item as any])}
              onChange={(e, newValue) => handleChange(newValue, item)}
              valueLabelDisplay="auto"
              min={start}
              max={end}
              getAriaValueText={valuetext}
              marks={marks({
                start,
                end,
                spaceBetween,
              })}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CategorySettings;
