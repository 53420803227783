import { SettingDto } from "src/app/services/generatedApi";

export enum BasePoseLandmark {
  NOSE = 0,
  LEFT_EYE_INNER = 1,
  LEFT_EYE = 2,
  LEFT_EYE_OUTER = 3,
  RIGHT_EYE_INNER = 4,
  RIGHT_EYE = 5,
  RIGHT_EYE_OUTER = 6,
  LEFT_EAR = 7,
  RIGHT_EAR = 8,
  MOUTH_LEFT = 9,
  MOUTH_RIGHT = 10,
  LEFT_SHOULDER = 11,
  RIGHT_SHOULDER = 12,
  LEFT_ELBOW = 13,
  RIGHT_ELBOW = 14,
  LEFT_WRIST = 15,
  RIGHT_WRIST = 16,
  LEFT_PINKY = 17,
  RIGHT_PINKY = 18,
  LEFT_INDEX = 19,
  RIGHT_INDEX = 20,
  LEFT_THUMB = 21,
  RIGHT_THUMB = 22,
  LEFT_HIP = 23,
  RIGHT_HIP = 24,
  LEFT_KNEE = 25,
  RIGHT_KNEE = 26,
  LEFT_ANKLE = 27,
  RIGHT_ANKLE = 28,
  LEFT_HEEL = 29,
  RIGHT_HEEL = 30,
  LEFT_FOOT_INDEX = 31,
  RIGHT_FOOT_INDEX = 32,
  HEAD_TOP = 33,
  THORAX = 34,
}

export enum HM36PoseLandmark {
  PELVIS = 0,
  RIGHT_HIP = 1,
  RIGHT_KNEE = 2,
  RIGHT_FOOT = 3,
  LEFT_HIP = 4,
  LEFT_KNEE = 5,
  LEFT_FOOT = 6,
  SPINE = 7,
  THORAX = 8,
  HEAD = 9,
  HEAD_TOP = 10,
  LEFT_SHOULDER = 11,
  LEFT_ELBOW = 12,
  LEFT_WRIST = 13,
  RIGHT_SHOULDER = 14,
  RIGHT_ELBOW = 15,
  RIGHT_WRIST = 16,
}

export enum organsKey {
  left_shank = "left_shank",
  right_shank = "right_shank",
  left_leg = "left_leg",
  right_leg = "right_leg",
  left_upper_arm = "left_upper_arm",
  right_upper_arm = "right_upper_arm",
  left_lower_arm = "left_lower_arm",
  right_lower_arm = "right_lower_arm",
  back = "back",
  neck = "neck",
  head = "head",
  cool = "cool",
  pelvis = "pelvis",
}

export const drawingOrgans: organsKey[] = [
  organsKey.left_shank,
  organsKey.left_leg,
  organsKey.left_upper_arm,
  organsKey.left_lower_arm,
  organsKey.right_shank,
  organsKey.right_leg,
  organsKey.right_upper_arm,
  organsKey.right_lower_arm,
  organsKey.back,
  organsKey.neck,
  organsKey.head,
  organsKey.cool,
  organsKey.pelvis,
];

export const handsLines: number[][][] = [[], []];
for (let i = 0; i < 2; i++) {
  for (let j = 0; j < 5; j++) {
    handsLines[i].push([i * 21 + j * 4 + 1 + 1000, i * 21 + 1000]);
    for (let k = 0; k < 3; k++) {
      handsLines[i].push([
        i * 21 + j * 4 + k + 1 + 1000,
        i * 21 + j * 4 + k + 2 + 1000,
      ]);
    }
  }
}

export const organs: Record<
  organsKey,
  { points: any[]; joint?: keyof SettingDto }
> = {
  left_shank: {
    points: [BasePoseLandmark.LEFT_KNEE, BasePoseLandmark.LEFT_ANKLE],
    joint: "leftKnee",
  },
  right_shank: {
    points: [BasePoseLandmark.RIGHT_KNEE, BasePoseLandmark.RIGHT_ANKLE],
    joint: "rightKnee",
  },
  left_leg: {
    points: [BasePoseLandmark.LEFT_HIP, BasePoseLandmark.LEFT_KNEE],
    joint: "leftKnee",
  },
  right_leg: {
    points: [BasePoseLandmark.RIGHT_HIP, BasePoseLandmark.RIGHT_KNEE],
    joint: "rightKnee",
  },
  left_upper_arm: {
    points: [BasePoseLandmark.LEFT_SHOULDER, BasePoseLandmark.LEFT_ELBOW],
    joint: "leftUpperArm",
  },
  right_upper_arm: {
    points: [BasePoseLandmark.RIGHT_SHOULDER, BasePoseLandmark.RIGHT_ELBOW],
    joint: "rightUpperArm",
  },
  left_lower_arm: {
    points: [BasePoseLandmark.LEFT_ELBOW, BasePoseLandmark.LEFT_WRIST],
    joint: "leftLowerArm",
  },
  right_lower_arm: {
    points: [BasePoseLandmark.RIGHT_ELBOW, BasePoseLandmark.RIGHT_WRIST],
    joint: "rightLowerArm",
  },
  back: {
    points: [
      [BasePoseLandmark.LEFT_SHOULDER, BasePoseLandmark.RIGHT_SHOULDER],
      [BasePoseLandmark.LEFT_HIP, BasePoseLandmark.RIGHT_HIP],
    ],
    joint: "back",
  },
  neck: {
    points: [
      [BasePoseLandmark.LEFT_EAR, BasePoseLandmark.RIGHT_EAR],
      [BasePoseLandmark.LEFT_SHOULDER, BasePoseLandmark.RIGHT_SHOULDER],
    ],
    joint: "neck",
  },
  head: {
    points: [
      [
        BasePoseLandmark.NOSE,
        BasePoseLandmark.LEFT_EYE,
        BasePoseLandmark.RIGHT_EYE,
        BasePoseLandmark.LEFT_EAR,
        BasePoseLandmark.RIGHT_EAR,
      ],
      [BasePoseLandmark.LEFT_EAR, BasePoseLandmark.RIGHT_EAR],
    ],
    joint: "neck",
  },
  cool: {
    points: [BasePoseLandmark.LEFT_HIP, BasePoseLandmark.RIGHT_HIP],
  },
  pelvis: {
    points: [BasePoseLandmark.LEFT_SHOULDER, BasePoseLandmark.RIGHT_SHOULDER],
  },
};

export type JointValue = {
  points: { "3d"?: (number | number[])[]; "2d"?: (number | number[])[] };
  reverse?: boolean;
  have3DAngle?: boolean;
  factors?: [number, number];
};

export type AngleJoints = keyof SettingDto;

export const joints: Record<AngleJoints, JointValue> = {
  leftKnee: {
    points: {
      "3d": [
        HM36PoseLandmark.LEFT_HIP,
        HM36PoseLandmark.LEFT_KNEE,
        HM36PoseLandmark.LEFT_FOOT,
      ],
      "2d": [
        BasePoseLandmark.LEFT_HIP,
        BasePoseLandmark.LEFT_KNEE,
        BasePoseLandmark.LEFT_ANKLE,
      ],
    },
    reverse: true,
  },
  rightKnee: {
    points: {
      "3d": [
        HM36PoseLandmark.RIGHT_HIP,
        HM36PoseLandmark.RIGHT_KNEE,
        HM36PoseLandmark.RIGHT_FOOT,
      ],
      "2d": [
        BasePoseLandmark.RIGHT_HIP,
        BasePoseLandmark.RIGHT_KNEE,
        BasePoseLandmark.RIGHT_ANKLE,
      ],
    },
    reverse: true,
  },
  leftUpperArm: {
    points: {
      "3d": [
        HM36PoseLandmark.LEFT_HIP,
        HM36PoseLandmark.LEFT_SHOULDER,
        HM36PoseLandmark.LEFT_ELBOW,
      ],
      "2d": [
        BasePoseLandmark.LEFT_HIP,
        BasePoseLandmark.LEFT_SHOULDER,
        BasePoseLandmark.LEFT_ELBOW,
      ],
    },
    have3DAngle: true,
  },
  rightUpperArm: {
    points: {
      "3d": [
        HM36PoseLandmark.RIGHT_HIP,
        HM36PoseLandmark.RIGHT_SHOULDER,
        HM36PoseLandmark.RIGHT_ELBOW,
      ],
      "2d": [
        BasePoseLandmark.RIGHT_HIP,
        BasePoseLandmark.RIGHT_SHOULDER,
        BasePoseLandmark.RIGHT_ELBOW,
      ],
    },
    have3DAngle: true,
  },

  leftLowerArm: {
    points: {
      "3d": [
        HM36PoseLandmark.LEFT_SHOULDER,
        HM36PoseLandmark.LEFT_ELBOW,
        HM36PoseLandmark.LEFT_WRIST,
      ],
      "2d": [
        BasePoseLandmark.LEFT_SHOULDER,
        BasePoseLandmark.LEFT_ELBOW,
        BasePoseLandmark.LEFT_WRIST,
      ],
    },
    reverse: true,
  },
  rightLowerArm: {
    points: {
      "3d": [
        HM36PoseLandmark.RIGHT_SHOULDER,
        HM36PoseLandmark.RIGHT_ELBOW,
        HM36PoseLandmark.RIGHT_WRIST,
      ],
      "2d": [
        BasePoseLandmark.RIGHT_SHOULDER,
        BasePoseLandmark.RIGHT_ELBOW,
        BasePoseLandmark.RIGHT_WRIST,
      ],
    },
    reverse: true,
  },
  back: {
    points: {
      "3d": [
        [HM36PoseLandmark.LEFT_SHOULDER, HM36PoseLandmark.RIGHT_SHOULDER],
        [HM36PoseLandmark.LEFT_HIP, HM36PoseLandmark.RIGHT_HIP],
        [HM36PoseLandmark.LEFT_KNEE, HM36PoseLandmark.RIGHT_KNEE],
      ],
      "2d": [
        [BasePoseLandmark.LEFT_SHOULDER, BasePoseLandmark.RIGHT_SHOULDER],
        [BasePoseLandmark.LEFT_HIP, BasePoseLandmark.RIGHT_HIP],
        [BasePoseLandmark.LEFT_KNEE, BasePoseLandmark.RIGHT_KNEE],
      ],
    },
    have3DAngle: true,
    reverse: true,
    factors: [0, 10],
  },

  neck: {
    points: {
      "3d": [
        [HM36PoseLandmark.LEFT_HIP, HM36PoseLandmark.RIGHT_HIP],
        HM36PoseLandmark.THORAX,
        HM36PoseLandmark.HEAD,
      ],
      "2d": [
        [BasePoseLandmark.LEFT_HIP, BasePoseLandmark.RIGHT_HIP],
        [BasePoseLandmark.LEFT_SHOULDER, BasePoseLandmark.RIGHT_SHOULDER],
        [BasePoseLandmark.LEFT_EAR, BasePoseLandmark.RIGHT_EAR],
      ],
    },
    reverse: true,
    factors: [3, 7],
    have3DAngle: true,
  },
  rightWrist: {
    points: {
      "2d": [[1026, 1030, 1034], 1021, BasePoseLandmark.RIGHT_ELBOW],
    },
    reverse: true,
    factors: [10, 0],
  },
  leftWrist: {
    points: {
      "2d": [[1005, 1009, 1013], 1000, BasePoseLandmark.LEFT_ELBOW],
    },
    reverse: true,
    factors: [10, 0],
  },
};

export const settingKeys: (keyof SettingDto)[] = [
  "neck",
  "back",
  "leftUpperArm",
  "rightUpperArm",
  "leftLowerArm",
  "rightLowerArm",
  "leftWrist",
  "rightWrist",
  "leftKnee",
  "rightKnee",
];
