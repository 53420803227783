import {
  Box,
  Grid,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { green, grey, orange, red } from "@mui/material/colors";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import { useContext, useMemo } from "react";
import { BodyPartState, TaskContext } from "src/context/TaskContext";
import { settingsNamesEnum } from "src/components/organisms/category/CategorySettings";
import {
  Bar,
  BarChart,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import StackBarChartTooltip from "src/components/organisms/charts/stackBarChart/StackBarChartTooltip";

const renderCustomizedLabel = ({ content, value, ...rest }: any) => (
  <Label
    {...rest}
    fontSize="14"
    fill="#FFFFFF"
    fontWeight="Bold"
    value={value > 4 ? `${value}%` : ""}
  />
);

const legendFormatter = (value: string) => {
  return value === "None" ? "No Data" : value;
};

export const OverallChart = () => {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const { calcSelectedPartState, calcSelectedPartStateCount } =
    useContext(TaskContext);

  const data = useMemo(
    () =>
      settingKeys.map((item) => {
        const counts = calcSelectedPartStateCount(item);
        const sum =
          counts[BodyPartState.SAFE] +
          counts[BodyPartState.CAUTION] +
          counts[BodyPartState.HAZARD] +
          counts[BodyPartState.NONE];
        return {
          name: settingsNamesEnum[item],
          Safe: Math.round((counts[BodyPartState.SAFE] / sum) * 1000) / 10,
          Caution:
            Math.round((counts[BodyPartState.CAUTION] / sum) * 1000) / 10,
          Hazard: Math.round((counts[BodyPartState.HAZARD] / sum) * 1000) / 10,
          None: Math.round((counts[BodyPartState.NONE] / sum) * 1000) / 10,
        };
      }),
    [calcSelectedPartStateCount],
  );

  return (
    <Paper
      elevation={isMd ? undefined : 0}
      sx={{
        width: "100%",
        p: { xs: 2, md: 3 },
        borderRadius: { xs: 0, md: 1 },
      }}
    >
      <Typography variant="h6" align="center" color="secondary">
        Overall chart
      </Typography>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ mt: 4, mb: 6 }}
          justifyContent="center"
          id="body-parts-overall-items"
        >
          {settingKeys.map((item, index) => {
            const state = calcSelectedPartState(item);
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={1}
                sx={{ minWidth: "120px" }}
                key={index}
              >
                <Typography fontSize={13} fontWeight={500} align="center">
                  {settingsNamesEnum[item]}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight="500"
                  align="center"
                  color="white"
                  sx={{
                    backgroundColor:
                      state === BodyPartState.SAFE
                        ? green[300]
                        : state === BodyPartState.HAZARD
                          ? red[300]
                          : state === BodyPartState.CAUTION
                            ? orange[400]
                            : grey[400],
                    pt: 1,
                    pb: 1,
                    borderRadius: "5px",
                  }}
                >
                  {state === BodyPartState.SAFE
                    ? "SAFE"
                    : state === BodyPartState.HAZARD
                      ? "HAZARD"
                      : state === BodyPartState.CAUTION
                        ? "CAUTION"
                        : "Limited Data"}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Box
          id="body-parts-overall"
          sx={{
            height: "500px",
            pb: 5,
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart layout="vertical" data={data} stackOffset="expand">
              <XAxis hide type="number" label="percent" unit="%" />
              <YAxis
                type="category"
                dataKey="name"
                stroke="#000"
                fontWeight="bold"
                fontSize="14"
                width={130}
              />
              <Tooltip content={<StackBarChartTooltip />} />
              <Legend
                wrapperStyle={{ position: "relative" }}
                formatter={legendFormatter}
              />

              <Bar dataKey="Safe" fill={green[300]} stackId="a">
                <LabelList
                  dataKey="Safe"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="Caution" fill={orange[300]} stackId="a">
                <LabelList
                  dataKey="Caution"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="Hazard" fill={red[300]} stackId="a">
                <LabelList
                  dataKey="Hazard"
                  position="center"
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey="None" fill={grey[300]} stackId="a" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Paper>
  );
};
