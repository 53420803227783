import jsPDF from "jspdf";

import {
  drawAssessmentListItems,
  drawBodyPartsOverallChart,
  drawBodyPartsOverallItems,
  drawCompareBodyPartsChart,
  drawFirstPageImage,
  drawFooterLogo,
  drawHumanBodyItem,
  drawLeftLogo,
  drawRightLogo,
  drawSelectedFrame,
  loadFonts,
  writeParticipantInformation,
  writeTitle,
  writeSubTitle,
  drawObservationAndRecommendation,
} from "./jsPdfHelper";

import moment from "moment";
import { TaskRo } from "src/app/services/generatedApi";
import { ReportSection } from "./constants";

export const generateReport = async (
  participantInformation: Record<string, string>,
  neededSections: ReportSection[],
  task: TaskRo,
  setGeneratingReport: (progress: number) => void,
) => {
  const pdf = new jsPDF({ format: "letter", compress: true });
  setGeneratingReport(1);
  loadFonts(pdf);
  drawLeftLogo(pdf);
  drawRightLogo(pdf);
  writeTitle(pdf);
  writeSubTitle(pdf);
  drawFirstPageImage(pdf);
  setGeneratingReport(5);
  writeParticipantInformation(
    pdf,
    neededSections.includes(ReportSection.ASSESSMENT_INFORMATION)
      ? participantInformation
      : {},
    moment(task.createdAt).format("DD MMM YYYY"),
  );

  if (neededSections.includes(ReportSection.ANALYSIS_SNAPSHOT_AND_BODY_MAP)) {
    pdf.addPage();

    setGeneratingReport(10);
    await drawSelectedFrame(pdf);
    setGeneratingReport(30);
    await drawHumanBodyItem(pdf);
    setGeneratingReport(40);

    drawRightLogo(pdf);
    drawFooterLogo(pdf);
  }

  if (
    neededSections.includes(ReportSection.BODY_JOINT_ANGLES_AND_RISK_LEVELS)
  ) {
    pdf.addPage();
    setGeneratingReport(50);
    await drawBodyPartsOverallItems(pdf);
    setGeneratingReport(60);
    await drawBodyPartsOverallChart(pdf);
    setGeneratingReport(70);
    await drawCompareBodyPartsChart(pdf);
    setGeneratingReport(80);

    drawRightLogo(pdf);
    drawFooterLogo(pdf);
  }

  if (
    neededSections.includes(ReportSection.ASSESSMENTS) &&
    document.querySelectorAll("#assessment-items .MuiGrid-item").length
  ) {
    pdf.addPage();
    setGeneratingReport(90);
    await drawAssessmentListItems(pdf);

    drawRightLogo(pdf);
    drawFooterLogo(pdf);
  }

  if (
    neededSections.includes(ReportSection.OBSERVATION_AND_RECOMMENDATION) &&
    (task.observation || task.recommendation)
  ) {
    pdf.addPage();
    setGeneratingReport(95);
    await drawObservationAndRecommendation(
      pdf,
      task.observation,
      task.recommendation,
    );

    drawRightLogo(pdf);
    drawFooterLogo(pdf);
  }

  setGeneratingReport(95);
  pdf.save("Report.pdf");
  setGeneratingReport(100);
};
