import { FullKeypoints, Keypoints } from "src/types/pose";

const getPoint = (
  pointIndex: number,
  keypoints: Keypoints | FullKeypoints,
  mode = "2d",
): { point: number[]; isVisible: boolean } => {
  const point = Array.isArray(keypoints)
    ? keypoints[pointIndex]
    : pointIndex >= 1000
      ? keypoints.hands[pointIndex - 1000]
      : keypoints.body[pointIndex];
  return {
    point,
    isVisible:
      point[mode === "3d" ? 3 : 2] > (pointIndex >= 1000 ? 0.65 : 0.35),
  };
};

export const getAveragePoint = (
  pointsIndex: number | number[],
  keypoints: Keypoints | FullKeypoints,
  mode = "2d",
): { point: number[]; isVisible: boolean } => {
  if (typeof pointsIndex === "number") {
    return getPoint(pointsIndex, keypoints, mode);
  }
  const point = Array.from({ length: mode === "3d" ? 4 : 3 }, () => 0);
  let isVisible = true;
  pointsIndex.forEach((index) => {
    const { point: currPoint, isVisible: currIsVisible } = getPoint(
      index,
      keypoints,
      mode,
    );
    for (let i = 0; i < point.length; i++) {
      point[i] += currPoint[i];
    }
    isVisible = isVisible || currIsVisible;
  });
  for (let i = 0; i < point.length; i++) {
    point[i] /= pointsIndex.length;
  }
  if (point[mode === "3d" ? 3 : 2] < 0.4) {
    isVisible = false;
  }
  return { point, isVisible };
};

export const calculateArea = (bbox: number[]): number => {
  return (bbox[2] - bbox[0]) * (bbox[3] - bbox[1]);
};

export const subtractVectors = (
  v1: number[],
  v2: number[],
  mode: "2d" | "3d" = "3d",
): number[] => {
  if (mode === "2d") {
    return [v1[0] - v2[0], v1[1] - v2[1], 0];
  }
  return v1.map((val, idx) => val - v2[idx]);
};

export const addVectors = (
  v1: number[],
  v2: number[],
  mode: "2d" | "3d" = "3d",
): number[] => {
  if (mode === "2d") {
    return [v1[0] + v2[0], v1[1] + v2[1], 0];
  }
  return v1.map((val, idx) => val + v2[idx]);
};

export const crossProduct = (v1: number[], v2: number[]): number[] => [
  v1[1] * v2[2] - v1[2] * v2[1],
  v1[2] * v2[0] - v1[0] * v2[2],
  v1[0] * v2[1] - v1[1] * v2[0],
];

export const dotProduct = (v1: number[], v2: number[]): number =>
  v1.reduce((acc, val, idx) => acc + val * v2[idx], 0);

export const vectorLength = (vector: number[]): number =>
  Math.sqrt(vector.reduce((acc, val) => acc + val ** 2, 0));

export const normalizeVector = (vector: number[]): number[] => {
  const length = vectorLength(vector);
  return vector.map((val) => val / length);
};

export const DEGREE_CONVERSION_FACTOR = 180 / Math.PI;

export const calculateAngleBetweenVectors = (
  v1: number[],
  v2: number[],
): number => {
  const angle = Math.acos(
    dotProduct(v1, v2) / (vectorLength(v1) * vectorLength(v2)),
  );
  return isNaN(angle) ? 0 : angle * DEGREE_CONVERSION_FACTOR;
};

export const calculateAngleBetween3Points = (
  points: number[][],
  mode: "2d" | "3d",
): number => {
  const [p1, p2, p3] = points;
  const v1 = subtractVectors(p1, p2, mode);
  const v2 = subtractVectors(p3, p2, mode);
  return calculateAngleBetweenVectors(v1, v2);
};

export const multiplyVector = (vector: number[], scalar: number): number[] =>
  vector.map((component) => component * scalar);

export const calculateAngleBetweenVectorAndPlane = (
  points: number[][],
  n: number[],
): number => {
  const v = subtractVectors(points[2], points[1], "3d");
  const vNorm = normalizeVector(v);
  const product = dotProduct(vNorm, n);
  const angleRad = Math.acos(product);
  const angleDeg = angleRad * DEGREE_CONVERSION_FACTOR;

  return angleDeg;
};

export const calculateAngleBetweenVectorsInPlane = (
  v1: number[],
  v2: number[],
  planeNorm: number[],
): number => {
  const projV1 = subtractVectors(
    v1,
    multiplyVector(planeNorm, dotProduct(v1, planeNorm)),
    "3d",
  );
  const projV2 = subtractVectors(
    v2,
    multiplyVector(planeNorm, dotProduct(v2, planeNorm)),
    "3d",
  );

  const angle = calculateAngleBetweenVectors(projV1, projV2);

  const crossProd = crossProduct(projV1, projV2);
  const sign = dotProduct(crossProd, planeNorm) >= 0 ? 1 : -1;

  return angle * sign;
};
