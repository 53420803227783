import { FC, useEffect, useState } from "react";
import {
  useMediaQuery,
  Paper,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import Loading from "src/components/atoms/Loading";
import {
  useCategoryControllerGetAllCategoriesQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  useUsersControllerCheckUserLimitReachedQuery,
} from "src/app/services/generatedApi";
import { AddVideoLimitRicked } from "src/components/organisms/addVideo/LimitRiched";
import Template from "src/components/templates/Template";
import { nameIdType } from "src/components/atoms/AutocompleteWithAdd";
import TaskInfoStep from "src/components/organisms/addVideo/TaskInfoStep";
import { UploadStep } from "src/components/organisms/addVideo/UploadStep";

const AddVideo: FC = () => {
  const { data: allCategories, isLoading: getCategoryLoading } =
    useCategoryControllerGetAllCategoriesQuery();
  const { data: allTags, isLoading: getTagsLoading } =
    useTasksControllerGetUserTagsForCurrentUserQuery();
  const { data: limitResult, isLoading: checkLimitLoading } =
    useUsersControllerCheckUserLimitReachedQuery(null as any, {
      refetchOnMountOrArgChange: 10,
    });

  const [taskName, setTaskName] = useState("");
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState<string | nameIdType | null>(null);
  const [description, setDescription] = useState("");
  const [blurFace, setBlurFace] = useState(false);
  const [blurBack, setBlurBack] = useState(false);
  const [multiTarget, setMultiTarget] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Task Info",
      description:
        "Enter file details, select category, tags, and set blurring options.",
    },
    { label: "Upload", description: "Upload your videos." },
  ];

  useEffect(() => {
    if (allCategories) {
      const defaultCategory = allCategories.categories.find(
        (cat) => cat.name === "Default" && cat.isSystemCategory,
      );
      if (defaultCategory) {
        setCategory(defaultCategory.id);
      }
    }
  }, [allCategories]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  if (getCategoryLoading || getTagsLoading || checkLimitLoading) {
    return (
      <Template>
        <Loading />
      </Template>
    );
  }

  if (limitResult?.reached) {
    return <AddVideoLimitRicked />;
  }

  const handleNext = () => {
    if (taskName) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleUploadCompleted = () => {
    setActiveStep(steps.length);
  };

  return (
    <Template>
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          p: { xs: 2, sm: 3, md: 4, lg: 5 },
          borderRadius: { xs: 0, md: 1 },
        }}
      >
        {allCategories && allCategories.categories.length < 1 ? (
          <Stack justifyContent="center" alignItems="center" rowGap={4}>
            <Typography align="center" variant="h6">
              It looks like you have not created any angle thresholds yet,
              please first create one and then get back to add new video.
            </Typography>
            <RouterLink
              to="/angleThresholds"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" color="primary">
                Create Angle thresholds
              </Button>
            </RouterLink>
          </Stack>
        ) : (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Typography mb={2}>{step.description}</Typography>
                  {index === 0 && (
                    <TaskInfoStep
                      taskName={taskName}
                      setTaskName={setTaskName}
                      category={category}
                      setCategory={setCategory}
                      tag={tag}
                      setTag={setTag}
                      description={description}
                      setDescription={setDescription}
                      blurFace={blurFace}
                      setBlurFace={setBlurFace}
                      blurBack={blurBack}
                      setBlurBack={setBlurBack}
                      multiTarget={multiTarget}
                      setMultiTarget={setMultiTarget}
                      allCategories={allCategories}
                      allTags={allTags}
                    />
                  )}
                  {index === 1 && (
                    <UploadStep
                      initialValues={{
                        taskName,
                        category,
                        tag,
                        description,
                        blurFace,
                        blurBack,
                        multiTarget,
                      }}
                      onCompleted={handleUploadCompleted}
                      handleBack={handleBack}
                    />
                  )}
                  {index < steps.length - 1 && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={!taskName}
                      >
                        Continue
                      </Button>
                      <Button disabled={index === 0} onClick={handleBack}>
                        Back
                      </Button>
                    </Stack>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        )}
      </Paper>
    </Template>
  );
};

export default AddVideo;
