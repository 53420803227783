import {
  EventRepeat as EventRepeatIcon,
  Scale as ScaleIcon,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { QuestionType } from "src/app/logic/assessment/reba-rula/types";
import {
  LiftLowerCarryLoadDto,
  LiftLowerTaskDataDto,
} from "src/app/services/generatedApi";
import { LiftIcon } from "src/components/atoms/Icons/LiftIcon";
import { SelectItem } from "src/components/atoms/SelectItem";
import {
  UnitModes,
  UnitValues,
} from "src/components/molecules/assessment/mmh/constants";
import { MMHFormField } from "src/components/molecules/assessment/mmh/FormField";
import { handCouplingSelectItems } from "./constants";

export const LiftLower: FC<{
  lift?: boolean;
  data: LiftLowerTaskDataDto;
  load: LiftLowerCarryLoadDto;
  onChangeData: (name: keyof LiftLowerTaskDataDto, value: any) => void;
  onChangeLoad: (name: keyof LiftLowerCarryLoadDto, value: any) => void;
  onChangeError: (
    name: string,
  ) => (errorState: { men: boolean; women: boolean }) => void;
}> = ({
  lift = false,
  data,
  load,
  onChangeData,
  onChangeLoad,
  onChangeError,
}) => {
  return (
    <>
      <Stack width="100%">
        <Typography gutterBottom>Hand Coupling:</Typography>
        <Stack direction="row" spacing={1} width="100%">
          {handCouplingSelectItems.map((handCouplingSelectItem) => (
            <SelectItem
              type={QuestionType.SELECT}
              key={handCouplingSelectItem.mode}
              text={handCouplingSelectItem.text}
              isSelected={data.handCoupling === handCouplingSelectItem.mode}
              onSelect={() =>
                onChangeData("handCoupling", handCouplingSelectItem.mode)
              }
            />
          ))}
        </Stack>
      </Stack>

      <MMHFormField
        preUnitText={lift ? "Lift" : "Lower"}
        label="Frequency"
        icon={<EventRepeatIcon />}
        unitMode={UnitModes.Frequency}
        min={1 / 1440}
        max={20}
        value={data.frequency}
        onChange={(value) => onChangeData("frequency", value)}
        setErrorState={onChangeError("frequency")}
      />
      <MMHFormField
        label="Load"
        icon={<ScaleIcon />}
        unitMode={UnitModes.Weight}
        textFieldProps={{
          inputProps: {
            step: 0.5,
          },
        }}
        value={load.objectWeight}
        onChange={(value) => onChangeLoad("objectWeight", value)}
        setErrorState={onChangeError("objectWeight")}
      />
      <MMHFormField
        label="Start Hand Height"
        icon={<LiftIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.INCH, UnitValues.CENTIMETER]}
        textFieldProps={{
          inputProps: {
            step: 0.1,
            ...(lift
              ? { min: 0, max: data.endHandHeight }
              : { min: data.endHandHeight }),
          },
        }}
        minF={lift ? 0 : 0.25}
        maxF={lift ? 1.71 : 1.96}
        minM={lift ? 0 : 0.25}
        maxM={lift ? 1.89 : 2.14}
        value={data.startHandHeight}
        onChange={(value) => onChangeData("startHandHeight", value)}
        setErrorState={onChangeError("startHandHeight")}
      />
      <MMHFormField
        label="End Hand Height"
        icon={<LiftIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.INCH, UnitValues.CENTIMETER]}
        textFieldProps={{
          inputProps: {
            step: 0.1,
            ...(lift
              ? { min: data.startHandHeight }
              : { min: 0, max: data.startHandHeight }),
          },
        }}
        minF={lift ? 0.25 : 0}
        maxF={lift ? 1.96 : 1.71}
        minM={lift ? 0.25 : 0}
        maxM={lift ? 2.14 : 1.89}
        value={data.endHandHeight}
        onChange={(value) => onChangeData("endHandHeight", value)}
        setErrorState={onChangeError("endHandHeight")}
      />
      <MMHFormField
        label="Start Hand Distance"
        icon={<LiftIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.INCH, UnitValues.CENTIMETER]}
        minF={0.2}
        maxF={0.68}
        minM={0.25}
        maxM={0.73}
        value={data.startHandDistance}
        onChange={(value) => onChangeData("startHandDistance", value)}
        setErrorState={onChangeError("startHandDistance")}
      />
      <MMHFormField
        label="End Hand Distance"
        icon={<LiftIcon />}
        unitMode={UnitModes.Distance}
        selectedUnitItems={[UnitValues.INCH, UnitValues.CENTIMETER]}
        minF={0.2}
        maxF={0.68}
        minM={0.25}
        maxM={0.73}
        value={data.endHandDistance}
        onChange={(value) => onChangeData("endHandDistance", value)}
        setErrorState={onChangeError("endHandDistance")}
      />
    </>
  );
};
