import {
  FC,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CategorySettings from "./CategorySettings";
import {
  CategoryRo,
  SettingDto,
  useCategoryControllerDeleteCategoryMutation,
  useCategoryControllerUpdateCategoryMutation,
} from "src/app/services/generatedApi";
import { useTheme } from "@mui/system";
import Loading from "src/components/atoms/Loading";
import { toast } from "react-toastify";
import { useAppSelector } from "src/app/hooks";
import { shallowEqual } from "react-redux";

type CategoryCardPropsType = {
  category: CategoryRo;
};

const CategoryCard: FC<CategoryCardPropsType> = ({
  category: { id, name, setting, isSystemCategory },
}) => {
  const [localSetting, setLocalSetting] = useState<SettingDto | null>(null);

  useEffect(() => {
    setLocalSetting(setting);
  }, [setting]);

  const isEqual = useMemo(
    () => shallowEqual(setting, localSetting),
    [localSetting, setting]
  );

  const { auth } = useAppSelector((state) => state);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const [deleteCategory, { isLoading: deleteLoading }] =
    useCategoryControllerDeleteCategoryMutation();

  const [editCategory, { isLoading: editLoading }] =
    useCategoryControllerUpdateCategoryMutation();

  const deleteHandler = () => {
    deleteCategory({ idDto: { id } })
      .unwrap()
      .then(() => {
        toast.success("Angle thresholds successfully deleted");
      });
  };

  const editHandler = () => {
    if (isEqual || !localSetting) return;
    editCategory({
      id,
      updateCategoryDto: {
        name,
        setting: localSetting,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("Angle thresholds successfully modified");
      });
  };

  const actionsBtn = (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ pt: 3 }}
    >
      <Button
        sx={{ width: "100%", maxWidth: "130px" }}
        variant="contained"
        color="info"
        onClick={editHandler}
        disabled={isEqual}
      >
        Update
      </Button>
      <Button
        sx={{ width: "100%", maxWidth: "130px" }}
        variant="contained"
        color="error"
        onClick={deleteHandler}
      >
        Delete
      </Button>
    </Stack>
  );

  return (
    <Paper
      component={Stack}
      direction="column"
      elevation={isMd ? undefined : 0}
      sx={{
        width: "100%",
        px: { xs: 3, sm: 2, md: 1 },
        py: 2,
        borderRadius: { xs: 0, md: 1 },
        position: "relative",
      }}
    >
      {(deleteLoading || editLoading) && <Loading />}
      <Box pb={1}>
        <Typography
          component="div"
          align="center"
          variant="h6"
          fontWeight="bold"
        >
          {name}
        </Typography>
        {isSystemCategory && (
          <Typography
            align="center"
            variant="h6"
            fontWeight="bold"
            component="div"
            color="primary"
          >
            (SYSTEM ANGLE THRESHOLD)
          </Typography>
        )}
      </Box>
      {localSetting && (
        <CategorySettings
          value={localSetting}
          onChange={setLocalSetting as Dispatch<SetStateAction<SettingDto>>}
        />
      )}
      {isSystemCategory ? (auth?.isAdmin ? actionsBtn : null) : actionsBtn}
    </Paper>
  );
};

export default CategoryCard;
