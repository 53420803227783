import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { TaskRo } from "src/app/services/generatedApi";
import { EventEmitterProvider } from "src/components/hook/useEventEmitter";
import { OverallRiskScore } from "src/components/molecules/result/overallRiskScore/OverallRiskScore";
import TaskContextProvider from "src/context/TaskContext";
import About from "src/components/molecules/result/about/About";
import { useCsvReportDownloader } from "src/components/hook/useCsvReportDownloader";
import { AdminControls } from "./AdminControls";
import { MainContent } from "./MainContent";

type ResultPropsType = {
  task: TaskRo;
  isCompare: boolean;
};

export const Result: FC<ResultPropsType> = ({ task, isCompare }) => {
  const downloadCsvReport = useCsvReportDownloader(task.id);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [assessmentPageIndex, setAssessmentPageIndex] = useState<number>(0);

  return (
    <Stack direction="column" spacing={2} sx={{ position: "relative" }}>
      <TaskContextProvider task={task}>
        <EventEmitterProvider>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            alignItems="flex-start"
          >
            <About task={task} />
            <OverallRiskScore />
          </Stack>
          <AdminControls
            task={task}
            assessmentPageIndex={assessmentPageIndex}
            openReportDialog={openReportDialog}
            setOpenReportDialog={setOpenReportDialog}
          />
          <MainContent
            task={task}
            isCompare={isCompare}
            assessmentPageIndex={assessmentPageIndex}
            downloadCsvReport={downloadCsvReport}
            openReportDialog={openReportDialog}
            setOpenReportDialog={setOpenReportDialog}
            setAssessmentPageIndex={setAssessmentPageIndex}
          />
        </EventEmitterProvider>
      </TaskContextProvider>
    </Stack>
  );
};
