import {
  LiftLowerTaskDataDto,
  PushPullCarryTaskDataDto,
} from "src/app/services/generatedApi";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum CalcTaskModes {
  LIFT = "LIFT",
  LOWER = "LOWER",
  PUSH_INITIAL = "PUSH_INITIAL",
  PULL_INITIAL = "PULL_INITIAL",
  PUSH_SUSTAINED = "PUSH_SUSTAINED",
  PULL_SUSTAINED = "PULL_SUSTAINED",
  CARRY = "CARRY",
}

export const calcTaskModesTitle: { [key in CalcTaskModes]: string } = {
  [CalcTaskModes.LIFT]: "Lift",
  [CalcTaskModes.LOWER]: "Lower",
  [CalcTaskModes.PUSH_INITIAL]: "Push Initial",
  [CalcTaskModes.PUSH_SUSTAINED]: "Push Sustained",
  [CalcTaskModes.PULL_INITIAL]: "Pull Initial",
  [CalcTaskModes.PULL_SUSTAINED]: "Pull Sustained",
  [CalcTaskModes.CARRY]: "Carry",
};

export type TaskData =
  | {
      mode: CalcTaskModes.LIFT | CalcTaskModes.LOWER;
      data: LiftLowerTaskDataDto;
    }
  | {
      mode:
        | CalcTaskModes.PUSH_INITIAL
        | CalcTaskModes.PUSH_SUSTAINED
        | CalcTaskModes.PULL_INITIAL
        | CalcTaskModes.PULL_SUSTAINED
        | CalcTaskModes.CARRY;
      data: PushPullCarryTaskDataDto;
    };

export const capableZScore: { [key: number]: number } = {
  10: -1.282,
  25: -0.675,
  50: 0,
  75: 0.675,
  90: 1.282,
};

export const cvValues: { [key in Gender]: { [key in CalcTaskModes]: number } } =
  {
    [Gender.FEMALE]: {
      [CalcTaskModes.LIFT]: 0.26,
      [CalcTaskModes.LOWER]: 0.307,
      [CalcTaskModes.PUSH_INITIAL]: 0.214,
      [CalcTaskModes.PULL_INITIAL]: 0.234,
      [CalcTaskModes.PUSH_SUSTAINED]: 0.286,
      [CalcTaskModes.PULL_SUSTAINED]: 0.298,
      [CalcTaskModes.CARRY]: 0.231,
    },
    [Gender.MALE]: {
      [CalcTaskModes.LIFT]: 0.276,
      [CalcTaskModes.LOWER]: 0.304,
      [CalcTaskModes.PUSH_INITIAL]: 0.231,
      [CalcTaskModes.PULL_INITIAL]: 0.238,
      [CalcTaskModes.PUSH_SUSTAINED]: 0.267,
      [CalcTaskModes.PULL_SUSTAINED]: 0.257,
      [CalcTaskModes.CARRY]: 0.278,
    },
  };

export const couplingFactor: {
  [key in LiftLowerTaskDataDto["handCoupling"]]: number;
} = {
  GOOD: 1.0,
  FAIR: 0.925,
  POOR: 0.84,
};
