import { FC, useMemo, useState } from "react";
import Template from "src/components/templates/Template";
import { useCategoryControllerGetAllCategoriesQuery } from "src/app/services/generatedApi";
import { Button, Dialog, Grid, Paper } from "@mui/material";
import CategoryCard from "src/components/organisms/category/CategoryCard";
import Loading from "src/components/atoms/Loading";
import { Add as AddIcon } from "@mui/icons-material";
import AddCategory from "src/components/organisms/category/AddAngleThresholds";

const AngleThresholds: FC = () => {
  const [modalState, setModalState] = useState(false);

  const { data, isLoading } = useCategoryControllerGetAllCategoriesQuery();

  const localCategory = useMemo(() => {
    if (data?.categories) {
      return [...data.categories].reverse();
    }
  }, [data?.categories]);

  return (
    <Template>
      {isLoading && <Loading />}
      <Button
        variant="contained"
        startIcon={<AddIcon fontSize="large" />}
        sx={{
          ml: { xs: 2, md: 0 },
          mb: { xs: 2, md: 4 },
          mt: { xs: 2, md: 0 },
        }}
        onClick={() => setModalState(true)}
      >
        Add New Angle Thresholds
      </Button>
      <Grid
        direction={{ xs: "column-reverse", md: "row" }}
        container
        spacing={2}
      >
        <Grid item md={8}>
          <Grid container spacing={{ xs: 4, md: 6, lg: 8 }}>
            {localCategory &&
              localCategory.map((category, index) => (
                <Grid item xs={12} key={index}>
                  <CategoryCard category={category} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item md={4} sx={{ display: { sm: "none", md: "block" } }}>
          <Paper
            sx={{
              position: "sticky",
              top: 120,
              px: 5,
              py: 2,
            }}
          >
            <img
              src="/pose.png"
              alt="angle threshold"
              style={{
                width: "100%",
                filter: "drop-shadow(0 1mm 1mm #ccc)",
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={modalState}
        onClose={() => setModalState(false)}
      >
        <AddCategory closeModalFunc={() => setModalState(false)} />
      </Dialog>
    </Template>
  );
};

export default AngleThresholds;
