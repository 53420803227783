import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const VerticalIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 1.5705L11.46 2.08575L5.46 8.08575L6.54 9.16575L11.25 4.452V19.5458L6.54 14.8358L5.46 15.9135L11.46 21.9135L12 22.428L12.54 21.9128L18.54 15.9128L17.46 14.8328L12.75 19.5473V4.4535L17.46 9.165L18.54 8.085L12.54 2.085L12 1.5705Z" />
    </SvgIcon>
  );
};
