import { FC, useState, MouseEvent } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loading from "src/components/atoms/Loading";
import { useAppDispatch } from "src/app/hooks";
import { loginAction } from "src/app/slice/authSlice";
import { authenticatedUserType } from "src/types/authTypes";
import { useAuthControllerLoginMutation } from "src/app/services/generatedApi";
import { formikOnSubmitType } from "src/types/formTypes";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const SignIn: FC = () => {
  const [loginUser, { isLoading }] = useAuthControllerLoginMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useAppDispatch(),
    navigate = useNavigate();

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const frequentValidation = yup
    .string()
    .min(3, "too short")
    .max(50, "too long")
    .required("required");

  const formInitialValues = {
    username: "",
    password: "",
    rememberMe: false,
  };
  const formValidation = yup.object().shape({
    username: frequentValidation,
    password: frequentValidation,
  });
  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { username, password, rememberMe },
    { setSubmitting }
  ) => {
    loginUser({
      loginDto: { username, password },
    })
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(
            loginAction({
              authenticatedUser: {
                ...(res as authenticatedUserType),
                isAdmin: res.user.roleNames
                  .map((x) => x.toUpperCase())
                  .includes("ADMIN"),
              },
              rememberMe,
            })
          );
          navigate("/");
          toast.success("You are successfully logged in");
        }
      })
      .catch(({ status }: { status: number }) => {
        if (status === 401) {
          toast.error("Username or Password is incorrect");
        }
      });
    setSubmitting(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidation}
        onSubmit={submitHandler}
      >
        {({ errors, touched, getFieldProps }) => (
          <Form autoComplete="on">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{
                    enterKeyHint: "next",
                    autoComplete: "on",
                    name: "username",
                  }}
                  label="Username *"
                  autoFocus
                  error={Boolean(errors.username && touched.username)}
                  helperText={errors.username}
                  {...getFieldProps("username")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Password *"
                  type={showPassword ? "text" : "password"}
                  inputProps={{
                    enterKeyHint: "done",
                    autoComplete: "on",
                    name: "password",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showPasswordHandler}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.password && touched.password)}
                  helperText={errors.password}
                  {...getFieldProps("password")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="rememberMe" color="primary" />}
                  label="Remember me"
                  {...getFieldProps("rememberMe")}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                      <Typography
                        variant="button"
                        sx={{ minWidth: "150px", fontSize: "1rem" }}
                      >
                        Sign in
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignIn;
