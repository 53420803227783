import {
  Check,
  Dangerous,
  ErrorOutline,
  GppGood,
  ReportProblem,
} from "@mui/icons-material";

export type RebaScoreDetailType = {
  range: { min?: number; max?: number };
  detail: string;
  color: string;
  icon: any;
};

export const rebaScoresDetail: RebaScoreDetailType[] = [
  {
    range: { min: 1, max: 1 },
    detail: "Low risk, no action needed",
    color: "#B2DFDB",
    icon: GppGood,
  },
  {
    range: { min: 2, max: 3 },
    detail: "Low to moderate risk, monitor regularly",
    color: "#FFF176",
    icon: Check,
  },
  {
    range: { min: 4, max: 7 },
    detail: "Moderate risk, action needed soon",
    color: "#FFB74D",
    icon: ErrorOutline,
  },
  {
    range: { min: 8, max: 10 },
    detail: "High risk, immediate action needed",
    color: "#EF5350",
    icon: ReportProblem,
  },
  {
    range: { min: 11 },
    detail: "Very high risk, work should stop until corrective action is taken",
    color: "#B71C1C",
    icon: Dangerous,
  },
];

export const getRebaScoresDetailItem = (score: number) => {
  let index = 0;
  for (; index < rebaScoresDetail.length - 1; index++) {
    const max = rebaScoresDetail[index].range.max;
    if (max === undefined || max >= score) {
      break;
    }
  }
  return rebaScoresDetail[index];
};
