import { RebaItems, RebaQuestions } from "../reba/types";
import { RulaItems, RulaQuestions } from "../rula/types";
import {
  QuestionType,
  RebaRulaItemSelect,
  RebaRulaQuestion,
  RebaRulaQuestionSelect,
  RebaRulaQuestionSelectOptional,
} from "./types";

export const getIdOfSelectedItem = <T>(
  question: RebaRulaQuestionSelect<T>,
): T => {
  return question.items[question.selectedIndex].id;
};

export const getIndexOfId = <T>(
  question: RebaRulaQuestionSelect<T>,
  id: T,
): number => {
  const index = (question.items as RebaRulaItemSelect<T>[]).findIndex(
    (item) => item.id === id,
  );
  if (index === -1) {
    return question.selectedIndex;
  }
  return index;
};

export const setIndexById = <T>(
  question: RebaRulaQuestionSelect<T>,
  id: T,
): void => {
  question.selectedIndex = getIndexOfId(question, id);
};

export const getValueOfQuestion = (question: RebaRulaQuestion<any>): number => {
  if (question.type === QuestionType.SELECT) {
    return question.items[question.selectedIndex].value;
  } else if (question.type === QuestionType.SELECT_OPTIONAL) {
    return question.selectedIndex
      ? question.items[question.selectedIndex].value
      : 0;
  } else {
    return question.items.reduce(
      (prev, item) => prev + (item.selected ? item.value : 0),
      0,
    );
  }
};

export const getValueOfRebaQuestions = (
  questions: RebaQuestions,
  selectedItemsKey: RebaItems[],
): number =>
  selectedItemsKey.reduce(
    (prev, item) => prev + getValueOfQuestion(questions[item]),
    0,
  );

export const getValueOfRulaQuestions = (
  questions: RulaQuestions,
  selectedItemsKey: RulaItems[],
): number =>
  selectedItemsKey.reduce(
    (prev, item) => prev + getValueOfQuestion(questions[item]),
    0,
  );

export const getIdOfSelectedOptionalItem = <T>(
  question: RebaRulaQuestionSelectOptional<T>,
): T | undefined => {
  return question.selectedIndex !== undefined
    ? question.items[question.selectedIndex].id
    : undefined;
};

export const getIndexOfOptionalId = <T>(
  question: RebaRulaQuestionSelectOptional<T>,
  id?: T,
): number | undefined => {
  if (id) {
    const index = (question.items as RebaRulaItemSelect<T>[]).findIndex(
      (item) => item.id === id,
    );
    if (index === -1) {
      return undefined;
    }
    return index;
  }
  return undefined;
};

export const setIndexOfOptionalById = <T>(
  question: RebaRulaQuestionSelectOptional<T>,
  id: T,
): void => {
  question.selectedIndex = getIndexOfOptionalId(question, id);
};
