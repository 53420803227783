import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showMenu: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    changeSidebarMenuState: (state, { payload }: { payload: boolean }) => {
      state.showMenu = payload;
    },
  },
});

export const { changeSidebarMenuState: changeSidebarMenuStateAction } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
