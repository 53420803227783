import {
  PauseCircle as PauseCircleIcon,
  PlayCircle as PlayCircleIcon,
} from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { toast } from "react-toastify";

import {
  useUsersControllerActivateUserByUsernameMutation,
  useUsersControllerSuspendUserByUsernameMutation,
} from "src/app/services/generatedApi";

export const ActivateUserButton: FC<{
  isActive: boolean;
  username: string;
}> = ({ isActive, username }) => {
  const [suspendUser, { isLoading: suspendUserLoading }] =
    useUsersControllerSuspendUserByUsernameMutation();
  const [activeUser, { isLoading: activeUserLoading }] =
    useUsersControllerActivateUserByUsernameMutation();

  const activeUserHandler = () => {
    if (isActive) {
      suspendUser({ username })
        .unwrap()
        .then(() => {
          toast.success("User successfully suspended");
        });
    } else {
      activeUser({ username })
        .unwrap()
        .then(() => {
          toast.success("User successfully Activated");
        });
    }
  };

  return (
    <MenuItem
      onClick={activeUserHandler}
      disabled={suspendUserLoading || activeUserLoading}
    >
      <ListItemIcon>
        {isActive ? (
          <PauseCircleIcon fontSize="small" />
        ) : (
          <PlayCircleIcon fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>{isActive ? "Suspend" : "Activate"}</ListItemText>
    </MenuItem>
  );
};
