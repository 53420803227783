import { createSlice } from "@reduxjs/toolkit";
import { updateUserInfoType } from "src/types/authTypes";

const getStoredUser = (): authSliceType | null => {
  const localStorageUser = localStorage.getItem("authenticatedUser");
  const sessionStorageUser = sessionStorage.getItem("authenticatedUser");

  if (localStorageUser) {
    return JSON.parse(localStorageUser) as authSliceType;
  } else if (sessionStorageUser) {
    return JSON.parse(sessionStorageUser) as authSliceType;
  }

  return null;
};

type authSliceType = {
  token: string;
  user: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    roleNames: string[];
  };
  isAdmin: boolean;
};

const initialState: authSliceType | null = getStoredUser();

type loginType = {
  rememberMe: boolean;
  authenticatedUser: authSliceType;
};

const storeUser = (user: authSliceType, rememberMe: boolean) => {
  const storage = rememberMe ? localStorage : sessionStorage;
  storage.setItem("authenticatedUser", JSON.stringify(user));
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      sessionStorage.clear();
      localStorage.clear();
      return null;
    },
    login: (_, { payload }: { payload: loginType }) => {
      storeUser(payload.authenticatedUser, payload.rememberMe);
      return payload.authenticatedUser;
    },
    updateUserInfo: (state, { payload }: { payload: updateUserInfoType }) => {
      if (state) {
        const updatedUser = {
          ...state.user,
          ...payload,
        };
        const newState = { ...state, user: updatedUser };

        storeUser(newState, !!localStorage.getItem("authenticatedUser"));
        return newState;
      }
      return state;
    },
  },
});

export const {
  logout: logoutAction,
  login: loginAction,
  updateUserInfo: updateUserInfoAction,
} = authSlice.actions;

export default authSlice.reducer;
