import { Stack } from "@mui/material";
import { FC, useContext, useEffect, useMemo } from "react";
import {
  getRebaDataFromResult,
  getRebaResult,
} from "src/app/logic/assessment/reba/Reba";
import { getRebaQuestions } from "src/app/logic/assessment/reba/RebaQuestions";
import { QuestionType } from "src/app/logic/assessment/reba-rula/types";
import {
  RebaItems,
  RebaQuestions,
  rebaItemsList,
} from "src/app/logic/assessment/reba/types";
import { RebaRulaFormItem } from "src/components/molecules/assessment/reba-rula/FormItem";
import { FloatingCard } from "src/components/molecules/assessment/FloatingCard";
import { AssessmentContext } from "src/context/AssessmentContext";
import {
  getRulaDataFromResult,
  getRulaResult,
} from "src/app/logic/assessment/rula/Rula";
import { getRulaQuestions } from "src/app/logic/assessment/rula/RulaQuestions";
import { RebaDto, RulaDto } from "src/app/services/generatedApi";
import {
  RulaItems,
  RulaQuestions,
  rulaItemsList,
} from "src/app/logic/assessment/rula/types";

type RebaPageDataType = {
  methodName: "reba";
  data: RebaQuestions;
  itemsList: RebaItems[];
  getResult: (rebaQuestions: RebaQuestions) => RebaDto;
};

type RulaPageDataType = {
  methodName: "rula";
  data: RulaQuestions;
  itemsList: RulaItems[];
  getResult: (rulaQuestions: RulaQuestions) => RulaDto;
};

type PageDataType = RebaPageDataType | RulaPageDataType;

type RebaRulaPageProps = {
  fileName?: string;
  havePose: boolean;
};

export const RebaRulaPage: FC<RebaRulaPageProps> = ({ fileName, havePose }) => {
  const { assessmentDataDto, setAssessmentDataDto, selectedMethod } =
    useContext(AssessmentContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const rebaData = useMemo(
    () =>
      assessmentDataDto?.reba
        ? getRebaDataFromResult(assessmentDataDto?.reba)
        : getRebaQuestions(),
    [assessmentDataDto?.reba],
  );

  const rulaData = useMemo(
    () =>
      assessmentDataDto?.rula
        ? getRulaDataFromResult(assessmentDataDto?.rula)
        : getRulaQuestions(),
    [assessmentDataDto?.rula],
  );

  const pageData: PageDataType =
    selectedMethod === "REBA"
      ? {
          methodName: "reba",
          data: rebaData,
          itemsList: rebaItemsList,
          getResult: getRebaResult,
        }
      : {
          methodName: "rula",
          data: rulaData,
          itemsList: rulaItemsList,
          getResult: getRulaResult,
        };

  return (
    <>
      <Stack spacing={3}>
        {pageData.itemsList.map((questionKey, index: number) => (
          <RebaRulaFormItem
            key={index}
            question={
              pageData.methodName === "reba"
                ? pageData.data[questionKey as RebaItems]
                : pageData.data[questionKey as RulaItems]
            }
            onSelect={(index: number) => {
              const newData = { ...pageData.data };
              const question =
                pageData.methodName === "reba"
                  ? (newData as RebaQuestions)[questionKey as RebaItems]
                  : (newData as RulaQuestions)[questionKey as RulaItems];
              if (question.type === QuestionType.SELECT) {
                question.selectedIndex = index;
              } else if (question.type === QuestionType.SELECT_OPTIONAL) {
                if (question.selectedIndex === index) {
                  question.selectedIndex = undefined;
                } else {
                  question.selectedIndex = index;
                }
              } else {
                question.items[index].selected =
                  !question.items[index].selected;
              }
              setAssessmentDataDto({
                [pageData.methodName]:
                  pageData.methodName === "reba"
                    ? pageData.getResult(newData as RebaQuestions)
                    : pageData.getResult(newData as RulaQuestions),
              });
            }}
          />
        ))}
      </Stack>
      {fileName && <FloatingCard fileName={fileName} havePose={havePose} />}
    </>
  );
};
