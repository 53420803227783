import { FC, Dispatch, SetStateAction } from "react";
import { alpha } from "@mui/material/styles";
import {
  useMediaQuery,
  Stack,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { MinimalTaskRo } from "src/app/services/generatedApi";
import ToolbarActions from "./AllToolbarActions";
import { Search as SearchIcon } from "@mui/icons-material";

type TasksTableToolbarPropsType = {
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  rows: MinimalTaskRo[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  isFetching: boolean;
};

const TasksTableToolbar: FC<TasksTableToolbarPropsType> = ({
  selectedRows,
  setSelectedRows,
  rows,
  search,
  setSearch,
  isFetching,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Toolbar
      sx={{
        pt: 3,
        pb: 3,
        ...(selectedRows.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        width="100%"
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Box sx={{ width: 20, height: 20 }}>
            {isFetching && <CircularProgress size={20} />}
          </Box>
          {selectedRows.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selectedRows.length} selected
            </Typography>
          ) : (
            isMd && (
              <Typography
                variant="h6"
                id="tableTitle"
                component="div"
                width="100%"
                sx={{ whiteSpace: "nowrap" }}
              >
                All videos
              </Typography>
            )
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          {selectedRows.length > 0 ? (
            <ToolbarActions
              selectedRows={selectedRows}
              rows={rows}
              setSelectedRows={setSelectedRows}
            />
          ) : (
            <TextField
              sx={{ width: "100%", maxWidth: "352px" }}
              size="small"
              placeholder="Search by name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
            />
          )}
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default TasksTableToolbar;
